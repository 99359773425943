import { createFeatureSelector, createSelector } from '@ngrx/store';
import { getGenomicsRequestState } from '../../genomics-request/store';
import GenomicsRequestState from '../../genomics-request/store/genomics-request.reducer';
import { getUserState } from '../../user/store';
import UserState from '../../user/store/user.reducer';
import GlobalContextState from './global-context.reducer';

export const selectGlobalContext = createFeatureSelector< GlobalContextState>('globalContext');

export const selectGlobalContextUserRole = createSelector(
  selectGlobalContext,
  (state: GlobalContextState) => state.role
);

export const selectGlobalContextGenomicsRequestsFromCurrentRole = createSelector(
  selectGlobalContext,
  getGenomicsRequestState,
  (state: GlobalContextState, requestState: GenomicsRequestState) => {
    if (state.role) {
      return requestState.requests;
    }
  }
);

export const selectGlobalContextUserAndRoleAndLab = createSelector(
  selectGlobalContext,
  getUserState,
  (state: GlobalContextState, userState: UserState) => {
    if (state && userState && userState.loggedIn) {
      return {role: state.role, user: userState.loggedIn, lab: state.lab};
    }
  }
);

export const selectEntityPage = createSelector(
  selectGlobalContext,
  (state: GlobalContextState) => state.currentPage
);

export const selectGlobalContextLab = createSelector(
  selectGlobalContext,
  (state: GlobalContextState) => state.lab
);

