import { AppState } from '../../store/app.reducers';
import { createSelector } from '@ngrx/store';
import PoolState from './pool.reducer';

export const getPoolState = (state: AppState) => state.pool;

export const selectPools = createSelector(
  getPoolState,
  (state: PoolState) => state.pools
);

export const selectPoolCounts = createSelector(
  getPoolState,
  (state: PoolState) => state.poolCounts
);

export const selectPoolColorScale = createSelector(
  getPoolState,
  (state: PoolState) => state.colorScale
);

export const selectIsPoolStorePending = createSelector(
  getPoolState,
  (state: PoolState) => state.pending
);

export const selectCurrentPool = createSelector(
  getPoolState,
  (state: PoolState) => state.currentPool
);

export const selectPoolColor = (poolAccessionCode: string) => createSelector(
  getPoolState,
  (state: PoolState) => state.colorScale && state.colorScale.get(poolAccessionCode)
  );
