import { Component, Inject, OnInit } from '@angular/core';
import {  Store } from '@ngrx/store';
import { AppState } from '../../../../store/app.reducers';
import { updateIndexKitModel } from '../../../store';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IndexKitModelUpdateDto } from '../../../models/index-kit-model.dto';
import { LibraryMethod } from '../../../../sequencing/models/library-method';
import { Observable } from 'rxjs';
import { selectActiveLibraryMethods } from '../../../../sequencing/store';
import { take } from 'rxjs/operators';


@Component({
  selector: 'nemo-index-kit-model-edit-dialog',
  template: `
    <div class="container"
         [formGroup]="formGroup"
    >
      <h1>Edit index kit model</h1>
      <span class="current">Current name: {{data['name']}}</span>
      <mat-form-field>
        <mat-label>New name</mat-label>
        <input
          matInput
          formControlName="name"
          placeholder="Displayed name, such as '10x Genomics Chromium i7 Multiplex Kit (PN 120262)'"
        >
      </mat-form-field>
      <div class="library-methods">
        <h3 mat-subheader>2. Library methods</h3>
        <mat-selection-list formControlName="libraryMethodIds">
          <mat-list-option
            *ngFor="let libraryMethod of libraryMethods$ | async"
            checkboxPosition="before"
            selected="{{libraryMethodSelection[libraryMethod.id]}}"
            [value]="libraryMethod.id">{{libraryMethod.name}}
          </mat-list-option>
        </mat-selection-list>
      </div>
      <div>
        <button mat-button (click)="close()">Cancel</button>
        <button
          mat-raised-button
          color="primary"
          [disabled]="!formGroup.valid"
          (click)="submit()"
        >Update
        </button>
      </div>
    </div>
  `,
  styles: [`
    div.container {
      display: flex;
      flex-direction: column;
    }

    mat-form-field {
      width: 40em;
      margin-right: 1em;
    }

    .current {
      margin: 0 0 1em 1em;
    }
  `]
})
export class IndexKitModelEditDialogComponent implements OnInit {
  libraryMethods$: Observable<LibraryMethod[]>;
  includedLibraryMethods: string[];
  libraryMethodSelection: { [key: number]: boolean; } = {};
  formGroup: UntypedFormGroup;
  indexKitModelId: number;

  constructor(
    private readonly store: Store<AppState>,
    private dialogRef: MatDialogRef<IndexKitModelEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: object
  ) {
  }

  ngOnInit() {
    this.indexKitModelId = this.data['id'];
    this.includedLibraryMethods = this.data['libraryMethods'];

    this.libraryMethods$ = this.store.select(selectActiveLibraryMethods);

    this.libraryMethods$.pipe(
      take(1)
    ).subscribe(activeLibraryMethods => {
      const selectedMethods: number[] = [];
      activeLibraryMethods.forEach(activeLibraryMethod => {
        if (this.isIncluded(activeLibraryMethod)) {
          selectedMethods.push(activeLibraryMethod.id);
          this.libraryMethodSelection[activeLibraryMethod.id] = true;
        } else {
          this.libraryMethodSelection[activeLibraryMethod.id] = false;
        }
      });
      this.formGroup = new UntypedFormGroup({
        name: new UntypedFormControl(this.data['name'], [
          Validators.required
        ]),
        libraryMethodIds: new UntypedFormControl(selectedMethods, [])
      });
    });
  }

  isIncluded(libraryMethod: LibraryMethod): boolean {
    return this.includedLibraryMethods.filter(includedLibraryMethod => {
      return includedLibraryMethod === libraryMethod.name;
    }).length > 0;
  }

  submit() {
    this.store.dispatch(updateIndexKitModel({
      indexKitModel: {
        id: this.indexKitModelId,
        name: this.formGroup.get('name').value,
        libraryMethodIds: this.formGroup.get('libraryMethodIds').value
      } as IndexKitModelUpdateDto
    }));
    this.close();
  }

  close(): void {
    this.dialogRef.close();
  }
}
