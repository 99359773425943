import { Observable } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import {  Store } from '@ngrx/store';
import { AppState } from '../../../store/app.reducers';
import { selectPlateByIdentifier } from '../../../labware/store/plates';
import { EVENT_LISTENER_ID_INDEXES } from '../constants';
import { map } from 'rxjs/operators';
import { Plate96 } from '../../../labware/models/plate-96';
import { BioMaterial } from '../../../bio/models/bio-material';
import { Index } from '../../../bio/models/barcode-index';
import { SimpleIndexKit } from '../../../index/models/indexKit';
import { findIndexKitByIdThenSelectFromIndexAssignment } from '../../../index/store';

@Component({
  selector: 'nemo-index-assigner-right-zone',
  template: `
    <nemo-select-index-kit (selectedIndexKitChange)="updateSelectedIndexKit($event)"
                           [ngClass]="(indexPlate$ | async) === false && 'no-selected-index'"></nemo-select-index-kit>

    <div class="plate-container"
         *ngIf="indexPlate$ | async">
      <nemo-plate-viewer-container plateAccessionCode="index-assign/indexes"
                                   [isReadonly]="false"></nemo-plate-viewer-container>
      <div class="text-selection">
        <nemo-well-selection-input plateAccessionCode="index-assign/indexes"></nemo-well-selection-input>
      </div>
    </div>
  `,
  styles: [`
    :host {
      display: flex;
      flex-direction: column;
    }

    nemo-select-index-kit {
      height: 4em;
      max-width: 40em;
    }
  `]
})
export class IndexAssignerRightZoneComponent implements OnInit {

  indexPlate$: Observable<Plate96<Index>>;

  constructor(private store: Store<AppState>) {
  }

  ngOnInit() {
    this.indexPlate$ = this.store.select(selectPlateByIdentifier(EVENT_LISTENER_ID_INDEXES))
      .pipe(
        
        map((plate: Plate96<BioMaterial>) => plate as Plate96<Index>)
      );
  }

  updateSelectedIndexKit(kit: SimpleIndexKit) {
    this.store.dispatch(findIndexKitByIdThenSelectFromIndexAssignment({id: kit.id}));
  }
}
