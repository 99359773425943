import { AfterContentChecked, ChangeDetectorRef, Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'nemo-request-submission-form-phix',
  template: `
    <div [formGroup]="formGroup">
      <h4>PhiX</h4>
      <span class="description">For low diversity libraries, please request a PhiX spike or consult a member of the Genomics lab.</span>
      <mat-form-field appearance="fill"
                      floatLabel="auto"
                      class="numeric-field phix">
        <mat-label [class.strike-through]="phiXPercentage.value === '0'">PhiX</mat-label>
        <input matInput #phiXPercentage
               formControlName="phiXPercentage"
               type="number"
               class="right-align"
               min="0"
               max="100"
               value="0"/>
        <span matTextSuffix>%</span>
        <mat-hint>Between 0 and 100%</mat-hint>
      </mat-form-field>
    </div>
  `,
  styleUrls: ['../request-submission-form/request-submission-form.component.scss'],
})
export class RequestSubmissionFormPhixComponent implements AfterContentChecked {
  @Input() formGroup: FormGroup;

  constructor(
    private cdref: ChangeDetectorRef
  ) {
  }

  ngAfterContentChecked(): void {
    this.cdref.detectChanges();
  }
}
