import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'nemo-request-submission-form-ref-transcriptome',
  template: `
    <div [formGroup]="formGroup">
      <h4>References: For downstream analysis*</h4>
      <mat-radio-group formControlName="refTransCtl">
        <mat-radio-button *ngFor="let option of options"
                          [value]="option.link">
          {{ option.name }}
        </mat-radio-button>
      </mat-radio-group>
    </div>
  `,
  styleUrls: ['../request-submission-form/request-submission-form.component.scss'],
  styles: [`
    mat-radio-group {
      flex-direction: row;
      flex-wrap: wrap;
    }

    mat-radio-button {
        margin-right: 24px;
        width: 7em;
    }
  `]
})
export class RequestSubmissionFormRefTranscriptomeComponent {
  @Input() formGroup: FormGroup;

  options = [
    {
      name: 'Human',
      link: '/data/novaseq-2021/reference_transcriptome/human/refdata-gex-GRCh38-2020-A'
    },
    {
      name: 'Mouse',
      link: '/data/novaseq-2021/reference_transcriptome/mouse/refdata-gex-mm10-2020-A'
    },
    {
      name: 'Rat',
      link: '/data/novaseq-2021/reference_transcriptome/rat/Rat_rDNA'
    },
    {
      name: 'Yeast',
      link: '/data/novaseq-2021/reference_transcriptome/yeast/SacCer3_mRNA_large_UTRs_rDNA'
    }
  ]
}
