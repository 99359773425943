import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {  Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { UntypedFormControl } from '@angular/forms';

import * as _ from 'lodash';

import { AppState } from '../../../../store/app.reducers';
import { selectCompanyActiveProjects } from '../../../store';
import { Project } from '../../../models/Project';

@Component({
  selector: 'nemo-project-select',
  template: `
    <mat-form-field class="example-full-width">
      <mat-label>Project</mat-label>
      <mat-select
        [formControl]="control"
        (selectionChange)="changedValue($event.value)"
       >
        <mat-option>
         <input matInput placeholder="Search projects"
           (input)="filterOptions($event.target.value)"
           (click)="$event.stopPropagation()"
           (keydown.space)="$event.stopPropagation()" />
        </mat-option>

        <mat-option *ngFor="let project of filteredOptions$ | async" [value]="project.code">
          [{{project.code}}] {{project.name}}
        </mat-option>
      </mat-select>

    </mat-form-field>
  `,
  styleUrls: ['../select.scss'],
})
export class ProjectSelectComponent implements OnInit {
  @Input()
  control: UntypedFormControl;

  @Output()
  changeValue = new EventEmitter<number>();

  options$: Observable<Project[]>;
  filteredOptions$: Observable<Project[]>;

  constructor(
    private store: Store<AppState>
  ) {
  }

  ngOnInit() {
    this.options$ = this.store.select(selectCompanyActiveProjects).pipe(
      map((projects) => _.orderBy(projects, [project => project.name.toLowerCase()]))
    );
    this.filteredOptions$ = this.options$; // Initially, show all options
  }

  filterOptions(query: string) {
    if (query) {
      this.filteredOptions$ = this.options$.pipe(
        map(options => options.filter(project =>
          project.name.toLowerCase().includes(query.toLowerCase()) ||
          project.code.toString().includes(query)
        ))
      );
    } else {
      this.filteredOptions$ = this.options$; // Reset to all options if query is empty
    }
  }

  changedValue(deptCode: number) {
    this.changeValue.emit(deptCode);
  }

}
