import { Component, Input, OnInit } from '@angular/core';
import { BioMaterialPlateMapping } from '../../../labware/models/bio-material-plate-mapping';
import { Pool, PoolSampleMix } from '../../../bio/models/pool';
import { formatAccessionCode } from '../../../shared/pipes/accession-code.pipe';
import {  Store } from '@ngrx/store';
import { AppState } from '../../../store/app.reducers';
import { selectPlateByIdentifier } from '../../../labware/store/plates';
import { ECHO_POOLING_SOURCE_PLATE_ID } from './echo-pooling-right-zone.component';
import { combineLatest, Observable } from 'rxjs';
import { Plate96 } from '../../../labware/models/plate-96';
import { map } from 'rxjs/operators';
import { ECHO_POOLING_DESTINATION_PLATE_ID } from './echo-pooling-left-zone.component';
import {
  EchoPooling,
  mergeToEchoPooling,
  toEchoPooling,
  toEchoPoolingProcessed
} from '../services/echo-pooling.service';

@Component({
  selector: 'nemo-echo-pooling-global-export',
  template: `
    <nemo-export-button tableName="pooling-source"
                        [accessionCode]="accessionCode$ | async"
                        [data]="formatedData$ | async"
                        [tableColumns]="exportTableColumns"
                        [disabled]="(formatedData$ | async) === undefined"
                        title="1. Export for calculations"></nemo-export-button>
  `
})
export class EchoPoolingGlobalExportComponent implements OnInit {

  @Input() processed = false;
  accessionCode$: Observable<string>;
  sourceMappings$: Observable<BioMaterialPlateMapping<Pool>[]>;
  targetMappings$: Observable<BioMaterialPlateMapping<Pool>[]>;
  formatedData$: Observable<object[]>;
  exportTableColumns: string[] = [
    'Request',
    'Accession Code',
    'Source Well',
    'ng/uL',
    '% Total',
    'nmole/L',
    'Transfer Volume (nl)',
    'Destination Well'
  ];

  constructor(private store: Store<AppState>) {
  }

  ngOnInit() {
    this.accessionCode$ = this.store.select(selectPlateByIdentifier(ECHO_POOLING_SOURCE_PLATE_ID)).pipe(
      
      map(plate => {
        if (plate) {
          return plate.accessionCode;
        }
      })
    );
    this.sourceMappings$ = this.store.select(selectPlateByIdentifier(ECHO_POOLING_SOURCE_PLATE_ID)).pipe(
      
      map((plate: Plate96<Pool>) => plate ? plate.getNonEmptyBioMaterialMappings() : undefined)
    );
    this.targetMappings$ = this.store.select(selectPlateByIdentifier(ECHO_POOLING_DESTINATION_PLATE_ID)).pipe(
      
      map((plate: Plate96<Pool>) => plate ? plate.getNonEmptyBioMaterialMappings() : undefined)
    );

    this.formatedData$ = combineLatest([this.sourceMappings$, this.targetMappings$])
      .pipe(
        map(([sourceMappings, targetMappings]) => {
            if (this.processed && targetMappings) {
              return toEchoPoolingProcessed(targetMappings as BioMaterialPlateMapping<PoolSampleMix>[]);
            } else if (sourceMappings && targetMappings) {
              const echoPoolings = toEchoPooling(sourceMappings);
              return mergeToEchoPooling(echoPoolings, targetMappings);
            } else if (sourceMappings) {
              return toEchoPooling(sourceMappings);
            } else {
              return [];
            }
          }
        ),
        map(echoPoolings => {
          if (echoPoolings) {
            return this.formatData(echoPoolings);
          }
        })
      );
  }

  formatData(echoPoolings: EchoPooling[]): object[] {
    return echoPoolings.map(echoPooling => {
        if (echoPooling) {
          return {
            'Requests': echoPooling.requestsAccessionCode
              .map(ac => formatAccessionCode(ac))
              .join(','),
            'Accession Code': formatAccessionCode(echoPooling.poolAccessionCode),
            'Source Well': echoPooling.sourceCoordinates.toString(),
            'ng/uL': '',
            '% Total': '',
            'nmole/L': '',
            'Transfer Volume (nl)': echoPooling.transferVolume,
            'Destination Well': (echoPooling.targetCoordinates === undefined)
                                ? ''
                                : echoPooling.targetCoordinates.toString(),
          };
        }
      }
    );
  }
}
