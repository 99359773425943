export class LibraryMethod {
  constructor(
    public readonly id: number,
    public readonly name: string,
    public readonly submissionType: SubmissionType | null,
    public readonly modality: string | null,
    public readonly read1: number,
    public readonly index1: number,
    public readonly index2: number,
    public readonly read2: number,
    public readonly archived: boolean
  ) {
  }
}

export enum SubmissionType {
  DNA = 'DNA',
  RNA = 'RNA',
  SingleCell = 'Single Cell',
  SpatialTranscriptomics = 'Spatial Transcriptomics',
  Other = 'Other'
}
