import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { selectGenomicsMessage, submitNewRequest } from '../../../store';
import { AppState } from '../../../../store/app.reducers';
import { NError } from '../../../../shared/nerrors/models/error.model';
import { FileTransferService } from '../../../../core/services/file-transfer.service';
import { SampleFileValidator } from './sample-file-validator.service';
import { CreateRequestDTO } from '../../../models/genomics-request.dto';
import { insertErrorInvalidSubmission } from '../../../../shared/nerrors/store';
import { LibraryMethod, SubmissionType } from '../../../../sequencing/models/library-method';
import { selectActiveLibraryMethodsBySubmissionType } from '../../../../sequencing/store';
import { MatDialog } from '@angular/material/dialog';
import { DialogInfoComponent } from '../../../../shared/shared/components/dialog-info/dialog-info.component';

@Component({
  selector: 'nemo-request-submission-form',
  templateUrl: './request-submission-form.component.html',
  styleUrls: ['./request-submission-form.component.scss']
})
export class RequestSubmissionFormComponent implements OnInit {

  formGroup: UntypedFormGroup;
  sampleGroup: UntypedFormGroup;
  requestMessage$: Observable<string>;
  selectedLibraryMethod: LibraryMethod | null | undefined = undefined;
  isPristine = true;
  param10xMin = 0;
  param10xMax = 999;

  libraryMethodsBySubmissionType$: Observable<Map<SubmissionType, LibraryMethod[]>>;

  constructor(
    private readonly store: Store<AppState>,
    private readonly fileTransferService: FileTransferService,
    private readonly sampleFileValidator: SampleFileValidator,
    private dialog: MatDialog
  ) {
    this.buildForm();
    this.createDynamicValidatorChanges();
  }

  ngOnInit() {
    this.requestMessage$ = this.store.select(selectGenomicsMessage);
    this.libraryMethodsBySubmissionType$ = this.store.select(selectActiveLibraryMethodsBySubmissionType);
  }

  buildForm() {
    this.sampleGroup = new UntypedFormGroup({
        sampleIndexHeader: new UntypedFormControl(null,
          [Validators.required]
        ),
        sampleFile: new UntypedFormControl('',
          [Validators.required],
        )
      }, [], [this.sampleFileValidator.validate]
    );
    this.formGroup = new UntypedFormGroup({
      title: new UntypedFormControl('',
        [Validators.required, Validators.maxLength(80)]
      ),
      description: new UntypedFormControl('',
        [Validators.maxLength(5000)]
      ),
      departmentCode: new UntypedFormControl(null,
        [Validators.required],
      ),
      projectCode: new UntypedFormControl(null,
        [Validators.required],
      ),
      studyId: new UntypedFormControl(null,
        []
      ),
      libraryMethod: new UntypedFormControl(null,
        [Validators.required],
      ),
      phiXPercentage: new UntypedFormControl(0,
        [Validators.min(0.0), Validators.max(100.0)]),
      sample: this.sampleGroup,
      param10xRead1: new UntypedFormControl('150',
        [Validators.required, Validators.min(this.param10xMin), Validators.max(this.param10xMax)]),
      param10xIndex1: new UntypedFormControl('8',
        [Validators.required, Validators.min(this.param10xMin), Validators.max(this.param10xMax)]),
      param10xIndex2: new UntypedFormControl('8',
        [Validators.required, Validators.min(this.param10xMin), Validators.max(this.param10xMax)]),
      param10xRead2: new UntypedFormControl('150',
        [Validators.required, Validators.min(this.param10xMin), Validators.max(this.param10xMax)]),
      collaborator: new UntypedFormControl(null,
        [Validators.required],
      ),
      refTransCtl: new UntypedFormControl(null,
        []
      ),
      organismCtl: new UntypedFormControl(null,
        []
      ),
      sampleTypeCtl: new UntypedFormControl(null,
        []
      ),
      isRestrictedAccessCtl: new UntypedFormControl(false),
      needAccessEmailsCtl: new UntypedFormControl(null, [this.emailListValidator]),
    });
  }

  createDynamicValidatorChanges() {
    this.formGroup.get('isRestrictedAccessCtl')?.valueChanges.subscribe((required) => {
      const needAccessEmailsCtl = this.formGroup.get('needAccessEmailsCtl');
      this.setValidator(needAccessEmailsCtl, required, this.emailListValidator);
    });
    this.formGroup.get('libraryMethod').valueChanges.subscribe((libraryMethod) => {
      [
        [ this.formGroup.get('refTransCtl'), this.requireReferenceTranscriptomeSetting(libraryMethod) ],
        [ this.formGroup.get('organismCtl'), this.requireSpeciesSetting(libraryMethod) ],
        [ this.formGroup.get('sampleTypeCtl'), this.requireSampleTypeSetting(libraryMethod) ],
      ].forEach(([ abstractControl, required ]: [ AbstractControl, boolean ]) =>
        this.setValidator(abstractControl, required)
      );
    });
  }

  setValidator(abstractControl: AbstractControl, required: boolean, value: any = null) {
    if (required) {
      if (value) {
        abstractControl.setValidators([ Validators.required, value ]);
      } else {
        abstractControl.setValidators([ Validators.required ]);
      }
    } else {
      abstractControl.clearValidators();
    }
    abstractControl.updateValueAndValidity();
  }

  selectedSubmissionTypeChanged(libraryMethod: LibraryMethod | null) {
    this.selectedLibraryMethod = libraryMethod;
    this.updateValues();
  }

  updateValues() {
    this.formGroup.patchValue({
      libraryMethod: this.selectedLibraryMethod,
      param10xRead1: this.selectedLibraryMethod?.read1 ?? 150,
      param10xIndex1: this.selectedLibraryMethod?.index1 ?? 8,
      param10xIndex2: this.selectedLibraryMethod?.index2 ?? 8,
      param10xRead2: this.selectedLibraryMethod?.read2 ?? 150,
      refTransCtl: null,
      organismCtl: null,
      sampleTypeCtl: null,
    });
    this.formGroup.updateValueAndValidity();
  }

  emailListValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    if (!value) return null; // Allow empty input when not required

    const emails = value.split(',').map((email: string) => email.trim());
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    const invalidEmails = emails.filter((email: string) => !emailPattern.test(email));
    if (invalidEmails.length > 0) {
      return { invalidEmails: invalidEmails.join(', ') }; // Return invalid emails as an error
    }
    return null; // Validation passed
  }

  async sampleFileChange(file: File) {
    const b64 = await this.fileTransferService.readFileAsDataURL(file);
    this.sampleGroup.patchValue({
      sampleFile: {
        file: b64,
        filename: file.name,
      },
    });
  }

  onSubmit() {
    // nemo-41: 10x params requires at least 1 parameter is not 0
    // form can enforce all the parameters are between 0 to 999
    // nemo-62 all input will require demux param now.
    const paramSum = this.formGroup.value.param10xRead1
      + this.formGroup.value.param10xRead2
      + this.formGroup.value.param10xIndex1
      + this.formGroup.value.param10xIndex2;
    if (paramSum === 0) {
      const errorPayload = { error: new NError('At least one of the 10x read length parameters must be >0') };
      this.store.dispatch(insertErrorInvalidSubmission(errorPayload));
      return;
    }

    this.isPristine = false;
    if (this.formGroup.valid) {
      this.validSubmission();
    } else {
      this.invalidSubmission();
    }
  }

  validSubmission() {
    let descriptionExtended = this.formGroup.value.description;
    const isRestrictedAccessCtlTxt = this.formGroup.value.isRestrictedAccessCtl ? "yes" : "no";
    // append parameters for all samples

    let extendStr = '\nSequencing/Demux Parameters:\n'
      + 'Read1: ' + this.formGroup.value.param10xRead1 + '\n'
      + 'Index1: ' + this.formGroup.value.param10xIndex1 + '\n'
      + 'Index2: ' + this.formGroup.value.param10xIndex2 + '\n'
      + 'Read2: ' + this.formGroup.value.param10xRead2 + '\n'
      + 'RestrictedAccess: ' + isRestrictedAccessCtlTxt

    if (this.formGroup.value.isRestrictedAccessCtl && this.formGroup.value.needAccessEmailsCtl){
	extendStr += "\n" + "needAccessEmails: " + this.formGroup.value.needAccessEmailsCtl
    }

    const refTransCtlVal = this.formGroup.value.refTransCtl;
    const organismCtlVal = this.formGroup.value.organismCtl;
    const sampleTypeCtlVal = this.formGroup.value.sampleTypeCtl;


    if (organismCtlVal == "Homo sapiens" && sampleTypeCtlVal == "Fresh frozen tissue" && isRestrictedAccessCtlTxt == "no") {
      throw new Error("\"Restricted Access\" field is required to be checked for Homo sapiens with Fresh frozen tissue, " +
        "Please confirm whether these samples are from Donor/Patient. Email calico-ngs@calicolabs.com for help");
    }

    if (refTransCtlVal) {
      descriptionExtended += extendStr + '\n' + 'RefTransPath: ' + refTransCtlVal;
    } else if (organismCtlVal && sampleTypeCtlVal){
      descriptionExtended += extendStr + '\n' + 'Organism: ' + organismCtlVal + '\n' + 'SampleType: ' + sampleTypeCtlVal;
    } else {
      descriptionExtended += extendStr;
    }

    const collaborator = this.formGroup.value.collaborator;

    const request: CreateRequestDTO = {
      libraryMethod: this.formGroup.value.libraryMethod?.name ?? null,
      phiXPercentage: this.formGroup.value.phiXPercentage,
      title: this.formGroup.value.title,
      description: descriptionExtended,
      sampleFile: this.sampleGroup.value.sampleFile,
      sampleIndexHeader: this.sampleGroup.value.sampleIndexHeader,
      departmentCode: this.formGroup.value.departmentCode,
      projectCode: this.formGroup.value.projectCode,
      studyId: this.formGroup.value.studyId,
      collaborator: collaborator
    };
    this.store.dispatch(submitNewRequest({ request }));
    this.dialog.open(DialogInfoComponent, {
      width: '500px',
      data: {
        message:
          'Submitting your request…'
      }
    });
  }

  invalidSubmission() {
    const controls = this.formGroup.controls;
    const messages = Object.keys(this.formGroup.controls)
      .map(field => ({ name: field, status: controls[field].status, errors: controls[field].errors }))
      .filter((e) => e.status !== 'VALID')
      .map((e) => e.name);
    const errorPayload = { error: new NError('Error with fields: ' + messages, {}) };
    this.store.dispatch(insertErrorInvalidSubmission(errorPayload));
  }

  requireReferenceTranscriptomeSetting(libraryMethod: LibraryMethod | null): boolean {
    const requiredSubmissionTypes = [
      SubmissionType.SingleCell,
      SubmissionType.SpatialTranscriptomics,
    ];
    return this.isSubmissionTypeOneOf(libraryMethod, requiredSubmissionTypes);
  }

  requireSpeciesSetting(libraryMethod: LibraryMethod | null): boolean {
    return this.isForDNAorRNA(libraryMethod)
  }

  requireSampleTypeSetting(libraryMethod: LibraryMethod | null): boolean {
    return this.isForDNAorRNA(libraryMethod)
  }

  isForDNAorRNA(libraryMethod: LibraryMethod | null): boolean {
    const requiredSubmissionTypes = [
      SubmissionType.DNA,
      SubmissionType.RNA,
    ];
    return this.isSubmissionTypeOneOf(libraryMethod, requiredSubmissionTypes);
  }

  isSubmissionTypeOneOf(libraryMethod: LibraryMethod | null, submissionTypes: SubmissionType[]): boolean {
    return submissionTypes.includes(<SubmissionType>libraryMethod?.submissionType);
  }
}
