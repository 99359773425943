import { Pipe, PipeTransform } from '@angular/core';
import { LibraryMethod, SubmissionType } from '../../../../sequencing/models/library-method';

@Pipe({
  name: 'submissionTypeKeyValue',
  standalone: true,
})
export class SubmissionTypeKeyValuePipe implements PipeTransform {
  transform(value: Map<SubmissionType, LibraryMethod[]>): { key: SubmissionType; value: LibraryMethod[]; }[] {
    return Array.from(value.keys())
      .sort((a, b) => a.localeCompare(b))
      .sort((a, b) => a === 'Other' ? 1 : b === 'Other' ? -1 : 0)
      .map(key => ({ key, value: value.get(key) }));
  }
}