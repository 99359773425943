import {
  AfterContentInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { FlowCell } from '../../../models/flow-cell';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { PoolSampleMix, SimplePoolWithRequest } from '../../../../bio/models/pool';
import { FlowCellMapping, IndexConflictWithLaneNumber, PoolWithLaneNumber } from '../../../models/flow-cell-mapping';
import {  Store } from '@ngrx/store';
import { AppState } from '../../../../store/app.reducers';
import { selectPoolColor } from '../../../../bio/store';
import { Observable, of } from 'rxjs';
import { selectSelectedFlowCellZeroConcentrationLanes } from '../../../store/flow-cells';
import { map } from 'rxjs/operators';

@Component({
  selector: 'nemo-flowcell-viewer',
  templateUrl: './flow-cell-viewer.component.html',
  styleUrls: ['./flow-cell-viewer.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FlowCellViewerComponent implements AfterContentInit, OnChanges {

  @ViewChild('layout', {static: true}) layoutDiv: ElementRef;

  @Input() flowCell: FlowCell;
  @Input() conflicts: IndexConflictWithLaneNumber[];
  @Input() isReadonly = true;
  @Input() isIcon = false;
  @Input() hideLegend = false;

  // Settings
  @Input() editSettings = false;
  @Input() settingsTooltip: string | null;
  @Output() settingsEdited = new EventEmitter<number>(); // Emit lane number

  @Output() addPool = new EventEmitter<PoolWithLaneNumber>();
  @Output() removePool = new EventEmitter<PoolWithLaneNumber>();

  isZeroConcentrationLane$: Observable<boolean[]>;

  lanePools: FlowCellMapping[][];
  pools: SimplePoolWithRequest[];

  constructor(
    public readonly store: Store<AppState>
  ) {
  }

  ngAfterContentInit(): void {
    this.isZeroConcentrationLane$ = this.store.select(selectSelectedFlowCellZeroConcentrationLanes).pipe(
      
      map(zeroConcentrationLanes => {
        const laneHasZeroConcentration = new Array<boolean>(this.flowCell.numberOfLanes);
        for (let i = 0; i < this.flowCell.numberOfLanes; i++) {
          laneHasZeroConcentration[i] = zeroConcentrationLanes.indexOf(i) >= 0;
        }
        return laneHasZeroConcentration;
      })
    );
    this.updateData();
  }

  ngOnChanges(_: SimpleChanges): void {
    this.updateData();
  }

  getPoolColor(poolAccessionCode: string): Observable<string> {
    if (this.isIcon) {
      return of('darkgrey');
    }
    return this.store.select(selectPoolColor(poolAccessionCode));
  }

  dropPool(laneNumber: number, event: CdkDragDrop<PoolSampleMix>) {
    const pool = event.item.data;
    this.addPool.emit({pool, laneNumber});
  }

  discardPool(pool: PoolSampleMix, laneNumber: number) {
    this.removePool.emit({pool, laneNumber});
  }

  settingsClick(laneNumber: number) {
    this.settingsEdited.emit(laneNumber);
  }

  /**
   group pool per lane and extract list of pools
   */
  private updateData() {
    this.lanePools = [];
    for (let i = 0; i < this.flowCell.numberOfLanes; i++) {
      this.lanePools[i] = [];
    }
    this.flowCell.layout.forEach((m) => this.lanePools[m.laneNumber].push(m));
    this.pools = this.flowCell.getSimplePoolsWithRequest();
  }
}
