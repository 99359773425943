import { Component, Input, OnInit } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import {  Store } from '@ngrx/store';
import { filter, map, take } from 'rxjs/operators';

import * as _ from 'lodash';
import EntityPage from '../../../global-context/models/entity-page';
import { AppState } from '../../../store/app.reducers';
import { findAllCommentsByEntity } from '../../store/comment.actions';
import { selectAllComments } from '../../store/comment.selector';
import { selectLoggedInUser, selectUserDictionary } from '../../../user/store';
import { User } from '../../../user/models/user.model';
import { CommentEntity } from '../../models/commentText';

@Component({
  selector: 'nemo-comment-thread',
  template: `
      <div
              class="container"
              *ngIf="isReady$ | async"
      >
          <nemo-comment-message-list
                  [comments]="comments$|async"
                  [myUserId]="myUserId$| async"
                  [userDictionary]="userDictionary$ | async"
          ></nemo-comment-message-list>
          <nemo-comment-message-new
                  [entityPage]="entityPage"
                  [userDictionary]="userDictionary$ | async"
          ></nemo-comment-message-new>
          <nemo-comment-file-new-button
            [entityPage]="entityPage">
          </nemo-comment-file-new-button>
      </div>
  `,
  styles: [`
      div.container {
          height: 100%;
          display: flex;
          flex-direction: column;
      }

      nemo-comment-message-list {
          flex-grow: 1;
          background-color: #ECEFF1;
      }

      nemo-comment-message-new {
        padding-top: 4px;
          height: 7em;
      }

      nemo-comment-file-new-button {
        display: flex;
        flex-direction: row-reverse;
        padding-bottom: 2px;
      }
  `]
})
export class CommentThreadComponent implements OnInit {
  @Input()
  entityPage: EntityPage;

  comments$: Observable<CommentEntity[]>;
  myUserId$: Observable<string>;
  userDictionary$: Observable<{ [key: string]: User }>;

  isReady$: Observable<boolean>;

  constructor(
    private store: Store<AppState>,
  ) {
  }

  ngOnInit() {
    this.store.dispatch(findAllCommentsByEntity({entityPage: this.entityPage}));

    this.comments$ = this.store.select(selectAllComments);
    this.myUserId$ = this.store.select(selectLoggedInUser).pipe(
      filter(u => Boolean(u)),
      map(u => u.id)
    );
    this.userDictionary$ = this.store.select(selectUserDictionary);

    this.isReady$ = combineLatest([this.comments$, this.myUserId$, this.userDictionary$])
      .pipe(
        filter((a) => {
            return Boolean(a[0]) && a[1] && (_.size(a[2]) > 0);
          }
        ),
        take(1),
        map(() => true)
      );
  }
}
