import { Project } from '../models/Project';
import { Department } from '../models/Department';
import {
  DepartmentAction,
  findAllDepartments,
  findAllDepartmentsAfterUpdate,
  findAllDepartmentsError,
  findAllDepartmentsSuccess
} from './department.actions';
import {
  findAllProjects,
  findAllProjectsAfterUpdate,
  findAllProjectsError,
  findAllProjectsSuccess,
  ProjectAction
} from './project.actions';
import { Lab } from '../models/Lab';
import { Study } from '../models/Study';
import { createReducer, on } from '@ngrx/store';
import {
  StudyAction,
  findAllStudiesAfterUpdate,
  findAllStudiesAtInit,
  findAllStudiesError,
  findAllStudiesSuccess
} from './study.actions';
import { findAllLabs, findAllLabsAfterUpdate, findAllLabsError, findAllLabsSuccess } from './lab.actions';

export default interface CompanyState {
  departments: Department[];
  projects: Project[];
  studies: Study[];
  labs: Lab[];
  message: string;
}

export const initialCompanyState: CompanyState = {
  departments: [],
  projects: [],
  studies: [],
  labs: [],
  message: null
};

const _reducer = createReducer(
  initialCompanyState,
  on(findAllLabs, findAllLabsAfterUpdate, (state): CompanyState => ({...state, labs: [], message: null})),
  on(findAllLabsSuccess, (state, {labs}): CompanyState => ({...state, labs, message: null})),
  on(findAllLabsError, (state, {message}): CompanyState => ({...state, labs: [], message})),
  on(findAllDepartments, findAllDepartmentsAfterUpdate, (state): CompanyState => ({...state, departments: [], message: null})),
  on(findAllDepartmentsSuccess, (state, {departments}): CompanyState => ({...state, departments, message: null})),
  on(findAllDepartmentsError, (state, {message}): CompanyState => ({...state, departments: [], message})),
  on(findAllProjects, findAllProjectsAfterUpdate, (state): CompanyState => ({...state, projects: [], message: null})),
  on(findAllProjectsSuccess, (state, {projects}): CompanyState => ({...state, projects, message: null})),
  on(findAllProjectsError, (state, {message}): CompanyState => ({...state, projects: [], message})),
  on(findAllStudiesAtInit, findAllStudiesAfterUpdate, (state): CompanyState => ({...state, studies: [], message: null})),
  on(findAllStudiesSuccess, (state, {studies}): CompanyState => ({...state, studies, message: null})),
  on(findAllStudiesError, (state, {message}): CompanyState => ({...state, studies: [], message})),
);

export function reducer(state = initialCompanyState, action: DepartmentAction | ProjectAction | StudyAction) {
  return _reducer(state, action);
}
