import { Component } from '@angular/core';
import { HasGlobalContextUserRole } from '../../../global-context/shared/global-context-shared/components/has-global-context-user-role';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/app.reducers';

@Component({
  selector: 'nemo-lab-presentation',
  template: `
    <div *ngIf="(hasLab$() | async) === false" class="lab">
      <div class="lab-presentation">
        <h1>Welcome to NEMO</h1>
        <h2>The Request Tracker</h2>
        <p></p>
        <p>Please select a lab below to get started.</p>
      </div>
      <div class="lab-extra">
      </div>
    </div>
    <div *ngIf="isLab$('genomics') | async" class="lab">
      <div class="lab-presentation">
        <h1>Welcome to NEMO</h1>
        <h2>The Sample Tracker for<br/>the Genomics Lab</h2>
        <p> <b>Recent Update </b></p>
    <table>
      <tr>
         <td>v3.0.0</td>
         <td width="20%">01/22/2025</td>
         <td style="text-align: left">NEMO-283 TECH: Upgrade the backend tech stack (SpringBoot: 2.2.6 -> 3.3.0; Kotlin: 1.5.0 -> 1.9.22; Java: 8 -> 21; and other upgrades) <br/>
                                      NEMO-272 NEW: As a user I can select a submission type in my request submission</td>
      </tr>
      <tr>
         <td>v2.7.3</td>
         <td width="20%">12/18/2024</td>
         <td style="text-align: left">NEMO-282 Fix Tenaya metadata setting issue <br/>
                                      NEMO-274 Adding a new type of request("Protein Assays") to Nemo for Protein Sciences</td>
      </tr>

      <tr>
         <td>v2.7.2</td>
         <td width="20%">11/22/2024</td>
         <td style="text-align: left">NEMO-265 Add Donor/Patient checkbox in submitting request page</td>
      </tr>

      <tr>
         <td>v2.7.1</td>
         <td width="20%">11/08/2024</td>
         <td style="text-align: left">NEMO-253 Add Search function for Nemo Request - Department,Project and Library method<br/>
                                      NEMO-254 Update Nemo project list with latest calico project list from financial department</td>
      </tr>

      <tr>
         <td>v2.7.0</td>
         <td width="20%">11/01/2024</td>
         <td style="text-align: left">[CRO support]NEMO-241 To show NOR info in request page; <br/>
                                      NEMO-252 Adding 10x cellranger yeast reference</td>
      </tr>

      <tr>
         <td>v2.6.9</td>
         <td width="20%">10/22/2024</td>
         <td style="text-align: left">NEMO-242 Send email to Sequencing admin while user clicks "notify requesters" button in flowcell page;<br/>
                                      NEMO-243 Turn back chat notifications on side bar</td>
      </tr>

      <tr>
         <td>v2.6.8</td>
         <td width="20%">09/26/2024</td>
         <td style="text-align: left">NEMO-236 Set Tentaya Metadata for new Azenta files in cronjob;<br/>
                                      NEMO-238 Send email notification if someone tasg a Nemo user in Nemo comments bar </td>
      </tr>

      <tr>
         <td>v2.6.7</td>
         <td width="20%">09/23/2024</td>
         <td style="text-align: left">[Bug fix]NEMO-217 New reqs appearing to have duplicated sequencing/demux parameters;<br/>
                                       NEMO-222 To add "Sample Type" setting to Nemo on Request Page </td>
      </tr>

      <tr>
         <td>v2.6.6</td>
         <td width="20%">09/13/2024</td>
         <td style="text-align: left"> [Bug fix] NEMO-232 Inability to view requests/submissions<br/></td>
      </tr>

      <tr>
         <td>v2.6.5</td>
         <td width="20%">09/06/2024</td>
         <td style="text-align: left"> [Bug fix] NEMO-221 auto-populating not working while uploading samples by index_id;<br/>
                                         NEMO-225 To add pagination support for "Genomics Requets list" page
         </td>
      </tr>

      <tr>
         <td>v2.6.4</td>
         <td width="20%">08/23/2024</td>
           <td style="text-align: left"> [Bug fix] NEMO-212 To fix "too big samplesheets file" issue while doing "export to pipeline";<br/>
                                         NEMO-223 To support the lab name change , from "Biochemistry" lab To "Protein Sciences" lab
         </td>
      </tr>

      <tr>
         <td>v2.6.3</td>
         <td width="20%">08/07/2024</td>
         <td style="text-align: left">NEMO-213 Disable email notifications in Nemo for "request commenting";<br/>
                                      [Bug fix] NEMO-214 Failed to submit the request when using new index kit model;<br/>
                                      NEMO-215 To support updating fastq Tenaya metadata -- sample_id, sample_name, read_file
         </td>
      </tr>


      <tr>
         <td>v2.6.2</td>
         <td width="20%">07/16/2024</td>
         <td style="text-align: left">[Bug fix] NEMO-205 Incorrect link in email notification;<br/>
                                      [Feature] NEMO-207 To add Salmon quantitation parameter "Organism" setting while user submitting request for BULK-RNASEQ;<br/>
                                      [Feature] NEMO-211 To Add cronjob and sidecar container to update Tenaya fastqfiles' metadata
         </td>
      </tr>

      <tr>
         <td>v2.6.1</td>
         <td width="20%">06/10/2024</td>
         <td style="text-align: left">[Feature enhancement]NEMO-203 Update the fastq file path email subject</td>
      </tr>

      <tr>
         <td>v2.6.0</td>
         <td width="20%">05/31/2024</td>
         <td style="text-align: left">Angular version upgrade: 10 to 16</td>
      </tr>

      <tr>
         <td>v2.5.9</td>
         <td width="20%">05/22/2024</td>
           <td style="text-align: left">[Bug Fix] NEMO-202 Fastqfile path email notification was sent to incorrect address</td>
      </tr>
      <tr>
         <td>v2.5.8</td>
         <td width="20%">05/06/2024</td>
         <td style="text-align: left">[Feature] NEMO-196 Add a 'notify requester' button in flowcell page for fastq files path; <br/>
                                      [Bug fix]NEMO-200 Nemo search for old RequestID such as "REQ_4B2J" does not return request</td>
     </tr>
     <tr>
         <td>v2.5.7</td>
         <td width="20%">04/08/2024</td>
           <td style="text-align: left">[Bug Fix]NEMO-198 Comments posted to Biochem Nemo request were sent to Genomic email alias</td>
      </tr>
      <tr>
         <td>v2.5.6</td>
         <td width="20%">03/27/2024</td>
           <td style="text-align: left">[Bug Fix]NEMO-197 Cannot see/select index kit while doing index assignment</td>
      </tr>
       <tr>
         <td>v2.5.5</td>
         <td width="20%">02/27/2024</td>
           <td style="text-align: left">NEMO-192 Email comments to Genomics team; NEMO-193 Enhance verification while submitting requests; NEMO-194 Update request page layout</td>
      </tr>

       <tr>
         <td>v2.5.3</td>
         <td width="20%">12/05/2023</td>
         <td style="text-align: left">NEMO-180 Send email notification to requester when the fastq files have been created</td>
      </tr>

       <tr>
         <td>v2.5.2</td>
         <td width="20%">12/01/2023</td>
         <td style="text-align: left">NEMO-185 Fix the broken links to the pool and plate examples</td>
      </tr>

       <tr>
         <td>v2.5.1</td>
         <td width="20%">10/24/2023</td>
         <td style="text-align: left">NEMO-179 Limit allowed users to access report dashboard</td>
      </tr>

       <tr>
         <td>v2.5.0</td>
         <td width="20%">10/17/2023</td>
         <td style="text-align: left">NEMO-178 Add Nemo Looker dashboard as part of Nemo reporting page</td>
      </tr>

       <tr>
         <td>v2.4.10</td>
         <td width="20%">10/06/2023</td>
         <td style="text-align: left">NEMO-177 Bug-fix Echo pooling pool's name is not as expected in some conditions</td>
      </tr>

       <tr>
         <td>v2.4.9</td>
         <td width="20%">09/29/2023</td>
         <td style="text-align: left">NEMO-175 Update the conditions to show "reference transcriptome" field while creating request</td>
      </tr>

       <tr>
         <td>v2.4.8</td>
         <td width="20%">09/12/2023</td>
         <td style="text-align: left">NEMO-174 If "reference transcriptome" is not set, we don't want to show it in description</td>
      </tr>

       <tr>
         <td>v2.4.7</td>
         <td width="20%">09/12/2023</td>
         <td style="text-align: left">NEMO-173 If submitter doesn't set concentration values, we want to keep it as blank</td>
      </tr>

       <tr>
         <td>v2.4.6</td>
         <td width="20%">09/06/2023</td>
         <td style="text-align: left">NEMO-172 add "reference transcriptome" field in request form for 10x runs</td>
      </tr>

       <tr>
         <td>v2.4.5</td>
         <td width="20%">08/25/2023</td>
         <td style="text-align: left">NEMO-171 bug-fix Submitter concentration values was not setting correctly in Benchling</td>
      </tr>

       <tr>
         <td>v2.4.4</td>
         <td width="20%">08/18/2023</td>
         <td style="text-align: left">Fix deployment config error</td>
      </tr>

       <tr>
         <td>v2.4.3</td>
         <td width="20%">08/18/2023</td>
         <td style="text-align: left">LIMS-1488 Decouple Nemo with Benchling; NEMO-167 To add fastq path(R1/R2) in exported flowcell csv</td>
      </tr>

       <tr>
         <td>v2.4.2</td>
         <td width="20%">08/09/2023</td>
         <td style="text-align: left">LIMS-1469 bug-fix: duplicated headers in the sequencing sample sheet</td>
      </tr>

       <tr>
         <td>v2.4.1</td>
         <td width="20%">07/24/2023</td>
         <td style="text-align: left">NEMO-166 duplicated column bug for flowcell lane assignment</td>
       </tr>

       <tr>
          <td>v2.4.0</td>
          <td width="20%">06/30/2023</td>
          <td style="text-align: left">Add support to integrate with Benchling.</td>
       </tr>

       <tr>
          <td>v2.3.2</td>
          <td width="20%">06/07/2023</td>
          <td style="text-align: left">NEMO-165 Fix missing Comments bar issue for Biochem lab.</td>
       </tr>

       <tr>
          <td>v2.3.1</td>
          <td width="20%">05/27/2023</td>
          <td style="text-align: left">Switch to github from gitlab, also complete GCP infrastructure migration.</td>
       </tr>

       <tr>
          <td>v2.2.1</td>
          <td width="20%">05/04/2023</td>
          <td style="text-align: left">Fix NEMO-157, Comments bar not showing issue.</td>
       </tr>
       <tr>
          <td>v2.2</td>
          <td width="20%">05/03/2023</td>
          <td style="text-align: left">Update IDs naming convention. </td>
       </tr>
       <tr>
          <td>v2.1</td>
          <td width="20%">01/20/2023</td>
          <td style="text-align: left">Fix NEMO-134 JDBC error happening while querying pool in flowcell page. </td>
      </tr>
       <tr>
          <td>v2.0</td>
          <td width="20%">01/04/2023</td>
          <td style="text-align: left">Two new columns have been added into sample request templates, Sample NOR info table has been added into flowcells page for admin users. </td>
       </tr>
     </table>
        <p>We will provide Illumina sequencing for your samples. You
          can submit RNA or DNA samples, or you can submit libraries
          that you have already prepared.</p>

        <p>Submit your samples by following the simple steps on the
          <a href="https://intranet.calicolabs.com/labs/genomics-lab/submitting-your-samples-illumina-sequencing"
             target="_blank">Calico intranet</a>.
        </p>

        <p>Questions? Please contact us
          <a href="mailto:calico-genomics-lab@calicolabs.com">by email</a>
          or stop by our lab F306 to chat with us.
        </p>
      </div>
      <div class="lab-extra">
        <img src="assets/genomics.jpg" alt="Genomics Lab"/>
      </div>
    </div>
    <div *ngIf="isLab$('biochem') | async" class="lab">
      <div class="lab-presentation">
        <h1>Welcome to NEMO</h1>
        <h2>The Protein Sciences Group</h2>
        <p>We are able to offer a range of services including:</p>
        <nemo-form-section-shortcuts
        ></nemo-form-section-shortcuts>
        <p>Please select one of the above links and follow the steps to submit a request. Questions can be
sent to us by email or you may find us in Building 1180, room G222(office) and room G238(lab). We look forward to working with you.</p>
      </div>
      <div class="lab-extra">
        <h3>Point of Contact</h3>
        <div>
          <h4>Group Leader, Protein Sciences</h4>
          <div>Qi Hao</div>
        </div>
        <div>
          <h4>DNA Preparation/Construct Design</h4>
          <div>Janani Sridar</div>
          <div>Matthew Bratkowski</div>
          <div>John Wang</div>
        </div>
        <div>
          <h4>Protein Expression/Purification</h4>
          <div>Matthew Bratkowski</div>
          <div>Janani Sridar</div>
          <div>John Wang</div>
        </div>
        <div class="last">
          <h4>Biophysical Technologies</h4>
          <div>John Wang</div>
          <div>Janani Sridar</div>
        </div>
      </div>
    </div>
  `,
  styles: [`
    .lab {
      display: flex;
      flex-direction: row;
      min-height: 420px;
    }

    .lab-presentation {
      width: 460px;
      min-width: 460px;
    }

    h1, h2 {
      font-family: Calibri, Roboto, sans-serif;
      font-weight: normal;
      text-align: center;
    }

    h1 {
      font-size: 3em;
    }

    h2 {
      font-size: 2.3em;
    }

    table, th, td {
      border: 1px solid;
    }
    table {
      border-collapse:collapse;
    }
    td {
      vertical-align: middle;
      text-align: center;
    }
    p {
      font-family: Calibri, Roboto, sans-serif;
      font-size: 1.3em;
    }

    li {
      font-size: 1.2em;
    }

    img {
      max-height: 420px;
    }

    .lab-extra {
      display: flex;
      flex-direction: column;
      margin-left: 20px;
    }

    .lab-extra h3 {
      margin-top: auto;
    }

    .lab-extra h4 {
      margin: 1em 0 2px 0;
    }

    .lab-extra .last {
      margin-bottom: 2em;
    }
  `]
})
export class LabPresentationComponent extends HasGlobalContextUserRole {

  constructor(
    private store: Store<AppState>,
  ) {
    super(store);
  }

}
