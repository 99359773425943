import { createSelector } from '@ngrx/store';
import { AppState } from '../../../store/app.reducers';
import FlowCellsState from './flow-cells.reducer';
import { FlowCell } from '../../models/flow-cell';

export const getFlowCellsState = (state: AppState) => state.flowCells;

export const selectSelectedFlowCell = createSelector(
  getFlowCellsState,
  (state: FlowCellsState) => state.selected
);

export const selectFlowCellSampleNors = createSelector(
  getFlowCellsState,
  (state: FlowCellsState) => state.sampleNors
);

export const selectFlowCellRequestFastqDirs = createSelector(
  getFlowCellsState,
  (state: FlowCellsState) => state.requestFastqDirs
);

export const selectNorAdmins = createSelector(
  getFlowCellsState,
  (state: FlowCellsState) => state.norAdmins
);

export const selectIsSelectedFlowCellSaved = createSelector(
  getFlowCellsState,
  (state: FlowCellsState) => state.selectedIsSaved
);

export const selectSelectedFlowCellConflict = createSelector(
  getFlowCellsState,
  (state: FlowCellsState) => state.selectedConflicts || []
);

export const selectSelectedFlowCellZeroConcentrationLanes = createSelector(
  selectSelectedFlowCell,
  (selectedFlowCell: FlowCell) => selectedFlowCell.concentrations.reduce((indices, concentration, index) => {
    if (concentration <= 0) {
      return [...indices, index];
    }
    return indices;
  }, [])
);



export const selectIsSelectedFlowCellSequencerConfigurationStateImproper = createSelector(
  selectSelectedFlowCell,
  (selectedFlowCell: FlowCell) => {
    const sequencer = selectedFlowCell.sequencerModel;
    const conf = selectedFlowCell.sequencerConfiguration;
    if (sequencer.flowCellTypes.length > 0) {
      if (conf === null || typeof conf.flowCellType === 'undefined') {
        return true;
      }
    }
    if (sequencer.loadingTypes.length > 0) {
      if (conf === null || typeof conf.loadingType === 'undefined') {
        return true;
      }
    }
    return false;
  }
);

export const selectFlowCells = createSelector(
  getFlowCellsState,
  (state: FlowCellsState) => state.flowCells
);

export const selectFlowCellPageInfo = createSelector(
  getFlowCellsState,
  (state: FlowCellsState) => state.pageInfo
);
