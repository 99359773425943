import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { PageInfo } from '../../shared/models/page.model';
import {  Store } from '@ngrx/store';
import { AppState } from '../../store/app.reducers';
import { findAllFlowCellsFromTodoPage, selectFlowCellPageInfo, selectFlowCells } from '../../labware/store/flow-cells';
import { FlowCell } from '../../labware/models/flow-cell';

@Component({
  selector: 'nemo-todo-lane-assignment',
  template: `
    <nemo-pending-title title="Select a flow cell to assign lanes"></nemo-pending-title>
    <nemo-flow-cell-table *ngIf="(flowCells$ | async)?.length > 0"
                          [flowCells]="flowCells$ | async"></nemo-flow-cell-table>
    <nemo-paginator [pageInfo]="pageInfo$ | async" (pageChange)="findAll($event[0], $event[1])"></nemo-paginator>
  `
})
export class TodoLaneAssignmentComponent implements OnInit {

  flowCells$: Observable<FlowCell[]>;
  pageInfo$: Observable<PageInfo>;

  constructor(private store: Store<AppState>) {
  }

  ngOnInit() {
    this.findAll(25, 0);
    this.pageInfo$ = this.store.select(selectFlowCellPageInfo);
    this.flowCells$ = this.store.select(selectFlowCells);
  }

  findAll(pageSize: number, page: number) {
    this.store.dispatch(findAllFlowCellsFromTodoPage({pageSize, page}));
  }
}
