import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectPlateByIdentifier, selectRangeSelectionsByIdentifier, updateSetSelection } from '../../../store/plates';
import { Plate96 } from '../../../models/plate-96';
import { Observable } from 'rxjs';
import { AppState } from '../../../../store/app.reducers';
import { PlateCoordinatesSelection } from '../../../models/plate-coordinates-selection';
import { PlateCoordinatesInterval } from '../../../models/plate-coordinates-interval';
import { BioMaterial } from '../../../../bio/models/bio-material';

/**
 * Container for a PlateViewer2DComponent that listens to the store for the latest plate data.
 */
@Component({
  selector: 'nemo-plate-viewer-container',
  templateUrl: './plate-viewer-container.component.html',
  styleUrls: ['./plate-viewer-container.component.scss']
})
export class PlateViewerContainerComponent implements OnInit {

  @Input() plateAccessionCode: string;  // accessionCode of the plate to listen to
  @Input() isReadonly: boolean;
  @Input() colorStringFn: (BioMaterial) => string = null;
  @Input() showLegend = false;

  wellSelection$: Observable<PlateCoordinatesSelection>;
  plate$: Observable<Plate96<BioMaterial>>;

  constructor(
    public readonly store: Store<AppState>,
  ) {
  }

  ngOnInit() {
    this.subscribeToSelection();
    this.subscribeToPlate();
  }

  private subscribeToPlate() {
    this.plate$ = this.store.select(selectPlateByIdentifier(this.plateAccessionCode));
  }

  private subscribeToSelection() {
    this.wellSelection$ = this.store.select(selectRangeSelectionsByIdentifier(this.plateAccessionCode));
  }

  /**
   * Callback to pass to the viewer on bioMaterialPlateMappings selection (mouse events)
   */
  updateSelection(interval: PlateCoordinatesInterval) {
    this.store.dispatch(updateSetSelection({
      plateAccessionCode: this.plateAccessionCode,
      pos: interval,
      clear: true
    }));
  }
}
