import { Component, OnInit } from '@angular/core';
import {  Store } from '@ngrx/store';
import { AppState } from '../../store/app.reducers';
import { findAllPlatesFromTodoPage } from '../../labware/store/plates/actions/plate-list.action';
import { Observable } from 'rxjs';
import { SimplePlateWithRequests } from '../../labware/models/simple-plate';
import { PageInfo } from '../../shared/models/page.model';
import { selectPlateList } from '../../labware/store/plates';
import { TaskAvailable } from '../../tasks/models/task.model';

@Component({
  selector: 'nemo-todo-index-assignment',
  template: `
    <nemo-pending-title title="Plates ready for index assigment"></nemo-pending-title>
    <nemo-plate-list *ngIf="(plates$ | async)?.length > 0" [plates]="plates$ | async"></nemo-plate-list>
  `,
})
export class TodoIndexAssignmentComponent implements OnInit {

  plates$: Observable<SimplePlateWithRequests[]>;

  constructor(private store: Store<AppState>) {
  }

  ngOnInit() {
    this.findAll();
    this.plates$ = this.store.select(selectPlateList(TaskAvailable.INDEX_ASSIGNMENT));
  }

  findAll() {
    this.store.dispatch(findAllPlatesFromTodoPage({availableTask: TaskAvailable.INDEX_ASSIGNMENT}));
  }
}
