import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, concatLatestFrom } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';
import { PlateService } from '../../services/plate.service';
import {
  archivePlate,
  archivePlateError,
  archivePlateSuccess,
  countPlatesFromTodoPage,
  findAllPlatesFromAPI,
  findAllPlatesFromEchoPooling,
  findAllPlatesFromIA,
  findAllPlatesFromReArray,
  findAllPlatesSnippets,
  findAllPlatesSnippetsSuccess,
  findAllPlatesToAssignQC,
  findAllPlates,
  findAllPlatesFromTodoPage,
  processEchoPooling,
  processEchoPoolingError,
  processEchoPoolingSuccess,
  processReArray,
  processReArrayError,
  processReArraySuccess,
  unarchivePlate,
  unarchivePlateError,
  unarchivePlateSuccess
} from './actions/plate-list.action';
import {
  countPlatesSuccess,
  platesFindAllError,
  platesFindAllSuccess,
  platesSearchError,
  platesSearchSuccess
} from './actions/plates-api.action';
import { platesSearch, PlatesSearchBarAction } from './actions/plates-search-bar.action';
import { Router } from '@angular/router';
import { echoPoolingImport, findPlateByAccessionCodeThenSelect } from './actions/plates-details.actions';
import { ECHO_POOLING_DESTINATION_PLATE_ID } from '../../../tasks/echo-pooling/components/echo-pooling-left-zone.component';
import {
  changeArchiveFilter,
  selectIsUnarchivedDefaultFalse,
  selectIsUnarchivedIgnoringNull,
} from '../../../table/store';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/app.reducers';
import { TaskAvailable } from '../../../tasks/models/task.model';
import { PlateSnippet } from '../../dto/labware.dto';

@Injectable()
export class PlatesApiEffects {

  constructor(
    private router: Router,
    private actions$: Actions,
    private store: Store<AppState>,
    private plateService: PlateService
  ) {
  }

  
  findAllPlates = createEffect(() => { return this.actions$.pipe(
    ofType(
      findAllPlates.type,
      findAllPlatesFromIA.type,
      findAllPlatesFromReArray.type,
      findAllPlatesFromTodoPage.type,
      findAllPlatesFromEchoPooling.type,
      findAllPlatesFromAPI.type
    ),
    concatLatestFrom(() => this.store.select(selectIsUnarchivedDefaultFalse('plate'))),
    mergeMap(([action, showArchived]) =>
      this.plateService.findAll(showArchived, action['availableTask']).pipe(
        map((plateSnippets: PlateSnippet[]) =>
          platesFindAllSuccess({plates: plateSnippets, mapKey: action['availableTask']})
        ),
        catchError(error => of(platesFindAllError({message: error.message})))
      )
    ),
  ) });

  
  findAllPlatesToAssignQC = createEffect(() => { return this.actions$.pipe(
    ofType(
      findAllPlatesToAssignQC.type
    ),
    concatLatestFrom(() => this.store.select(selectIsUnarchivedIgnoringNull('plate'))),
    mergeMap(([action, showArchived]) =>
      this.plateService.findAll(showArchived, TaskAvailable.QC_PLATE_ASSIGN_DIRECTORY_TO).pipe(
        map((plateSnippets: PlateSnippet[]) =>
          platesFindAllSuccess({
            plates: plateSnippets,
            mapKey: TaskAvailable.QC_PLATE_ASSIGN_DIRECTORY_TO
          })
        ),
        catchError(error => of(platesFindAllError({message: error.message})))
      )
    ),
  ) });

  
  findAllSnippets$ = createEffect(() => { return this.actions$.pipe(
    ofType(findAllPlatesSnippets.type),
    mergeMap(action => this.plateService.findAllSnippetsNotArchived()),
    map((snippets) => findAllPlatesSnippetsSuccess({snippets}))
  ) });

  
  count = createEffect(() => { return this.actions$.pipe(
    ofType(countPlatesFromTodoPage.type),
    mergeMap((action: ReturnType<| typeof countPlatesFromTodoPage>) =>
      this.plateService.count(action.availableTask).pipe(
        map(plateCounts => countPlatesSuccess({plateCounts, availableTask: action.availableTask})),
        catchError(error => of(platesFindAllError({message: error.message})))
      )
    )
  ) });

  
  searchPlates = createEffect(() => { return this.actions$.pipe(
    ofType(platesSearch.type),
    mergeMap((action: PlatesSearchBarAction) => this.plateService.search(action.query, action.showArchived)),
    map(plates => platesSearchSuccess({plates})),
    catchError(error => of(platesSearchError({message: error.message})))
  ) });

  
  processReArray = createEffect(() => { return this.actions$.pipe(
    ofType(processReArray.type),
    mergeMap((action: ReturnType<typeof processReArray>) =>
      this.plateService.processReArray(action.accessionCode).pipe(
        map(() => (processReArraySuccess({accessionCode: action.accessionCode}))),
        catchError(error => of(processReArrayError({message: error.message})))
      )
    )
  ) });

  
  processEchoPooling = createEffect(() => { return this.actions$.pipe(
    ofType(processEchoPooling.type),
    mergeMap((action: ReturnType<typeof processEchoPooling>) =>
      this.plateService.processEchoPooling(action.accessionCode).pipe(
        map(() => (processEchoPoolingSuccess({accessionCode: action.accessionCode}))),
        catchError(error => of(processEchoPoolingError({message: error.message})))
      )
    )
  ) });

  
  echoPoolingImport = createEffect(() => { return this.actions$.pipe(
    ofType(echoPoolingImport.type),
    mergeMap((action: ReturnType<typeof echoPoolingImport>) =>
      this.plateService.echoPoolingImport(action.targetAccessionCode, action.file).pipe(
        map(() => (findPlateByAccessionCodeThenSelect({
          accessionCode: action.targetAccessionCode,
          putPlateIdentifier: ECHO_POOLING_DESTINATION_PLATE_ID
        }))),
        catchError(error => of(processReArrayError({message: error.message})))
      )
    ),
  ) });

  
  changeStatusFilter = createEffect(() => { return this.actions$.pipe(
    ofType(changeArchiveFilter.type),
    filter((action: ReturnType<typeof changeArchiveFilter>) => action.tableId === 'plate'),
    map(() => findAllPlatesFromAPI({}))
  ) });

  
  archive = createEffect(() => { return this.actions$.pipe(
    ofType(archivePlate.type),
    mergeMap((action: ReturnType<typeof archivePlate>) => this.plateService.archive(action.accessionCode)),
    map((plateSnippet) => archivePlateSuccess({plateSnippet})),
    catchError(error => of(archivePlateError({message: error.message})))
  ) });

  
  unarchive = createEffect(() => { return this.actions$.pipe(
    ofType(unarchivePlate.type),
    mergeMap((action: ReturnType<typeof unarchivePlate>) => this.plateService.restore(action.accessionCode)),
    map((plateSnippet) => unarchivePlateSuccess({plateSnippet})),
    catchError(error => of(unarchivePlateError({message: error.message})))
  ) });
}
