<form [formGroup]="formGroup" (ngSubmit)="onSubmit()" class="submit-form">
  <mat-form-field class="full-width" appearance="fill">
    <mat-label>Title</mat-label>
    <input matInput #title type="text" formControlName="title"/>
    <mat-hint align="end">{{title.value.length}} / 80</mat-hint>
  </mat-form-field>

  <div>
    <h4>Request origin</h4>
    <div class="request-origin">
      <nemo-department-select [control]="formGroup.controls['departmentCode']"></nemo-department-select>
      <nemo-project-select [control]="formGroup.controls['projectCode']"></nemo-project-select>
      <nemo-study-select [control]="formGroup.controls['studyId']"></nemo-study-select>
      <nemo-collaborator-select [control]="formGroup.controls['collaborator']"></nemo-collaborator-select>
    </div>
  </div>

  <nemo-request-submission-form-library-method [formGroup]="formGroup"
                                               [libraryMethodsGroupBySubmissionType]="libraryMethodsBySubmissionType$ | async"
                                               [selectedLibraryMethod]="selectedLibraryMethod"
                                               (selectedLibraryMethodChange)="selectedSubmissionTypeChanged($event)"/>

  <ng-container *ngIf="!!selectedLibraryMethod">

    <nemo-request-submission-form-ref-transcriptome *ngIf="requireReferenceTranscriptomeSetting(selectedLibraryMethod)"
                                                    [formGroup]="formGroup"/>

    <nemo-request-submission-form-species *ngIf="requireSpeciesSetting(selectedLibraryMethod)"
                                          [formGroup]="formGroup"/>

    <nemo-request-submission-form-sample-type *ngIf="requireSampleTypeSetting(selectedLibraryMethod)"
                                              [formGroup]="formGroup"
                                              [selectedLibraryMethod]="selectedLibraryMethod"/>

    <nemo-request-submission-form-phix [formGroup]="formGroup"/>

    <nemo-request-submission-form-demux-parameters [formGroup]="formGroup"
                                                   [param10xMin]="param10xMin"
                                                   [param10xMax]="param10xMax"/>

    <!-- Comment -->
    <h4>Description</h4>
    <span class="description">General information on the request, special comments</span>
    <mat-form-field class="full-width" appearance="outline">
      <textarea rows="5" matInput #description type="text" formControlName="description"
                placeholder="Description"></textarea>
      <mat-hint align="end">{{description.value.length}} / 5000</mat-hint>
    </mat-form-field>

    <!-- Checkbox -->
    <div>
      <mat-checkbox formControlName="isRestrictedAccessCtl">
        Check this box if the samples are from Donors or Patients to enable restricted access.
      </mat-checkbox>
    </div>

    <div *ngIf="formGroup.get('isRestrictedAccessCtl')?.value" class="notice">
      <b>Notice: Generated data is accessible only to specified Calico accounts.</b>
      <div>
        <label for="needAccessEmails">Please enter the Calico email addresses that require access to the data:</label>
        <input id="needAccessEmails"
               type="text"
               formControlName="needAccessEmailsCtl"
               placeholder="Enter email addresses separated by commas"
               style="width: 600px;"
               [class.invalid]="formGroup.get('needAccessEmailsCtl')?.invalid && formGroup.get('needAccessEmailsCtl')?.touched"/>
      </div>
      <div *ngIf="formGroup.get('needAccessEmailsCtl')?.hasError('invalidEmails')">
        <small style="color: red;">Invalid emails: {{ formGroup.get('needAccessEmailsCtl')?.getError('invalidEmails') }}</small>
      </div>
      <div *ngIf="formGroup.get('needAccessEmailsCtl')?.hasError('required')">
        <small style="color: red;">This field is required.</small>
      </div>
    </div>

    <!-- File upload -->
    <nemo-request-submission-form-file-upload [formGroup]="sampleGroup"
                                              [isPristine]="isPristine"
                                              (sampleFileChange)="sampleFileChange($event)"/>

    <!-- Submit -->
    <button id="submit-request-button"
            type="submit"
            mat-raised-button
            [disabled]="!formGroup.valid"
            color="primary">
      Submit request
    </button>

  </ng-container>
  <p>All fields with * are required</p>
</form>
