import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { archiveIndexKit, selectIndexKits, unarchiveIndexKit } from '../../store';
import { AppState } from '../../../store/app.reducers';
import { Observable } from 'rxjs';
import { SimpleIndexKit } from '../../models/indexKit';

@Component({
  selector: 'nemo-index-kit-table',
  template: `
    <table mat-table [dataSource]="indexKits$ | async" class="table">
      <ng-container matColumnDef="index-kit-icon">
        <th mat-header-cell *matHeaderCellDef class="table-header"></th>
        <td mat-cell *matCellDef="let indexKit">
          <nemo-plate-icon [dimensions]="indexKit.dimensions" [occupancy]="indexKit.occupancy"></nemo-plate-icon>
        </td>
      </ng-container>
      <ng-container matColumnDef="barcode">
        <th mat-header-cell *matHeaderCellDef class="table-header"><span>Barcode</span></th>
        <td mat-cell *matCellDef="let indexKit" class="bold table-line"><span>{{indexKit.barcode}}</span></td>
      </ng-container>

      <ng-container matColumnDef="libraryMethods">
        <th mat-header-cell *matHeaderCellDef class="table-header"><span>Library method </span></th>
        <td mat-cell *matCellDef="let indexKit">
          <div *ngFor="let libraryMethod of indexKit.libraryMethods" class="table-line">{{libraryMethod.name}}</div>
        </td>
      </ng-container>

      <ng-container matColumnDef="indexKitModel">
        <th mat-header-cell *matHeaderCellDef class="table-header"><span>Name</span></th>
        <td mat-cell *matCellDef="let indexKit" class="table-line"><span>{{indexKit.indexKitModel}}</span></td>
      </ng-container>

      <ng-container matColumnDef="availableIndexes">
        <th mat-header-cell *matHeaderCellDef class="table-header"><span>Available indexes</span></th>
        <td mat-cell *matCellDef="let indexKit" class="table-line"><span>{{indexKit.numberOfAvailableIndexes}}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="archive">
        <th mat-header-cell *matHeaderCellDef class="table-header">
          <nemo-archive-filter tableId="index-kit"></nemo-archive-filter>
        </th>
        <td mat-cell *matCellDef="let indexKit" class="table-line">
          <nemo-switch-button [archived]="indexKit.archived"
                              (event)="onSwitchButtonClick(indexKit.id, $event)"></nemo-switch-button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  `
})
export class IndexKitTableComponent implements OnInit {

  displayedColumns: string[] = ['index-kit-icon', 'barcode', 'libraryMethods', 'indexKitModel', 'availableIndexes', 'archive'];
  indexKits$: Observable<SimpleIndexKit[]>;

  constructor(private store: Store<AppState>) {
  }

  ngOnInit() {
    this.indexKits$ = this.store.select(selectIndexKits);
  }

  onSwitchButtonClick(id: number, archive: boolean) {
    if (archive) {
      this.store.dispatch(archiveIndexKit({id}));
    } else {
      this.store.dispatch(unarchiveIndexKit({id}));
    }
  }
}
