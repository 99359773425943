<div class="container">
  <div class="layout" #layout>
    <div *ngFor="let lane of lanePools; index as i"
         class="lane"
         [class.has-conflict]="conflicts && conflicts[i]">
      <div *ngIf="editSettings"
           class="label settings clickable"
           (click)="settingsClick(i)">
        <mat-icon [class.hidden]="lane.length === 0"
                  [class.zeroConcentration]="(isZeroConcentrationLane$ | async)[i]"
                  [matTooltip]="(isZeroConcentrationLane$ | async)[i] ? 'concentration should be between 0.50 and 3.00nM' : settingsTooltip">equalizer
        </mat-icon>
        <span [class.zeroConcentration]="(isZeroConcentrationLane$ | async)[i]"
        >{{flowCell.concentrations[i] ? flowCell.concentrations[i] : '[?]'}}nM</span>
      </div>
      <div *ngIf="!editSettings"
           class="label settings">
        <span>{{flowCell.concentrations[i] ? flowCell.concentrations[i] : '[?]'}}nM</span>
      </div>
      <div class="content"
           cdkDropList
           (cdkDropListDropped)="dropPool(i, $event)"
           cdkDragDisabled>
        <div *ngFor="let poolFraction of lane"
             class="pool-fraction"
             [style.flexGrow]="poolFraction.laneOccupancy"
             [style.background]="getPoolColor(poolFraction.simplePoolWithRequest.accessionCode) | async"
        >
          <div class="remove"
               *ngIf="!isReadonly">
            <a (click)="discardPool(poolFraction.simplePoolWithRequest, i)">
              <mat-icon>delete</mat-icon>
            </a>
          </div>
        </div>
      </div>
      <div class="label">
        {{i + 1}}
      </div>
    </div>

  </div>
  <div class="guide" *ngIf="!isIcon && !hideLegend">
    <div *ngFor="let pool of pools" class="color-legend pool">
      <span [style.color]="getPoolColor(pool.accessionCode) | async" class="symbol">●</span>
      {{pool.accessionCode}}
    </div>
  </div>
</div>
