import { Component, OnInit } from '@angular/core';
import {  Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Index } from '../../../../bio/models/barcode-index';
import { Plate96 } from '../../../../labware/models/plate-96';
import { AppState } from '../../../../store/app.reducers';
import { selectPlateByIdentifier, selectRangeSelectionsByIdentifier } from '../../../../labware/store/plates';
import { EVENT_LISTENER_ID_INDEXES, EVENT_LISTENER_ID_SAMPLES } from '../../constants';
import { PlateCoordinatesSelection } from '../../../../labware/models/plate-coordinates-selection';
import { BioMaterial } from '../../../../bio/models/bio-material';
import { PoolSamplePlaceholder } from '../../../../bio/models/pool';

@Component({
  selector: 'nemo-request-index-assignment',
  templateUrl: './request-index-assignment.component.html',
  styleUrls: ['./request-index-assignment.component.scss']
})
export class RequestIndexAssignmentComponent implements OnInit {

  samplePlate$: Observable<Plate96<PoolSamplePlaceholder>>;
  indexPlate$: Observable<Plate96<Index>>;

  currentSelectionSamples$: Observable<PlateCoordinatesSelection>;
  currentSelectionIndexes$: Observable<PlateCoordinatesSelection>;

  constructor(private store: Store<AppState>) {
  }

  ngOnInit() {
    this.samplePlate$ = this.store.select(selectPlateByIdentifier(EVENT_LISTENER_ID_SAMPLES))
      .pipe(
        
        map((plate: Plate96<BioMaterial>) => plate as Plate96<PoolSamplePlaceholder>)
      );

    this.indexPlate$ = this.store.select(selectPlateByIdentifier(EVENT_LISTENER_ID_INDEXES))
      .pipe(
        
        map((plate: Plate96<BioMaterial>) => plate as Plate96<Index>)
      );

    this.currentSelectionSamples$ = this.store
      .select(selectRangeSelectionsByIdentifier(EVENT_LISTENER_ID_SAMPLES));

    this.currentSelectionIndexes$ = this.store
      .select(selectRangeSelectionsByIdentifier(EVENT_LISTENER_ID_INDEXES));
  }
}
