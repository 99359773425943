import { AppState } from '../../store/app.reducers';
import { createSelector } from '@ngrx/store';
import UserState from './user.reducer';

export const getUserState = (state: AppState) => state.user;

export const selectLoggedInUser = createSelector(
  getUserState,
  (state: UserState) => state.loggedIn
);

export const selectPendingUserLoad = createSelector(
  getUserState,
  (state: UserState) => state.pendingUserLoad
);

export const selectUserFromId = (userId: string) => createSelector(
  getUserState,
  (state: UserState) => state.userDict[userId]
);

export const selectUserDictionary = createSelector(
  getUserState,
  (state: UserState) => state.userDict
);

export const selectAllowedUsersForReport = createSelector(
  getUserState,
  (state: UserState) => state.allowedUsersForReport
);
