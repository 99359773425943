import { createSelector } from '@ngrx/store';
import { AppState } from '../../store/app.reducers';
import QcState from './qc.reducer';

export const getQCState = (state: AppState) => state.qc;

export const selectAllQCPlateSummaries = createSelector(
  getQCState,
  (state: QcState) => state.all
);

export const selectQCTodoCounts = createSelector(
  getQCState,
  (state: QcState) => state.qcTodoCounts
);

export const selectQcResultPageInfo = createSelector(
  getQCState,
  (state: QcState) => state.pageInfo
);

export const selectCurrentPlateQC = createSelector(
  getQCState,
  (state: QcState) => state.currentPlateQC
);
