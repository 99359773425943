import {
  clearPlateSelectionFromTaskApi,
  echoPoolingImportError,
  emptyPlate,
  findPlateByAccessionCode,
  findPlateByAccessionCodeThenSelect,
  findPlateByAccessionCodeThenSelectFromEchoPooling,
  findPlateByAccessionCodeThenSelectFromPlateApi,
  findPlateByAccessionCodeThenSelectFromReArray,
  findPlateByAccessionCodeThenSelectFromTaskApi,
  putPlate,
  removePlateFromEchoPooling,
  removePlateFromIndexAssignment,
  removePlateFromReArray,
  setPlateSelection,
  updateSetSelection,
  updateUnsetSelection,
} from './actions/plates-details.actions';
import { PlateCoordinatesSelection } from '../../models/plate-coordinates-selection';
import { Plate96 } from '../../models/plate-96';
import { BioMaterial } from '../../../bio/models/bio-material';
import { countPlatesError } from './actions/plate-api.actions';
import { PlateCounts } from '../../dto/plate.dto';
import {
  countPlatesSuccess,
  findPlateByAccessionCodeError,
  findPlateByAccessionCodeSuccess,
  platesFindAllError,
  platesFindAllSuccess,
  platesSearchError,
  platesSearchSuccess,
  updatePlateFieldSuccess,
} from './actions/plates-api.action';
import {
  archivePlateSuccess,
  findAllPlates,
  findAllPlatesFromAPI,
  findAllPlatesFromEchoPooling,
  findAllPlatesFromIA,
  findAllPlatesFromReArray,
  findAllPlatesFromTodoPage,
  findAllPlatesSnippets,
  findAllPlatesSnippetsSuccess,
  findAllPlatesToAssignQC,
  processEchoPoolingError,
  processReArrayError,
  unarchivePlateSuccess,
} from './actions/plate-list.action';
import { PlateSnippet } from '../../dto/labware.dto';
import { createReducer, on } from '@ngrx/store';

/**
 * The platesStates handle the plates universe in the app:
 *   * plates: the plates themselves, referenced by an plateAccessionCode
 *   * rangeSelections: the selected bioMaterialPlateMappings, referenced by the same plateAccessionCode
 *
 * Plate & WellSection objects are immutable. All operations return a new instance, in order to be store friendly
 */
export default interface PlatesState {
  plates: { [key: string]: Plate96<BioMaterial> };
  rangeSelections: {};
  message: string;
  plateCounts: { [key: string]: PlateCounts };
  plateList: { [key: string]: PlateSnippet[] };
  snippets: PlateSnippet[];
  currentPlate: Plate96<BioMaterial> | null; // current plate for details screen, c.f. request reducer
  pending: boolean;
}

export const initialState: PlatesState = {
  plates: {},
  rangeSelections: {},
  message: '',
  plateCounts: {},
  plateList: {},
  snippets: [],
  currentPlate: null,
  pending: false
};

export const reducer = createReducer(
  initialState,
  on(findAllPlates,
    findAllPlatesFromIA,
    findAllPlatesFromReArray,
    findAllPlatesFromTodoPage,
    findAllPlatesFromEchoPooling,
    findAllPlatesFromAPI,
    findAllPlatesToAssignQC,
    (state): PlatesState => ({ ...state,
      pending: true,
      message: ''
    })
  ),
  on(findPlateByAccessionCodeThenSelect,
    findPlateByAccessionCodeThenSelectFromTaskApi,
    findPlateByAccessionCodeThenSelectFromReArray,
    findPlateByAccessionCodeThenSelectFromEchoPooling,
    findPlateByAccessionCodeThenSelectFromPlateApi,
    findPlateByAccessionCode,
    (state): PlatesState => ({ ...state,
      pending: true,
      currentPlate: null,
      message: ''
    })
  ),
  on(findAllPlatesSnippets,
    (state): PlatesState => ({ ...state,
      snippets: null
    })
  ),
  on(findAllPlatesSnippetsSuccess,
    (state, { snippets }): PlatesState => ({ ...state,
      snippets
    })
  ),
  on(countPlatesSuccess,
    (state, { availableTask, plateCounts }): PlatesState => {
      const key = availableTask === undefined ? 'all' : availableTask;
      return {
        ...state,
        pending: false,
        plateCounts: { ...state.plateCounts,
          [key]: plateCounts
        }
      };
    }
  ),
  on(countPlatesError,
    (state, { message }): PlatesState => ({ ...state,
      pending: false,
      message
    })
  ),
  on(putPlate,
    (state, { plateAccessionCode, plate, readonly }): PlatesState => {
      if (readonly) {
        return { ...state,
          plates: { ...state.plates,
            [plateAccessionCode]: plate
          }
        };
      }
      return { ...state,
        plates: { ...state.plates,
          [plateAccessionCode]: plate
        },
        rangeSelections: { ...state.rangeSelections,
          [plateAccessionCode]: new PlateCoordinatesSelection(plate.dimensions)
        }
      };
    }
  ),
  on(findPlateByAccessionCodeSuccess,
    updatePlateFieldSuccess,
    (state, { plate }): PlatesState => ({ ...state,
      currentPlate: plate,
      pending: false
    })
  ),
  on(removePlateFromReArray,
    removePlateFromEchoPooling,
    removePlateFromIndexAssignment,
    (state, { plateAccessionCode }): PlatesState => {
      const { ...plates } = state.plates;
      const { ...rangeSelections } = state.rangeSelections;
      delete plates[plateAccessionCode];
      delete rangeSelections[plateAccessionCode];
      return { ...state,
        plates,
        rangeSelections
      };
    }
  ),
  on(emptyPlate,
    (state, { plateAccessionCode, plateCoordinatesSelection }): PlatesState => ({ ...state,
      plates: { ...state.plates,
        [plateAccessionCode]: state.plates[plateAccessionCode].emptyAtSelection(plateCoordinatesSelection)
      }
    })
  ),
  on(setPlateSelection,
    (state, { plateAccessionCode, plateCoordinatesSelection }): PlatesState => ({ ...state,
      rangeSelections: { ...state['rangeSelections'],
        [plateAccessionCode]: plateCoordinatesSelection
      }
    })
  ),
  on(updateUnsetSelection,
    (state, { plateAccessionCode, pos }): PlatesState => ({ ...state,
      rangeSelections: { ...state['rangeSelections'],
        [plateAccessionCode]: state.rangeSelections[plateAccessionCode].unselect(pos)
      }
    })
  ),
  on(updateSetSelection,
    (state, { plateAccessionCode, pos, clear }): PlatesState => {
      const prevSelection = state.rangeSelections[plateAccessionCode];
      return { ...state,
        rangeSelections: { ...state['rangeSelections'],
          [plateAccessionCode]: clear ? prevSelection.clearSelection().select(pos) : prevSelection.select(pos)
        }
      };
    }
  ),
  on(clearPlateSelectionFromTaskApi,
    (state, { plateAccessionCode }): PlatesState => ({ ...state,
      rangeSelections: { ...state['rangeSelections'],
        [plateAccessionCode]: state.rangeSelections[plateAccessionCode].clearSelection()
      }
    })
  ),
  on(platesSearchSuccess,
    platesFindAllSuccess,
    (state, { mapKey, plates }): PlatesState => ({ ...state,
      pending: false,
      plateList: { ...state.plateList,
        [mapKey ?? 'all']: plates
      }
    })
  ),
  on(archivePlateSuccess,
    unarchivePlateSuccess,
    (state, { plateSnippet }): PlatesState => {
      const key = 'all';
      const updatedList = state.plateList[key].map((p: PlateSnippet) =>
        (p.accessionCode === plateSnippet.accessionCode) ? plateSnippet : p
      );
      return { ...state,
        plateList: { ...state.plateList,
          [key]: updatedList
        }
      };
    }
  ),
  on(platesSearchError,
    platesFindAllError,
    findPlateByAccessionCodeError,
    processReArrayError,
    processEchoPoolingError,
    echoPoolingImportError,
    (state, { message }): PlatesState => ({ ...state,
      pending: false,
      message
    })
  )
);
