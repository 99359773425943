import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {  Store } from '@ngrx/store';
import { findAllIndexKitModels, selectActiveIndexKitModels } from '../../../store';
import { Observable } from 'rxjs';
import { AppState } from '../../../../store/app.reducers';
import { SimpleIndexKitModel } from '../../../models/indexKitModel';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { IndexKitService } from '../../../services/index-kit.service';
import { EVENT_LISTENER_ID_INDEXES } from '../../../../tasks/index-assignment/constants';
import { Plate96 } from '../../../../labware/models/plate-96';
import { Index } from '../../../../bio/models/barcode-index';
import { putPlate } from '../../../../labware/store/plates';

@Component({
  selector: 'nemo-add-index-kit-dialog',
  templateUrl: './add-index-kit-dialog.component.html',
  styleUrls: ['./add-index-kit-dialog.component.scss']
})
export class AddIndexKitDialogComponent implements OnInit {

  form: UntypedFormGroup;
  errorMessage = '';
  selectedIndexKitModel: SimpleIndexKitModel;
  indexKitsModel$: Observable<SimpleIndexKitModel[]>;

  constructor(
    private readonly fb: UntypedFormBuilder,
    private store: Store<AppState>,
    private dialogRef: MatDialogRef<AddIndexKitDialogComponent>,
    private indexKitService: IndexKitService
  ) {
  }

  ngOnInit() {
    this.errorMessage = '';
    this.form = this.buildForm();
    this.indexKitsModel$ = this.store.select(selectActiveIndexKitModels);
    this.store.dispatch(findAllIndexKitModels());
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  addIndexKit(): void {
    if (this.form.valid) {
      const createIndexKit = {
        barcode: this.barcode.value,
        indexKitModel: this.selectedIndexKitModel.name
      };

      this.indexKitService.addIndexKit(createIndexKit)
        .subscribe((plate: Plate96<Index>) => {
            this.store.dispatch(putPlate({plateAccessionCode: EVENT_LISTENER_ID_INDEXES, plate: plate, readonly: false}));
            this.dialogRef.close();
          },
          error => {
            this.errorMessage = (error.message) ? error.message : 'Impossible to add index kit';
          });
    }
  }

  get barcode() {
    return this.form.get('barcode');
  }

  buildForm(): UntypedFormGroup {
    const fb = this.fb;

    return fb.group({
      barcode: fb.control('',
        [Validators.required],
      ),
      indexKitModel: fb.control('',
        [Validators.required],
      ),
    });
  }
}
