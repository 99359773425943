import { Component, OnInit } from '@angular/core';
import {  Store } from '@ngrx/store';
import { AppState } from '../../../../store/app.reducers';
import { createLibraryMethod, selectAllLibraryMethods } from '../../../store';
import { UntypedFormControl, Validators } from '@angular/forms';
import { take, tap } from 'rxjs/operators';

@Component({
  selector: 'nemo-library-method-settings-add',
  template: `
    <div class="container"
    >
      <mat-form-field>
        <mat-label>Library method name</mat-label>
        <input
          matInput
          [formControl]="formControl"
        >
      </mat-form-field>
      <button
        mat-raised-button
        color="primary"
        [disabled]="!formControl.valid"
        (click)="submit()"
      >Add
      </button>
    </div>
  `,
  styles: [`
               mat-form-field {
                 width: 40em;
                 margin-right: 1em;
               }
             `]
})
export class LibraryMethodSettingsAddComponent implements OnInit {
  formControl: UntypedFormControl;

  constructor(
    private readonly store: Store<AppState>
  ) {
  }

  ngOnInit() {
    this.formControl = new UntypedFormControl('', [
      Validators.required
    ]);
    this.store.select(selectAllLibraryMethods).pipe(take(1), tap(() => this.formControl.setValue('')));
  }

  submit() {
    this.store.dispatch(createLibraryMethod({name: this.formControl.value.trim()}));
  }
}
