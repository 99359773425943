import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { formatAccessionCode } from '../../../../shared/pipes/accession-code.pipe';
import { InstrumentDirectory } from '../../../models/instrumentDirectory';
import {
  FlexibleHtmlColumnDef,
  FlexiblePlainColumnDef
} from '../../../../table/model/flexible-columm-def';
import { InstrumentFile } from '../../../models/instrumentFile';
import { formatDate } from '@angular/common';
import { formatCommentDateFormat } from '../../../../comment/pipes/comment-date.pipe';

@Component({
  selector: 'nemo-instrument-directory-file-list',
  template: `
    <h3>File list</h3>
    <nemo-flexible-table
      [values]="sortedFiles"
      [columnDefinitions]="columnDefinitions"
    ></nemo-flexible-table>
  `,
  styles: []
})
export class InstrumentDirectoryFileListComponent implements OnInit {
  @Input()
  instrumentDirectory: InstrumentDirectory;

  sortedFiles: InstrumentFile[];

  columnDefinitions = [
    new FlexiblePlainColumnDef<InstrumentFile>(
      'accessionCode',
      'AC',
      () => true,
      (f: InstrumentFile) => formatAccessionCode(f.accessionCode),
      {'flex-grow': 0.3}
    ),
    new FlexiblePlainColumnDef<InstrumentFile>(
      'createAt',
      'Created',
      () => true,
      (f: InstrumentFile) => formatCommentDateFormat(f.createdAt, new Date()),
      {'flex-grow': 0.3}
    ),
    new FlexibleHtmlColumnDef<InstrumentFile>(
      'basename',
      'file name',
      () => true,
      (f: InstrumentFile) => `<a href="${f.gcsUrl}" target="__NEMO_GCS__">${f.basename}</a>`
    )
  ];

  constructor() {
  }

  ngOnInit(): void {
    this.sortedFiles = this.instrumentDirectory.files.slice()
      .sort((a, b) => a.suffix.localeCompare(b.suffix));
  }
}
