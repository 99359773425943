import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'nemo-request-submission-form-demux-parameters',
  template: `
    <div [formGroup]="formGroup">
      <h4>Sequencing/demux Parameters*</h4>
      <span class="description">Input values between 0 to 999.</span>
      <div class="demux-parameters">
        <mat-form-field *ngFor="let param10xFormControlName of param10xFormControlNames; let i = index"
                        appearance="fill"
                        floatLabel="auto"
                        class="numeric-field">
          <input matInput type="number"
                 [formControlName]="param10xFormControlName"
                 [min]="param10xMin"
                 [max]="param10xMax"/>
          <mat-label>{{ param10xTitles[i] }}</mat-label>
        </mat-form-field>
      </div>
    </div>
  `,
  styleUrls: ['../request-submission-form/request-submission-form.component.scss'],
})
export class RequestSubmissionFormDemuxParametersComponent {
  @Input() formGroup: FormGroup;
  @Input() param10xMin = 0;
  @Input() param10xMax = 999;

  param10xTitles: string[] = [
    'Read 1',
    'Index 1',
    'Index 2',
    'Read 2',
  ];
  param10xFormControlNames: string[] = [
    'param10xRead1',
    'param10xIndex1',
    'param10xIndex2',
    'param10xRead2',
  ];
}
