import { Component, OnInit } from '@angular/core';
import {  Store } from '@ngrx/store';
import { AppState } from '../../../store/app.reducers';
import { PageInfo } from '../../../shared/models/page.model';
import { Observable } from 'rxjs';
import { findAllFlowCells, selectFlowCellPageInfo, selectFlowCells } from '../../store/flow-cells';
import { Title } from '@angular/platform-browser';
import { FlowCell } from '../../models/flow-cell';

@Component({
  selector: 'nemo-flow-cell-table-page',
  template: `
    <nemo-pending-title [title]="'Flow cells'"></nemo-pending-title>
    <nemo-flow-cell-table [flowCells]="flowCells$ | async"></nemo-flow-cell-table>
    <nemo-paginator [pageInfo]="pageInfo$ | async" (pageChange)="findAll($event[0], $event[1])"></nemo-paginator>
`
})
export class FlowCellTablePageComponent implements OnInit {

  flowCells$: Observable<FlowCell[]>;
  pageInfo$: Observable<PageInfo>;

  constructor(
    private store: Store<AppState>,
    private titleService: Title
  ) {
  }

  ngOnInit() {
    this.titleService.setTitle('Nemo flow cell list');
    this.findAll(25, 0);
    this.pageInfo$ = this.store.select(selectFlowCellPageInfo);
    this.flowCells$ = this.store.select(selectFlowCells);
  }

  findAll(pageSize: number, page: number) {
    this.store.dispatch(findAllFlowCells({pageSize, page}));
  }
}
