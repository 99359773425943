import { FormStructure } from '../../models/form';
import { Action, createReducer, on } from '@ngrx/store';
import {
  actionFormEditModify, actionFormLoadLatestYaml,
  actionFormSaveSuccess,
  actionFormValidateError,
  actionFormValidateSuccess,
  actionFormValidateYaml, actionLoadLatestYamlSuccess,
} from './form-editor.actions';
import { TextValidationError } from '../../models/text-validation-error';

export interface FormEditorState {
  yaml: string;
  formStructure: FormStructure;
  error: TextValidationError;
  isModified: boolean;
  isValid: boolean;
  isSaved: boolean;
}

export const initialState: FormEditorState = {
  yaml: '',
  formStructure: null,
  error: null,
  isModified: false,
  isValid: true,
  isSaved: true
};

const formEditorReducer = createReducer(
  initialState,
  on(actionFormEditModify, (state): FormEditorState => (
    {...state, isModified: true, isValid: false, isSaved: false}
  )),
  on(actionFormLoadLatestYaml, (state): FormEditorState => (
    {...state, formStructure: null, yaml: null, error: null, isModified: false, isValid: true, isSaved: false}
  )),
  on(actionLoadLatestYamlSuccess, (state, {yaml}): FormEditorState => (
    {...state, formStructure: null, yaml, error: null, isModified: false, isValid: true, isSaved: true}
  )),
  on(actionFormValidateYaml, (state, {yaml}): FormEditorState => (
    {...state, yaml, formStructure: null, error: null}
  )),
  on(actionFormValidateSuccess, (state, {formStructure}): FormEditorState => (
    {...state, formStructure, error: null, isValid: true}
  )),
  on(actionFormValidateError, (state, {error}): FormEditorState => (
    {...state, formStructure: null, error: error, isValid: false}
  )),
  on(actionFormSaveSuccess, (state, {form}): FormEditorState => (
    {...state, formStructure: form.structure, error: null, isModified: false, isValid: true, isSaved: true}
  ))
);

export function reducer(state: FormEditorState | undefined, action: Action) {
  return formEditorReducer(state, action);
}
