import { AppState } from '../../store/app.reducers';
import { createSelector } from '@ngrx/store';
import TableState from './table.reducer';

const getTableState = (state: AppState) => state.table;

const selectArchiveFilter = (tableId: string) => createSelector(
  getTableState,
  (state: TableState) => state.archiveFilter[tableId]
);

export const selectIsArchivedOrDefaultTrue = (tableId: string) => createSelector(
  selectArchiveFilter(tableId),
  (checked: boolean | undefined | null): boolean | null => checked === undefined ? true : checked
);

export const selectIsUnarchivedIgnoringNull = (tableId: string) => createSelector(
  selectIsArchivedOrDefaultTrue(tableId),
  (checked: boolean | null): boolean | null => checked ? !checked : null
);

export const selectIsUnarchivedDefaultFalse = (tableId: string) => createSelector(
  selectIsArchivedOrDefaultTrue(tableId),
  (checked: boolean | null): boolean => !checked
);
