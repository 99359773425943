import { LibraryMethod } from '../models/library-method';
import { createReducer, on } from '@ngrx/store';
import { findAllLibraryMethodsSuccess } from './actions/library-method.actions';
import { SequencerModel } from '../models/sequencer-model';
import { findAllSequencerModelsSuccess } from './actions/sequencer-model.actions';

export default interface SequencingState {
  libraryMethods: LibraryMethod[];
  sequencerModels: SequencerModel[];
}
export const initialState: SequencingState = {
  libraryMethods: [],
  sequencerModels: []
};

export const reducer = createReducer(
  initialState,
  on(findAllLibraryMethodsSuccess, (state, { libraryMethods }): SequencingState => ({...state,
    libraryMethods
  })),
  on(findAllSequencerModelsSuccess, (state, { sequencerModels }): SequencingState => ({...state,
    sequencerModels
  })),
);
