import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LibraryMethod, SubmissionType } from '../../../../sequencing/models/library-method';
import { FormGroup } from '@angular/forms';
import { SubmissionTypeKeyValuePipe } from './request-submission-form-library-method.pipe';

@Component({
  selector: 'nemo-request-submission-form-library-method',
  template: `
    <div [formGroup]="formGroup">
      <h4>Submission type*</h4>
      <mat-accordion displayMode="flat" [multi]="false"
                     [ngClass]="{ 'library-method-selected': !!selectedLibraryMethod }">
        <mat-expansion-panel *ngFor="let submissionType of libraryMethodsGroupBySubmissionType | submissionTypeKeyValue"
                             (expandedChange)="selectedSubmissionTypeChanged()">
          <mat-expansion-panel-header>{{ submissionType.key }}</mat-expansion-panel-header>
          <!--      <label>Select Library Method</label>-->
          <mat-radio-group formControlName="libraryMethod"
                           name="library-methods"
                           class="library-method-radio-group"
                           (change)="onLibraryMethodChange($event.value)">
            <mat-radio-button *ngFor="let libraryMethods of submissionType.value"
                              [value]="libraryMethods">
              {{ libraryMethods.name }}
            </mat-radio-button>
          </mat-radio-group>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  `,
  styleUrls: ['../request-submission-form/request-submission-form.component.scss'],
  styles: [`
      mat-accordion.library-method-selected mat-expansion-panel {
          color: lightgrey;
      }

      mat-accordion.library-method-selected mat-expansion-panel.mat-expanded {
          color: initial;
      }

      mat-expansion-panel label {
          display: block;
          margin: 0 10px 8px;
      }

      mat-radio-group.library-method-radio-group {
          flex-direction: row;
          flex-wrap: wrap;
      }

      mat-radio-button {
          width: 100%;
          max-width: 480px;
          min-width: 50%;
          flex-grow: 1;
      }
  `],
})
export class RequestSubmissionFormLibraryMethodComponent {
  @Input() formGroup: FormGroup;
  @Input() libraryMethodsGroupBySubmissionType: Map<SubmissionType, LibraryMethod[]>;
  @Input() selectedLibraryMethod: LibraryMethod | null;
  @Output() selectedLibraryMethodChange: EventEmitter<LibraryMethod | null> = new EventEmitter<LibraryMethod | null>();

  selectedSubmissionTypeChanged() {
    this.selectedLibraryMethodChange.emit(null);
  }

  onLibraryMethodChange(selectedLibraryMethod: LibraryMethod) {
    this.selectedLibraryMethodChange.emit(selectedLibraryMethod);
  }
}
