import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SampleFileIndexHeaderEnum } from '../../../models/plate-file-validation.model';
import { selectGenomicsContainersPreview } from '../../../store';
import { Observable } from 'rxjs';
import { RequestSampleContainers } from '../../../models/genomics-request';
import { AppState } from '../../../../store/app.reducers';
import { Store } from '@ngrx/store';

@Component({
  selector: 'nemo-request-submission-form-file-upload',
  template: `
    <div [formGroup]="formGroup" class="file-upload">
      <h4>Submission Options*</h4>

      <div class="sample-index-header">
        <mat-radio-group formControlName="sampleIndexHeader">
          <mat-radio-button *ngFor="let i of indexHeaderValidationIndices"
                            [value]="i">{{indexHeaderValidation[i]}}</mat-radio-button>
        </mat-radio-group>
      </div>

      <button id="upload-plate-file-button"
              type="button"
              mat-stroked-button
              [ngClass]="sampleFileClassName"
              color="primary"
              (click)="fileInput.click()">
        Select sample file
      </button>
      <label for="upload-plate-file-button">
        <span>{{ sampleFile.get('filename') }}</span>
      </label>
      <input hidden type="file" #fileInput (change)="onSampleFileChanged($event)">
      <nemo-help-link gDocPath="drive/folders/17z9biba3N_hqUa71N7FqFmqJGHHsrHwh?usp=sharing"
                      plateExampleFile="request-a-simple-plate-with-nor.csv"
                      poolExampleFile="request-a-simple-pool-with-nor.csv"
                      text="sample file formats"
                      displayIndexDownload="true"></nemo-help-link>
      <mat-icon matSuffix class="valid" *ngIf="submittedContainersPreview$ | async">checked</mat-icon>
      <mat-icon matSuffix class="error" *ngIf="sampleFileErrors?.message">clear</mat-icon>

      <div *ngIf="submittedContainersPreview$ | async" class="miniature">
        <nemo-request-sample-containers [showActions]="false"
                                        [showOpenLinks]="false"
                                        [containers]="submittedContainersPreview$ | async"
                                        [isIcon]="false"
                                        [indexHint]="true"
        ></nemo-request-sample-containers>
      </div>
      <div class="lab-presentation">
        <p>
          <mat-icon [ngStyle]="{'color':'red'}">notification_important</mat-icon>
          Two new columns have been added to the sample file template:
        <ol>
          <li><b>Requested_reads(Million)</b>: Number of reads requested in millions(default: 25M).</li>
          <li><b>Vol(uL) </b>: Sample volume in microliters*.</li>
        </ol>
      </div>
    </div>
  `,
  styleUrls: ['../request-submission-form/request-submission-form.component.scss'],
})
export class RequestSubmissionFormFileUploadComponent implements OnInit {
  @Input() formGroup: FormGroup;
  @Input() isPristine: boolean;
  @Output() sampleFileChange: EventEmitter<File> = new EventEmitter<File>();

  submittedContainersPreview$: Observable<RequestSampleContainers>;

  indexHeaderValidation = [
    'I am submitting samples (DNA/RNA/Tissue, etc)',
    'My libraries are single indexed (i7)',
    'My libraries are dual indexed (i7+i5)',
    'I am submitting libraries by the index name'
  ];
  indexHeaderValidationIndices = [
    SampleFileIndexHeaderEnum.NO_INDEX,
    SampleFileIndexHeaderEnum.I7_ONLY,
    SampleFileIndexHeaderEnum.I7_AND_I5,
    SampleFileIndexHeaderEnum.INDEX_ID,
  ];

  constructor(
    private store: Store<AppState>
  ) {
  }

  ngOnInit() {
    this.submittedContainersPreview$ = this.store.select(selectGenomicsContainersPreview);
  }

  get sampleFile() {
    return this.formGroup.get('sampleFile');
  }

  get sampleFileErrors() {
    return this.sampleFile.value && this.sampleFile.errors;
  }

  get sampleFileClassName(): string {
    if (!this.isPristine && this.sampleFile.errors) {
      return 'plate-file-error';
    } else {
      return '';
    }
  }

  async onSampleFileChanged(event: any) {
    if (event.target.files && event.target.files.length > 0) {
      this.sampleFileChange.emit(event.target.files[0]);
    }
  }
}
