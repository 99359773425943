import {
  countPoolsError,
  countPoolsFromTodoPage,
  countPoolsSuccess,
  findAllPoolsByFlowCell,
  findPoolByAccessionCode,
  findPoolByAccessionCodeError,
  findPoolByAccessionCodeSuccess,
  findPools,
  poolColorReset,
  poolColorUpdateList,
  poolError,
  poolsFindAll,
  poolsFindAllError,
  poolsFindAllFromTodoPage,
  poolsFindAllSuccess,
  poolsFindByFlowCellId,
  poolsFindByFlowCellIdError,
  poolsFindByFlowCellIdSuccess,
  poolsSearch,
  poolsSearchError,
  poolsSearchSuccess,
} from './actions';
import { PoolCounts, poolCountsZero } from '../dto/bio.dto';

import { PoolWithRequest, SimplePoolWithRequest } from '../models/pool';
import * as d3 from 'd3';
import { ColorScale } from '../../shared/models/color-scale';
import { createReducer, on } from '@ngrx/store';

export default interface PoolState {
  pools: SimplePoolWithRequest[];
  colorScale: ColorScale<string>;
  pending: boolean;
  message: string;
  poolCounts: PoolCounts;
  currentPool: PoolWithRequest;
}

const initialColorScale = () => {
  const poolColors: string[] = [
    ...d3.schemeSet3,
    ...Array.from({ length: 101 }, (_, i) => d3.interpolatePlasma(i / 100))
  ];
  return new ColorScale<string>({}, poolColors, (accessionCode) => accessionCode);
}

export const initialState: PoolState = {
  pools: [],
  pending: false,
  colorScale: initialColorScale(),
  message: '',
  poolCounts: poolCountsZero,
  currentPool: null,
};

export const reducer = createReducer(
  initialState,
  on(findPoolByAccessionCode,
    (state): PoolState => ({ ...state,
      pending: true,
      message: '',
      currentPool: null
    })
  ),
  on(findPoolByAccessionCodeSuccess,
    (state, { pool }): PoolState => ({ ...state,
      pending: false,
      currentPool: pool
    })
  ),
  on(findPools,
    poolsSearch,
    countPoolsFromTodoPage,
    poolsFindAllFromTodoPage,
    poolsFindByFlowCellId,
    poolsFindAll,
    (state): PoolState => ({ ...state,
      pending: true,
      message: ''
    })
  ),
  on(poolsSearchSuccess,
    findAllPoolsByFlowCell,
    poolsFindAllSuccess,
    poolsFindByFlowCellIdSuccess,
    (state, { pools }): PoolState => ({ ...state,
      pending: false,
      pools
    })
  ),
  on(countPoolsSuccess,
    (state, { poolCounts }): PoolState => ({ ...state,
      pending: false,
      poolCounts
    })
  ),
  on(poolError,
    poolsSearchError,
    poolsFindAllError,
    countPoolsError,
    poolsFindByFlowCellIdError,
    findPoolByAccessionCodeError,
    (state, { message }): PoolState => ({ ...state,
      pending: false,
      message
    })
  ),
  on(poolColorReset,
    (state): PoolState => ({ ...state,
      colorScale: state.colorScale.reset()
    })
  ),
  on(poolColorUpdateList,
    (state, action): PoolState => ({ ...state,
      colorScale: state.colorScale.updateRange(action.accessionCodes)
    })
  )
);
