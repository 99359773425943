import { Component, Input, OnInit } from '@angular/core';
import { SimplePoolWithRequest } from '../../../bio/models/pool';
import {  Store } from '@ngrx/store';
import { AppState } from '../../../store/app.reducers';
import { FlowCell } from '../../../labware/models/flow-cell';
import { Observable } from 'rxjs';
import { selectPools } from '../../../bio/store';

@Component({
  selector: 'nemo-lane-assigner-container',
  template: `
    <nemo-lane-assigner-header [flowCell]="flowCell"></nemo-lane-assigner-header>

    <p *ngIf="(pools$ | async)?.length === 0">
      <b>BEWARE: there are not requests opened with Pools</b>
    </p>

    <nemo-lane-assignment-conflict-viewer></nemo-lane-assignment-conflict-viewer>
    <nemo-lane-assigner [pools]="pools$ |async"></nemo-lane-assigner>
  `
})
export class LaneAssignerContainerComponent implements OnInit {

  @Input() flowCell: FlowCell;
  pools$: Observable<Array<SimplePoolWithRequest>>;

  constructor(public readonly store: Store<AppState>) {
  }

  ngOnInit() {
    this.pools$ = this.store.select(selectPools);
  }
}
