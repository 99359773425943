import { Component, OnDestroy, OnInit } from '@angular/core';
import { AddPlateDialogComponent } from '../../../../labware/shared/components/add-plate-dialog/add-plate-dialog.component';
import { AddFlowCellDialogComponent } from '../../../../labware/shared/components/add-flow-cell-dialog/add-flow-cell-dialog.component';
import { AddIndexKitDialogComponent } from '../../../../index/shared/components/add-index-kit-dialog/add-index-kit-dialog.component';
import { HasGlobalContextUserRole } from '../../../../global-context/shared/global-context-shared/components/has-global-context-user-role';
import {  Store } from '@ngrx/store';
import { AppState } from '../../../../store/app.reducers';
import { UserRole } from '../../../../global-context/models/user-role';
import { ContextualMenuButton } from '../../../model/contextual-menu-button';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { selectAllFormTypesForCurrentLab } from '../../../../form/store/form/form.selector';
import { actionLoadAllFormTypesWithSectionsForLab } from '../../../../form/store/form';

@Component({
  selector: ' nemo-new-button',
  template: `
    <nemo-new-button-ui
      *ngIf="contextButtons$ | async"
      [buttons]="contextButtons$ | async"
    ></nemo-new-button-ui>
  `,
  styles: []
})
export class NewButtonComponent extends HasGlobalContextUserRole implements OnInit, OnDestroy {
  genomicsButtons: ContextualMenuButton[] = [
    {
      path: '/new-request',
      title: 'New request',
      lab: 'genomics'
    } as ContextualMenuButton,
    {
      dialog: AddPlateDialogComponent,
      title: 'New plate',
      divider: true,
      userRole: UserRole.LABER,
      lab: 'genomics'
    } as ContextualMenuButton,
    {
      dialog: AddFlowCellDialogComponent,
      title: 'New flow cell',
      userRole: UserRole.LABER,
      lab: 'genomics'
    } as ContextualMenuButton, {
      dialog: AddIndexKitDialogComponent,
      title: 'New index kit',
      userRole: UserRole.LABER,
      lab: 'genomics',
      width: 600
    } as ContextualMenuButton
  ];

  contextButtons$: Observable<ContextualMenuButton[]>;

  labSubscription: Subscription;

  constructor(
    private store: Store<AppState>,
  ) {
    super(store);
  }

  ngOnInit(): void {

    this.contextButtons$ = combineLatest([
      this.withinContextValidator$().pipe(
        map((fVal) => this.genomicsButtons.filter((b) => fVal(b.userRole, b.lab)))
      ),
      this.store.select(selectAllFormTypesForCurrentLab),
      this.currentLab$.pipe(
        filter(lab => lab != null)
      )
    ]).pipe(
      map(([filteredGenomicsButtons, formTypes, currentLab]) =>
        filteredGenomicsButtons.concat(
          formTypes
            .filter(formType => formType.lab === currentLab.alias)
            .slice()
            .sort((aType, anotherType) => aType.name.localeCompare(anotherType.name))
            .map(formType =>
              ({
                path: '/new-form-request/' + formType.name,
                title: 'New ' + ' request',
                lab: currentLab.alias
              } as ContextualMenuButton)
            ))
      )
    );

    this.labSubscription = this.currentLab$.pipe(
      filter(theLab => Boolean(theLab)),
      take(1)
    ).subscribe(
      (theLab) => this.store.dispatch(actionLoadAllFormTypesWithSectionsForLab({lab: theLab.alias}))
    );
  }

  ngOnDestroy(): void {
    if (this.labSubscription) {
      this.labSubscription.unsubscribe();
    }
  }


}
