import { combineLatest, Observable } from 'rxjs';
import { UserRole } from '../../../models/user-role';
import { AppState } from '../../../../store/app.reducers';
import {  Store } from '@ngrx/store';
import { selectGlobalContextLab, selectGlobalContextUserRole } from '../../../store/global-context.selectors';
import { map } from 'rxjs/operators';
import { Lab } from '../../../../company/models/Lab';
import { NemoVersion } from 'src/constants/version';

export abstract class HasGlobalContextUserRole {
  currentUserRole$: Observable<UserRole>;
  isSubmitter$: Observable<boolean>;
  isLaber$: Observable<boolean>;
  currentLab$: Observable<Lab>;
  version$: string;
  protected constructor(store$: Store<AppState>) {
    this.currentUserRole$ = store$.
      select((selectGlobalContextUserRole)
    );

    this.isSubmitter$ = this.currentUserRole$.pipe(
      map(userRole => userRole === UserRole.SUBMITTER)
    );

    this.isLaber$ = this.currentUserRole$.pipe(
      map(userRole => userRole === UserRole.LABER)
    );

    this.currentLab$ = store$.select(selectGlobalContextLab);
    this.version$ = NemoVersion;
  }

  isUserRoleValidator = (currentRole: UserRole, checkedRole: UserRole) =>
    checkedRole ?
    (currentRole && currentRole === checkedRole) :
    true

  isUserRole$(role: UserRole): Observable<boolean> {
    return this.currentUserRole$.pipe(
      map(currentRole => {
        if (role) {
          return this.isUserRoleValidator(role, currentRole);
        } else {
          return true;
        }
      })
    );
  }

  hasLab$(): Observable<boolean> {
    return this.currentLab$.pipe(
      map(currentLab => Boolean(currentLab))
    );
  }

  isLabValidator = (currentLab: Lab, checkedLabAlias: string) =>
    checkedLabAlias ?
    (
      currentLab &&
      (checkedLabAlias.startsWith('!') && currentLab.alias !== checkedLabAlias.substr(1) || currentLab.alias === checkedLabAlias)
    ) :
    true

  isLab$(labAlias: string): Observable<boolean> {
    return this.currentLab$.pipe(
      map(currentLab => this.isLabValidator(currentLab, labAlias))
    );
  }

  /**
   * Are the passed role/lab contained within the current context ?
   */
  isWithinContext$(role: UserRole, labAlias: string): Observable<boolean> {
    return combineLatest([
      this.isUserRole$(role),
      this.isLab$(labAlias)
    ]).pipe(
      map(([isUserRole, isLab]) => isUserRole && isLab)
    );
  }

  withinContextValidator$(): Observable<(role: UserRole, labAlias: string) => boolean> {
    return combineLatest([
      this.currentUserRole$,
      this.currentLab$
    ]).pipe(
      map(([currentRole, currentLab]) =>
        (checkedRole: UserRole, checkedLabAlias: string) => {
          return this.isUserRoleValidator(currentRole, checkedRole) &&
            this.isLabValidator(currentLab, checkedLabAlias);

        }
      )
    );
  }
}
