import { Store } from '@ngrx/store';
import { MatSnackBar, MatSnackBarRef, SimpleSnackBar } from '@angular/material/snack-bar';
import { Component, OnInit } from '@angular/core';

import { AppState } from '../../../../store/app.reducers';
import { clearErrorFromSnackBarClose, selectActiveError } from '../../store';
import { NError } from '../../models/error.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'nemo-error-flash',
  template: '',
})
export class ErrorFlashComponent implements OnInit {
  matSnackBarRef: MatSnackBarRef<SimpleSnackBar>;
  snackSubscription: Subscription;

  constructor(
    private store: Store<AppState>,
    private snackBar: MatSnackBar
  ) {
  }

  ngOnInit() {
    this.store
      .select(selectActiveError)
      // TODO Use service or effect to show error
      .subscribe((e?: NError) => {
        if (e) {
          if (this.snackSubscription) {
            this.snackSubscription.unsubscribe();
          }
          this.matSnackBarRef = this.snackBar.open(e.message, 'close', {});
          this.snackSubscription = this.matSnackBarRef.onAction().subscribe(() => {
            this.store.dispatch(clearErrorFromSnackBarClose());
          });
        } else if (this.matSnackBarRef) {
          this.matSnackBarRef.dismiss();
        }
      });
  }
}
