import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import {  Store } from '@ngrx/store';
import { AppState } from '../../../../store/app.reducers';
import { archiveIndexKitModel, selectIndexKitModels, restoreIndexKitModel } from '../../../store';
import { SimpleIndexKitModel } from '../../../models/indexKitModel';
import { IndexKitModelEditDialogComponent } from './index-kit-model-edit.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'nemo-index-kit-model-settings-list',
  template: `
    <div class="container">
      <table
        mat-table
        [dataSource]="indexKitModels$ | async"
        class="mat-elevation-z8"
      >
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Name</th>
          <td mat-cell *matCellDef="let element"> {{element.name}} </td>
        </ng-container>
        <ng-container matColumnDef="libraryMethods">
          <th mat-header-cell *matHeaderCellDef>Library methods</th>
          <td mat-cell *matCellDef="let element">
            <span class="library-method" *ngFor="let libraryMethod of element.libraryMethods">{{libraryMethod}}</span>
        </ng-container>
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef>Actions</th>
          <td mat-cell *matCellDef="let element">
            <button
              mat-mini-fab
              color="primary"
              (click)="edit(element)"
              matTooltip="edit the index kit model name"
            >
              <mat-icon>edit</mat-icon>
            </button>
            <button
              mat-mini-fab
              *ngIf="!element.archived"
              color="warn"
              (click)="archive(element.id)"
              matTooltip="archive the index kit model"
            >
              <mat-icon>archive</mat-icon>
            </button>
            <button
              mat-mini-fab
              *ngIf="element.archived"
              color="primary"
              (click)="restore(element.id)"
              matTooltip="Restore the index kit model"
            >
              <mat-icon>unarchive</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  `,
  styles: [`
    table {
      width: 100%;
    }

    .library-method {
      display: block;
    }
  `]
})
export class IndexKitModelSettingsListComponent implements OnInit {
  indexKitModels$: Observable<SimpleIndexKitModel[]>;

  displayedColumns = ['name', 'libraryMethods', 'action'];

  constructor(
    private readonly store: Store<AppState>,
    private dialog: MatDialog
  ) {
  }

  ngOnInit() {
    this.indexKitModels$ = this.store.select(selectIndexKitModels);
  }

  edit(indexKitModel: SimpleIndexKitModel) {
    this.dialog.open(IndexKitModelEditDialogComponent, {data: indexKitModel});
  }

  archive(indexKitModelId: number) {
    this.store.dispatch(archiveIndexKitModel({indexKitModelId}));
  }

  restore(indexKitModelId: number) {
    this.store.dispatch(restoreIndexKitModel({indexKitModelId}));
  }
}
