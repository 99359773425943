import { createReducer, on } from '@ngrx/store';
import {
  actionCompleteFormRequestStage,
  actionCompleteFormRequestStageSuccess,
  actionFindAllFormRequests,
  actionFindAllFormRequestsByAssignedTo,
  actionFindAllFormRequestsByAssignedToSuccess,
  actionFindAllFormRequestsByRequester,
  actionFindAllFormRequestsByRequesterSuccess,
  actionFindAllFormRequestsSuccess,
  actionFindFormRequestByAccessionCodeFromRequestPage,
  actionFindFormRequestByAccessionCodeSuccess,
  actionModifyFormRequest,
  actionReopenFormRequestStage,
  actionReopenFormRequestStageSuccess,
  actionSearchAllFormRequests,
  actionSubmitFormRequest,
  archiveFormRequestFromListSuccess,
  restoreFormRequestFromListSuccess,
} from './form-request.actions';
import { FormRequest, FormRequestSummary } from '../../models/form-request';
import {
  assignUserToFormRequestSuccess,
  unassignUserFromFormRequestSuccess,
} from '../../../commons/common-request/store/common-request.actions';

export interface FormRequestState {
  current: FormRequest;
  all: FormRequestSummary[];
  pending: boolean;
}

export const initialState: FormRequestState = {
  current: null,
  all: null,
  pending: false
};

export const reducer = createReducer(
  initialState,
  on(actionSubmitFormRequest,
    actionModifyFormRequest,
    actionFindFormRequestByAccessionCodeFromRequestPage,
    actionCompleteFormRequestStage,
    actionReopenFormRequestStage,
    (state): FormRequestState => ({...state,
      current: null
    })
  ),
  on(actionFindFormRequestByAccessionCodeSuccess,
    actionCompleteFormRequestStageSuccess,
    actionReopenFormRequestStageSuccess,
    assignUserToFormRequestSuccess,
    unassignUserFromFormRequestSuccess,
    (state, {formRequest}): FormRequestState => ({...state,
      current: formRequest
    })
  ),
  on(actionFindAllFormRequests,
    actionFindAllFormRequestsByAssignedTo,
    actionFindAllFormRequestsByRequester,
    actionSearchAllFormRequests,
    (state): FormRequestState => ({...state,
      all: null
    })
  ),
  on(actionFindAllFormRequestsSuccess,
    actionFindAllFormRequestsByAssignedToSuccess,
    actionFindAllFormRequestsByRequesterSuccess,
    (state, {formRequests}): FormRequestState => ({...state,
      all: formRequests
    })
  ),
  on(archiveFormRequestFromListSuccess, (state, {accessionCode}): FormRequestState => {
    const newRequestlist = toggleArchivedInRequestList(state, accessionCode, true);
    return {...state,
      all: newRequestlist
    }}
  ),
  on(restoreFormRequestFromListSuccess, (state, {accessionCode}): FormRequestState => {
    const newRequestList = toggleArchivedInRequestList(state, accessionCode, false);
    return {...state,
      all: newRequestList
    }}
  )
);

function toggleArchivedInRequestList(
  state: FormRequestState,
  accessionCode: string,
  archived: boolean
): FormRequestSummary[] {
  return state.all.map(request =>
    (request.accessionCode === accessionCode) ?
      {...request, archived} :
    request
  );
}
