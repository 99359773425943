import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {  Store } from '@ngrx/store';
import { AppState } from '../../../store/app.reducers';
import { Observable, Subscription } from 'rxjs';
import { Plate96 } from '../../models/plate-96';
import { BioMaterial } from '../../../bio/models/bio-material';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { findPlateByAccessionCode, selectCurrentPlate, selectIsPlateStorePending } from '../../store/plates';

@Component({
  selector: 'nemo-plate-page',
  templateUrl: './plate-page.component.html',
  styleUrls: ['./plate-page.component.scss']
})
export class PlatePageComponent implements OnInit, OnDestroy {

  @Input() isReadonly: boolean;
  @Input() colorStringFn: (BioMaterial) => string = null;
  @Input() showLegend = false;

  private paramSubscription: Subscription;
  plate$: Observable<Plate96<BioMaterial>>;
  pending$: Observable<boolean>;
  plateAccessionCode: string;

  constructor(
    private route: ActivatedRoute,
    private store: Store<AppState>,
    private titleService: Title
  ) {
  }

  ngOnInit() {
    this.plate$ = this.store.select(selectCurrentPlate);
    this.pending$ = this.store.select(selectIsPlateStorePending);

    this.paramSubscription = this.route.params.subscribe(params => {
      this.plateAccessionCode = params['plateAccessionCode'];
      this.titleService.setTitle('Nemo plate ' + this.plateAccessionCode);
      this.store.dispatch(
        findPlateByAccessionCode({
          accessionCode: this.plateAccessionCode
        })
      );
    });
  }

  ngOnDestroy() {
    this.paramSubscription.unsubscribe();
  }

}
