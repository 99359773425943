import { Component, Input} from '@angular/core';
import { RequestFastqDir } from '../../models/request-fastqdir';


@Component({
  selector: 'nemo-request-fastqdirs-table',
    template: `
    <b> Request Fastq dir info </b>
    <table mat-table [dataSource]="_requestFastqDirs">
      <ng-container matColumnDef="request_id">
	<th mat-header-cell *matHeaderCellDef>Request ID</th>
	<td mat-cell *matCellDef="let item"> {{ item.requestAC }} </td>
      </ng-container>

      <ng-container matColumnDef="fastq_dir">
	<th mat-header-cell *matHeaderCellDef>Fastq Dir</th>
	<td mat-cell *matCellDef="let item"> {{ item.fastqDir }} </td>
      </ng-container>

      <ng-container matColumnDef="requester">
	<th mat-header-cell *matHeaderCellDef>Requester</th>
	<td mat-cell *matCellDef="let item"> {{ item.requester }} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  `,
  styles: [`
    table {
      width: 100%;
    }
  `]
})
export class RequestFastqDirsTableComponent {
  _requestFastqDirs: Array<RequestFastqDir>;
  displayedColumns: string[] = [
      'request_id',
      'fastq_dir',
      'requester'
  ];
  @Input() set requestFastqDirs(value: Array<RequestFastqDir>) {
    if (value) {
      this._requestFastqDirs = value.slice().sort((a, b) => a.requestAC.localeCompare(b.requestAC));
    }
  }
}
