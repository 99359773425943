import { SimpleIndexKit } from '../models/indexKit';
import {
  archiveIndexKitError,
  findAllIndexKitModels,
  findAllIndexKitModelsError,
  findAllIndexKitModelsSuccess,
  findAllIndexKits,
  findAllIndexKitsSuccess,
  findIndexKitsError,
  findIndexKitsFromIA,
  findIndexKitsSuccess,
  indexKitError,
  unarchiveIndexKitError,
} from './index-kit.actions';
import { SimpleIndexKitModel } from '../models/indexKitModel';
import { PageInfo } from '../../shared/models/page.model';
import { createReducer, on } from '@ngrx/store';

export default interface IndexKitState {
  indexKits: SimpleIndexKit[];
  pageInfo: PageInfo | null;
  indexKitModels: SimpleIndexKitModel[];
  pending: boolean;
  message: string;
}

export const initialState: IndexKitState = {
  indexKits: [],
  pageInfo: null,
  indexKitModels: [],
  pending: false,
  message: '',
};

export const reducer = createReducer<IndexKitState>(
  initialState,
  on(findAllIndexKits,
    (state): IndexKitState => ({ ...state,
      pending: true,
      message: ''
    })
  ),
  on(findAllIndexKitsSuccess,
    (state, { indexKits, pageInfo }): IndexKitState => ({ ...state,
      pending: false,
      pageInfo,
      indexKits
    })
  ),
  on(findIndexKitsFromIA,
    (state): IndexKitState => ({ ...state,
      pending: true,
      indexKits: [],
      message: ''
    })
  ),
  on(findIndexKitsSuccess,
    (state, { indexKits }): IndexKitState => ({ ...state,
      pending: false,
      indexKits
    })
  ),
  on(findAllIndexKitModels,
    (state): IndexKitState => ({ ...state,
      pending: true,
      indexKitModels: [],
      message: ''
    })
  ),
  on(findAllIndexKitModelsSuccess,
    (state, { indexKitModels }): IndexKitState => ({ ...state,
      pending: false,
      indexKitModels
    })
  ),
  on(findIndexKitsError,
    findAllIndexKitModelsError,
    indexKitError,
    archiveIndexKitError,
    unarchiveIndexKitError,
    (state, { message }): IndexKitState => ({ ...state,
      pending: false,
      message
    })
  )
);