import { createReducer, on } from '@ngrx/store';
import { changeArchiveFilter } from './table.actions';

export default interface TableState {
  archiveFilter: { [key: string]: boolean };
}

export const initialState: TableState = {
  archiveFilter: {}
};

export const reducer = createReducer(
  initialState,
  on(changeArchiveFilter,
    (state, { tableId, checked }): TableState => {
      return { ...state,
        archiveFilter: { ...state.archiveFilter, [tableId]: checked }
      };
    }
  )
);
