import { createReducer, on } from '@ngrx/store';
import { Graph } from '../models/graph';
import { findGraphByEntityAccessionCodeFromPage, findGraphByEntityAccessionCodeSuccess } from './graph.actions';

export default interface GraphState {
  dictionary: { [key: string]: Graph };
}

export const initialState: GraphState = {
  dictionary: {}
};

export const reducer = createReducer(
  initialState,
  on(findGraphByEntityAccessionCodeFromPage,
    (state, {accessionCode}): GraphState => ({...state,
      dictionary: {...state.dictionary,
        [accessionCode]: null
      }
    })
  ),
  on(findGraphByEntityAccessionCodeSuccess,
    (state, {accessionCode, graph}): GraphState => ({...state,
      dictionary: {...state.dictionary,
        [accessionCode]: graph
      }
    })
  )
);
