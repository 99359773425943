import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { UserDTO } from '../models/user.dto';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { dtoToUser } from './user.mapper';
import { User } from '../models/user.model';
import {  Store } from '@ngrx/store';
import { AppState } from '../../store/app.reducers';
import { selectUserFromId } from '../store';
import { JSON_CONTENT } from '../../constants/http';
export const USER_BASE_URI = environment.apiUrl + '/users';
const QURERY_USERS_URI = environment.apiUrl + '/reporting/allowed-users';

@Injectable({
  providedIn: 'root',
})
export class UserService {

  constructor(private readonly http: HttpClient, private store: Store<AppState>) {
  }

  search(queryString: string): Observable<UserDTO[]> {
    const uri = USER_BASE_URI;
    return this.http.get<UserDTO[]>(uri, {params: {q: queryString}});
  }

  findAll(): Observable<User[]> {
    return this.http.get<UserDTO[]>(USER_BASE_URI).pipe(
      map((dtos) => dtos.map(dtoToUser))
    );
  }

  findById(id: string): Observable<User> {
    return this.http.get<UserDTO>(USER_BASE_URI + '/' + id)
      .pipe(
        map(dtoToUser)
      );
  }

  createUnlessExists(user: User): Observable<User> {
    return this.http.post<User>(USER_BASE_URI, user);
  }

  findByIdFromCache(id: string): Observable<User> {
    return this.store.select(selectUserFromId(id));
  }

  getUserListForReport(): Observable<string[]> {
    const options = {headers: JSON_CONTENT};
    return this.http.get<string[]>(QURERY_USERS_URI, options);
  }

}
