import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { NRequest } from '../../models/genomics-request';
import {  Store } from '@ngrx/store';
import { AppState } from '../../../store/app.reducers';
import { findAllFlowCellsForRequest, selectFlowCells } from '../../../labware/store/flow-cells';
import { Observable } from 'rxjs';
import { FlowCell } from '../../../labware/models/flow-cell';
import { UpdateRequestDTO } from '../../models/genomics-request.dto';
import { updateRequest } from '../../store';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { LibraryMethod } from '../../../sequencing/models/library-method';
import { selectActiveLibraryMethods } from '../../../sequencing/store';

@Component({
  selector: 'nemo-request-info',
  template: `
    <div class="left-pane" *ngIf="request && request?.stages[1].completedAt === null">
      <form [formGroup]="formGroup" (ngSubmit)="onSubmit()" class="submit-form">
        <mat-form-field
          class="full-width"
          appearance="fill"
        >
          <input matInput
                 #title
                 type="text"
                 formControlName="title"
                 (change)="checkHasChanged()"
          />
          <mat-hint align="start">a short description</mat-hint>
          <mat-hint align="end">{{title.value.length}} / 80</mat-hint>
        </mat-form-field>

        <div>
          <h4>Request origin</h4>
          <div class="request-origin">
            <nemo-department-select
              [control]="formGroup.get('departmentCode')"
              (changeValue)="checkHasChanged()"
            ></nemo-department-select>
            <nemo-project-select
              [control]="formGroup.get('projectCode')"
              (changeValue)="checkHasChanged()"
            ></nemo-project-select>
            <nemo-study-select
              [control]="formGroup.controls['studyId']"
              (changeValue)="checkHasChanged()"
            ></nemo-study-select>
            <nemo-collaborator-select
              [control]="formGroup.controls['collaborator']"
              (changeValue)="checkHasChanged()"
            ></nemo-collaborator-select>
          </div>
        </div>

        <!-- Library method selection -->
        <div>
          <h4>Preparation</h4>
          <mat-form-field
            class="full-width"
            appearance="fill"
          >
            <mat-select formControlName="libraryMethod"
                        (selectionChange)="checkHasChanged()">
              <mat-option *ngFor="let libraryMethod of libraryMethods$ | async" [value]="libraryMethod.name">
                {{libraryMethod.name}}
              </mat-option>
            </mat-select>
            <mat-hint align="start">The requested analysis</mat-hint>
          </mat-form-field>
        </div>

        <!-- PhiX -->
        <h4>PhiX</h4>
        <mat-form-field
          appearance="fill"
          floatLabel="auto"
          class="numeric-field phix"
        >
          <input matInput
                 formControlName="phiXPercentage"
                 #phiXPercentage
                 type="number"
                 class="right-align"
                 min="0"
                 max="100"
                 (change)="checkHasChanged()"
          />
          <span matSuffix>%</span>
          <mat-hint>a concentration between 0 and 100%</mat-hint>
        </mat-form-field>

        <!-- Comment -->
        <h4>Description</h4>
        <mat-form-field class="full-width" appearance="outline">
          <textarea rows="5" matInput #description type="text"
                    formControlName="description"
                    (change)="checkHasChanged()"
          ></textarea>
          <mat-hint align="end">{{description.value.length}} / 5000</mat-hint>
        </mat-form-field>

        <!-- Submit -->
        <button mat-raised-button
                id="submit-request-button"
                type="submit"
                color="primary"
                [disabled]="!hasChanged || !formGroup.valid"
        >Save
        </button>
      </form>


    </div>
    <div class="left-pane" *ngIf="request && request?.stages[1].completedAt !== null">
      <p><strong>Title:</strong> {{request?.title}}</p>
      <p>
        <strong>Origin:</strong>
        <span *ngIf="request?.department" class="origin-field">
          <b>department:</b> [{{request?.department.code}}] {{request?.department.name}}
        </span>
        <span *ngIf=" ! request?.department" class="origin-field">no department</span>
        <span *ngIf="request?.project" class="origin-field">
          <b>project:</b> [{{request?.project.code}}] {{request?.project.name}}
        </span>
        <span *ngIf="! request?.project" class="origin-field">no project</span>
        <span *ngIf="request?.study" class="origin-field">
          <b>study:</b> {{request?.study.name}}
        </span>
        <span *ngIf="! request?.study" class="origin-field">no study</span>
      </p>
      <p><strong>Library method:</strong> {{request?.libraryMethod}}</p>
      <p><strong>phiX:</strong> {{request?.phiXPercentage}} %</p>
      <p><strong>Description:</strong> {{request?.description}}</p>
      <p><strong>Fastq file path:</strong> {{request?.fastqDir}}</p>
    </div>
    <div *ngIf="request?.stages[request?.stages.length - 1].completedAt !== null" class="right-pane">
      <nemo-request-flowcell-export
        [flowCells]="flowCells$ | async"
        [requestIsComplete]="request?.stages[request?.stages.length - 1].completedAt !== null"
        [requestAccessionCode]="request?.accessionCode">
      </nemo-request-flowcell-export>
    </div>
  `,
  styles: [`
    :host {
      display: flex;
      flex-direction: row;
    }

    .left-pane {
      width: 67%;
    }

    .right-pane {
      width: 33%;
    }

    span.origin-field {
      margin-left: 1em;
    }

    div.request-origin nemo-department-select, nemo-project-select, nemo-study-select {
      margin-right: 2em;
    }

    .phix {
      width: 260px;
    }
  `]
})
export class RequestInfoComponent implements OnChanges, OnInit {

  @Input() request: NRequest;
  flowCells$: Observable<FlowCell[]>;
  hasChanged = false;
  formGroup: UntypedFormGroup;
  libraryMethods$: Observable<LibraryMethod[]>;

  constructor(
    private store: Store<AppState>,
  ) {
    this.buildForm();
  }

  buildForm() {
    this.formGroup = new UntypedFormGroup({
      libraryMethod: new UntypedFormControl('',
        [Validators.required],
      ),
      phiXPercentage: new UntypedFormControl(0,
        [Validators.required, Validators.min(0.0), Validators.max(100.0)]),
      title: new UntypedFormControl('',
        [Validators.required, Validators.maxLength(80)]
      ),
      description: new UntypedFormControl('',
        [Validators.maxLength(5000)]
      ),
      departmentCode: new UntypedFormControl(null,
        []
      ),
      projectCode: new UntypedFormControl(null,
        []
      ),
      studyId: new UntypedFormControl(null,
        []
      ),
      collaborator: new UntypedFormControl(null,
        []
      )
    });
  }

  ngOnInit(): void {
    this.flowCells$ = this.store.select(selectFlowCells);
    this.libraryMethods$ = this.store.select(selectActiveLibraryMethods);
    this.updateFormControlsWithRequest(this.request);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getFlowCellsForRequest();
    this.updateFormControlsWithRequest(this.request);
  }

  updateFormControlsWithRequest(request: NRequest) {
    if (this.request) {
      this.formGroup.get('title').setValue(request.title);
      this.formGroup.get('description').setValue(request.description);
      this.formGroup.get('phiXPercentage').setValue(request.phiXPercentage);
      this.formGroup.get('libraryMethod').setValue(request.libraryMethod);
      if (this.request.department) {
        this.formGroup.get('departmentCode').setValue(request.department.code);
      }
      if (this.request.project) {
        this.formGroup.get('projectCode').setValue(request.project.code);
      }
      if (this.request.study) {
        this.formGroup.get('studyId').setValue(request.study.id);
      }
      if (this.request.collaborator) {
        this.formGroup.get('collaborator').setValue(request.collaborator);
      }
    }
  }

  checkHasChanged() {
    this.hasChanged = !(
      this.formGroup.get('title').value === this.request.title
      && this.formGroup.get('phiXPercentage').value === this.request.phiXPercentage
      && this.formGroup.get('libraryMethod').value === this.request.libraryMethod
      && this.formGroup.get('description').value === this.request.description
      && (this.request.department && this.formGroup.get('departmentCode').value === this.request.department.code)
      && (this.request.project && this.formGroup.get('projectCode').value === this.request.project.code)
      && (this.request.study && this.formGroup.get('studyId').value === this.request.study.id)
      && (this.request.collaborator && this.formGroup.get('collaborator').value === this.request.collaborator)
    );
  }

  onSubmit() {
    const newValues = new UpdateRequestDTO(
      this.request.accessionCode,
      this.formGroup.get('title').value,
      this.formGroup.get('phiXPercentage').value,
      this.formGroup.get('libraryMethod').value,
      this.formGroup.get('description').value,
      this.formGroup.get('departmentCode').value,
      this.formGroup.get('projectCode').value,
      this.formGroup.get('studyId').value,
      this.formGroup.get('collaborator').value
    );
    this.store.dispatch(updateRequest({request: newValues}));
    this.hasChanged = false;
  }

  getFlowCellsForRequest() {
    if (this.request && this.request.stages[2].completedAt) {
      this.store.dispatch(findAllFlowCellsForRequest({
        requestAccessionCode: this.request.accessionCode
      }));
    }
  }

}
