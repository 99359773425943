import { createReducer, on } from '@ngrx/store';
import {
  completePlateQCAssignment,
  findTaskByRequestError,
  findTaskByRequestSuccess,
  validatePlateQCAssignment,
  validatePlateQCAssignmentError,
  validatePlateQCAssignmentInvalid,
  validatePlateQCAssignmentSuccess
} from './task.action';
import { Task } from '../models/task.model';
import { InvalidPlateQCInstrumentDirectoryError } from '../plate-qc-assignment/models/invalid-plate-qc-instrument-directory-error';

export default interface TaskState {
  tasks: Task[];
  message: string;
  plateQCAssignment: TaskPlatQCAssignmentState;
}

export interface TaskPlatQCAssignmentState {
  plateAccessionCode: string;
  instrumentDirectoryAccessionCode: string;
  error: InvalidPlateQCInstrumentDirectoryError;
}

export const initialState: TaskState = {
  tasks: [],
  message: '',
  plateQCAssignment: {
    plateAccessionCode: null,
    instrumentDirectoryAccessionCode: null,
    error: null
  }
};

export const reducer = createReducer(
  initialState,
  on(validatePlateQCAssignmentSuccess,
    (state): TaskState => state
  ),
  on(findTaskByRequestSuccess,
    (state, { tasks }): TaskState => ({ ...state,
      tasks
    })
  ),
  on(findTaskByRequestError,
    (state, { message }): TaskState => ({ ...state,
      message
    })
  ),
  on(validatePlateQCAssignment,
    (state, { plateAccessionCode, instrumentDirectoryAccessionCode }): TaskState => ({ ...state,
      plateQCAssignment: {...state.plateQCAssignment,
        plateAccessionCode,
        instrumentDirectoryAccessionCode,
        error: null
      }
    })
  ),
  on(validatePlateQCAssignmentInvalid,
    (state, { error }): TaskState => ({ ...state,
      plateQCAssignment: {...state.plateQCAssignment,
        plateAccessionCode: null,
        error
      }
    })
  ),
  on(validatePlateQCAssignmentError,
    (state): TaskState => ({ ...state,
      plateQCAssignment: {...state.plateQCAssignment,
        error: null
      }
    })
  ),
  on(completePlateQCAssignment,
    (state): TaskState => ({ ...state,
      plateQCAssignment: {...state.plateQCAssignment,
        plateAccessionCode: null,
        instrumentDirectoryAccessionCode: null,
        error: null
      }
    })
  )
);