import { AppState } from '../../store/app.reducers';
import { createSelector } from '@ngrx/store';
import IndexKitState from './index-kit.reducer';
import { SimpleIndexKitModel } from '../models/indexKitModel';

export const getIndexKitState = (state: AppState) => state.indexKit;

export const selectIndexKits = createSelector(
  getIndexKitState,
  (state: IndexKitState) => state.indexKits
);

export const selectPageInfo = createSelector(
  getIndexKitState,
  (state: IndexKitState) => state.pageInfo
);

export const selectIndexKitModels = createSelector(
  getIndexKitState,
  (state: IndexKitState) => state.indexKitModels.slice().sort(onNameAsc)
);

export const selectActiveIndexKitModels = createSelector(
  selectIndexKitModels,
  (indexKitModels: SimpleIndexKitModel[]) => indexKitModels.filter(indexKitModel => !indexKitModel.archived)
);

const onNameAsc = (a: SimpleIndexKitModel, b: SimpleIndexKitModel) => a.name.toLowerCase().localeCompare(b.name.toLowerCase());