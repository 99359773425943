import {Component, OnInit} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {AppState} from '../../../../store/app.reducers';
import { Store} from '@ngrx/store';
import {ReportingService} from '../../../services/reporting-service';
import {selectLoggedInUser, selectAllowedUsersForReport} from '../../../../user/store/user.selectors';
import {saveAs} from 'file-saver';

@Component({
  selector: 'nemo-reporting-home',
  template: `<h2>Reporting</h2>
  <iframe *ngIf="(allowedUsers$ | async).includes((user$ | async))" width=80% height=100%
          src="https://lookerstudio.google.com/embed/reporting/3acdccd2-f8a7-4a8f-8c1e-71d1e6571fa7/page/p7jcD" frameborder="0"
          style="border:0" allowfullscreen></iframe>
  <p></p>
  <button
    mat-raised-button
    color="primary"
    (click)="downloadRequestDump()"
  >Download all requests
  </button>
  `,
  styles: [`
  `]
})
export class ReportingHomeComponent implements OnInit {
  user$: Observable<String>;
  allowedUsers$: Observable<String[]>;

  constructor(
    private readonly reportingService: ReportingService,
    private store: Store<AppState>
  ) {
  }

  ngOnInit() {
    this.user$ = this.store.select(selectLoggedInUser).pipe(
      
      filter(u => Boolean(u)),
      map(u => u.email)
    );
    this.allowedUsers$ = this.store.select(selectAllowedUsersForReport);
  }

  downloadRequestDump() {
    this.reportingService.exportRequestDump()
      .subscribe(({filename, content}) => {
        const blob = new Blob([content], {type: 'text/csv'});
        saveAs(blob, filename);
      });
  }
}
