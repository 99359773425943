import { createReducer, on } from '@ngrx/store';
import {User} from '../models/user.model';
import {
  loadAllUserDetailsFromApiError,
  loadAllUserDetailsFromApiSuccess,
  loadUserDetailsError,
  loadUserDetailsPending,
  loadUserDetailsSuccess,
  storeLoggedInUser,
  findAllowedUsersForReport,
  findAllowedUsersForReportSuccess
} from './actions/user.actions';

export default interface UserState {
  loggedIn: User | null;
  pendingUserLoad: { [key: string]: boolean };
  userDict: { [key: string]: User };
  errorMessage: string;
  allowedUsersForReport: string[];
}

export const initialState: UserState = {
  loggedIn: null,
  pendingUserLoad: {},
  userDict: {},
  errorMessage: null,
  allowedUsersForReport: []
};

export const reducer = createReducer(
  initialState,
  on(storeLoggedInUser,
    (state: UserState, { user }): UserState => ({ ...state,
      loggedIn: user
    })
  ),
  on(loadUserDetailsPending,
    (state: UserState, { userId }): UserState => ({ ...state,
      pendingUserLoad: { ...state.pendingUserLoad,
        [userId]: true
      }
    })
  ),
  on(loadUserDetailsSuccess,
    (state: UserState, { user }): UserState => ({ ...state,
      userDict: { ...state.userDict,
        [user.id]: user
      },
      pendingUserLoad: { ...state.pendingUserLoad,
        [user.id]: false
      },
    })
  ),
  on(loadUserDetailsError,
    (state: UserState, { userId, message }): UserState => ({ ...state,
      userDict: { ...state.userDict,
        [userId]: null
      },
      pendingUserLoad: { ...state.pendingUserLoad,
        [userId]: false
      },
      errorMessage: message
    })
  ),
  on(loadAllUserDetailsFromApiSuccess,
    (state: UserState, { users }): UserState => {
      const newUserDict = { ...state.userDict };
      users.forEach((user) => newUserDict[user.id] = user);
      return { ...state,
        userDict: newUserDict,
        errorMessage: null
      };
    }
  ),
  on(loadAllUserDetailsFromApiError,
    (state: UserState, { message }): UserState => ({ ...state,
      errorMessage: message
    })
  ),
  on(findAllowedUsersForReport,
    (state: UserState): UserState => ({ ...state,
      allowedUsersForReport: []
    })
  ),
  on(findAllowedUsersForReportSuccess,
    (state: UserState, { allowedUsersForReport }): UserState => ({ ...state,
      allowedUsersForReport
    })
  )
);
