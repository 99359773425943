import { createReducer, on } from '@ngrx/store';
import { UserRole } from '../models/user-role';
import {
  actionChangeLab,
  actionChangeUserRole,
  actionSetEntityPage,
  actionSetEntityPageToNull,
} from './global-context.actions';
import EntityPage from '../models/entity-page';
import { Lab } from '../../company/models/Lab';

export default interface GlobalContextState {
  role: UserRole;
  lab: Lab;
  currentPage: EntityPage;
}

export const initialState: GlobalContextState = {
  role: null,
  lab: null,
  currentPage: null
};

export const reducer = createReducer(
  initialState,
  on(actionChangeUserRole,
    (state, {role}): GlobalContextState => ({ ...state,
      role
    })
  ),
  on(actionChangeLab,
    (state, {lab}): GlobalContextState => ({ ...state,
      lab
    })
  ),
  on(actionSetEntityPage,
    (state, {entityPage}): GlobalContextState => ({ ...state,
      currentPage: entityPage
    })
  ),
  on(actionSetEntityPageToNull,
    (state): GlobalContextState => ({ ...state,
      currentPage: null
    })
  ),
);
