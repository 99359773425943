import { Component, OnDestroy, OnInit } from '@angular/core';
import {  Store } from '@ngrx/store';
import { filter, map } from 'rxjs/operators';
import { AppState } from '../../../store/app.reducers';
import { Observable, Subscription } from 'rxjs';
import { findFlowCellByFlowCellIdThenSelect, selectSelectedFlowCell, selectFlowCellRequestFastqDirs, selectFlowCellSampleNors, selectNorAdmins } from '../../store/flow-cells';
import { selectLoggedInUser } from '../../../user/store';
import { ActivatedRoute } from '@angular/router';
import { FlowCell } from '../../models/flow-cell';
import { Title } from '@angular/platform-browser';
import { SampleNor } from '../../models/sample-nor';
import { RequestFastqDir } from '../../models/request-fastqdir';

@Component({
  selector: 'nemo-flow-cell-page',
  template: `
    <nemo-flow-cell-title
      *ngIf="flowCell$ | async"
      [flowCell]="flowCell$ | async"
    ></nemo-flow-cell-title>

    <nemo-flow-cell-pool-viewer-readonly
      *ngIf="(flowCell$ | async)?.status !== 'DRAFT'"
    >
    </nemo-flow-cell-pool-viewer-readonly>

    <nemo-lane-assigner-container *ngIf="(flowCell$ | async)?.status === 'DRAFT'"
                                  [flowCell]="flowCell$ | async"
    ></nemo-lane-assigner-container>

    <nemo-lane-assignment-table *ngIf="(flowCell$ | async)?.layout.length > 0"
        [layout]="(flowCell$ | async)?.layout"></nemo-lane-assignment-table>

    <br><br>
    <nemo-request-fastqdirs-table *ngIf="(norAdmins$ | async).includes((user$ | async))  && (requestFastqDirs$ | async).length > 0"
        [requestFastqDirs]="(requestFastqDirs$ | async)"></nemo-request-fastqdirs-table>

    <br><br>
    <nemo-sample-nors-table *ngIf="(norAdmins$ | async).includes((user$ | async))  && (sampleNors$ | async).length > 0"
       [sampleNors]="(sampleNors$ | async)" [showRequestInfo]="true"></nemo-sample-nors-table>
`
})
export class FlowCellPageComponent implements OnInit, OnDestroy {

  private paramSubscription: Subscription;
    flowCell$: Observable<FlowCell>;
    sampleNors$: Observable<SampleNor[]>;
    requestFastqDirs$: Observable<RequestFastqDir[]>;
    user$: Observable<String>;
    norAdmins$: Observable<string[]>;

  constructor(
    private route: ActivatedRoute,
    private store: Store<AppState>,
    private titleService: Title
  ) {
  }

  ngOnInit() {
      this.paramSubscription = this.route.params.subscribe(params => {
        const flowCellId = params['flowCellId'];
        this.titleService.setTitle('Nemo flow cell ' + flowCellId);
        this.store.dispatch(findFlowCellByFlowCellIdThenSelect({flowCellId: flowCellId}));
      });
      this.flowCell$ = this.store.select(selectSelectedFlowCell);
      this.sampleNors$ = this.store.select(selectFlowCellSampleNors);
      this.requestFastqDirs$ = this.store.select(selectFlowCellRequestFastqDirs);
      this.user$ = this.store.select(selectLoggedInUser).pipe(

        filter(u => Boolean(u)),
        map(u => u.email)
      );
      this.norAdmins$ = this.store.select(selectNorAdmins);
  }

  ngOnDestroy() {
    this.paramSubscription.unsubscribe();
  }
}
