import { Component, OnInit } from '@angular/core';
import {  Store } from '@ngrx/store';
import { AppState } from '../../../../../store/app.reducers';
import { Observable } from 'rxjs';
import { UserRole } from '../../../../models/user-role';
import { selectGlobalContextUserRole } from '../../../../store/global-context.selectors';
import { actionChangeUserRole } from '../../../../store/global-context.actions';
import { HasGlobalContextUserRole } from '../has-global-context-user-role';

@Component({
  selector: 'nemo-user-role-selector',
  template: `
    <nemo-context-switch-button
      [text]="['I am', 'submitting requests', 'to the '+((currentLab$|async)?.name ||'')+' lab']"
      extraClass="button-submitter"
      [isActive]="isSubmitter$ | async"
      (buttonSelected)="changeUserRoleToSubmitter()"
    ></nemo-context-switch-button>
    <nemo-context-switch-button
      [text]="['I am a', ((currentLab$|async)?.name ||'' ), 'lab member']"
      extraClass="button-laber"
      [isActive]="isLaber$ | async"
      (buttonSelected)="changeUserRoleToLaber()"
    ></nemo-context-switch-button>
  `,
  styles: [`
             :host {
               display: flex;
               width: 100%
             }
           `]
})
export class UserRoleSelectorComponent extends HasGlobalContextUserRole implements OnInit {

  role$: Observable<UserRole>;

  constructor(
    private store: Store<AppState>,
  ) {
    super(store);
  }

  ngOnInit(): void {
    this.role$ = this.store.select(selectGlobalContextUserRole);
  }

  changeUserRoleToSubmitter() {
    this.store.dispatch(actionChangeUserRole({role: UserRole.SUBMITTER}));
  }

  changeUserRoleToLaber() {
    this.store.dispatch(actionChangeUserRole({role: UserRole.LABER}));
  }
}
