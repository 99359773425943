import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType, concatLatestFrom} from '@ngrx/effects';
import {catchError, concatMap, filter, map, mergeMap, switchMap, withLatestFrom} from 'rxjs/operators';
import { Store} from '@ngrx/store';
import {of, EMPTY} from 'rxjs';

import {AppState} from '../../store/app.reducers';
import {selectLoggedInUser, getUserState} from './user.selectors';
import {User} from '../models/user.model';
import {UserService} from '../services/user.service';
import {
  loadAllUserDetailsFromApiError,
  loadAllUserDetailsFromApiSuccess,
  loadAllUserDetailsFromInit,
  loadUserDetailsError,
  loadUserDetailsFromRequestList,
  loadUserDetailsFromUserSummary,
  loadUserDetailsPending,
  loadUserDetailsSuccess,
  loggedInSuccess,
  pingBackendLoggedInUser,
  pingBackendLoggedInUserError,
  pingBackendLoggedInUserSuccess,
  storeLoggedInUser,
  findAllowedUsersForReport,
  findAllowedUsersForReportSuccess
} from './actions/user.actions';
import {findAllDepartments, findAllProjects} from '../../company/store';
import {clearErrorFromLoggedIn} from '../../shared/nerrors/store';
import {findAllLibraryMethodsAtInit} from '../../sequencing/store';
import {findAllSequencerModelsAtInit} from '../../sequencing/store/actions/sequencer-model.actions';
import {actionLoadContextAtInit} from '../../global-context/store/global-context.actions';
import {findAllIndexKitModelsAtInit} from '../../index/store';
import {findAllStudiesAtInit} from '../../company/store/study.actions';
import {findAllLabs} from '../../company/store/lab.actions';

@Injectable()
export class UserEffects {

  constructor(
    private actions$: Actions,
    private userService: UserService,
    private store: Store<AppState>,
  ) {
  }

  // launch a series of action to load global data from the backend
  
  login$ = createEffect(() => { return this.actions$.pipe(
    ofType(loggedInSuccess.type),
    concatLatestFrom(() => this.store.select(selectLoggedInUser)),
    filter(([, regLoggedInUser]) => {
      // only trigger initialization once
      return regLoggedInUser === null || !regLoggedInUser.id;
    }),
    switchMap(([action, ]) => [
        storeLoggedInUser({user: action['user']}),
        clearErrorFromLoggedIn(),
        findAllDepartments(),
        loadAllUserDetailsFromInit(),
        findAllProjects(),
        findAllStudiesAtInit(),
        findAllLabs(),
        findAllLibraryMethodsAtInit(),
        findAllSequencerModelsAtInit(),
        findAllIndexKitModelsAtInit(),
        pingBackendLoggedInUser({user: action['user']}),
        actionLoadContextAtInit(),
        findAllowedUsersForReport()
      ]
    ),
  ) });

  
  pingUser$ = createEffect(() => { return this.actions$.pipe(
    ofType(
      pingBackendLoggedInUser.type
    ),
    mergeMap((action: ReturnType<typeof pingBackendLoggedInUser>) => {
      return this.userService.createUnlessExists(action.user);
    }),
    map(() => pingBackendLoggedInUserSuccess()),
    catchError(error => of(pingBackendLoggedInUserError({message: error.message})))
  ) });

  
  loadAllUser$ = createEffect(() => { return this.actions$.pipe(
    ofType(
      loadAllUserDetailsFromInit.type
    ),
    mergeMap(() => this.userService.findAll()),
    map((users) => loadAllUserDetailsFromApiSuccess({users})),
    catchError(error => of(loadAllUserDetailsFromApiError({message: error.message}))),
  ) });

  
  findAllowedUsersForReport = createEffect(() => { return this.actions$.pipe(
    ofType(findAllowedUsersForReport.type),
    mergeMap((action: ReturnType<typeof findAllowedUsersForReport>) =>
      this.userService.getUserListForReport().pipe(
        map(allowedUsersForReport => {
          return findAllowedUsersForReportSuccess({allowedUsersForReport});
        }),
        catchError(error => {
          console.error('Error fetching users:', error);
          // You can dispatch a failure action here, if you have one defined.
          // return of(someFailureAction({ error }));
          return EMPTY; // if you don't want to dispatch anything on error
        })
      )
    )
  ) });

  /**
   * the goal of this effect is to trigger a user GET via the user Service only if it is not already either loaded
   * or if arequest for this user is already pending
   */
  
  loadUserDetails$ = createEffect(() => { return this.actions$.pipe(
    ofType(
      loadUserDetailsFromRequestList.type,
      loadUserDetailsFromUserSummary.type
    ),
    concatMap((action: ReturnType<typeof loadUserDetailsFromRequestList>) => {
        return of(action).pipe(withLatestFrom(
          this.store.
            select((getUserState),
          ))
        );
      }
    ),
    filter(([{userId}, {userDict, pendingUserLoad}]) => !pendingUserLoad[userId] && !userDict[userId]),
    mergeMap(([{userId}]) => {
        this.store.dispatch(loadUserDetailsPending({userId}));

        return this.userService.findById(userId).pipe(
          map((user: User) =>
            loadUserDetailsSuccess({user})
          ),
          catchError(error => of(loadUserDetailsError({userId: userId, message: error.message}))),
        );
      }
    ),
  ) });
}
