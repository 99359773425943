export const environment = {
  production: false,
  baseUrl: window.location.origin,
  apiUrl: '/api',
  auth: {
    clientId: '519041030625-fsd4kqnl6smf6osiamo17nu185ad9mja.apps.googleusercontent.com',
    issuer: 'https://accounts.google.com',
    strictDiscoveryDocumentValidation: false,
    scope: 'openid profile email',
    silentRefreshRedirectUri: window.location.origin + '/assets/silent-refresh.html',
    showDebugInformation: true,
    timeoutFactor: 0.5
  },
  doc: {
    howToSubmitter: 'https://docs.google.com/document/d/1ntFxDPHB9ok3BkvKobo1my_g0jybD9fXhelGWevAYSo'
  }
};
