import { Component, Input, OnInit } from '@angular/core';
import {  Store } from '@ngrx/store';
import { AppState } from '../../../store/app.reducers';
import { findPlateByAccessionCodeThenSelectFromReArray, selectPlateList } from '../../../labware/store/plates';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { SimplePlateWithRequests } from '../../../labware/models/simple-plate';
import { findAllPlatesFromReArray } from '../../../labware/store/plates/actions/plate-list.action';

export const RE_ARRAY_SOURCE_PLATE_ID = 're-array/source';

@Component({
  selector: 'nemo-re-array-right-zone',
  template: `
    <nemo-select-plate [plates$]="plates$"
                       (changeSelectedPlate)="updateSelectedPlate($event)"
                       [selectedPlateAccessionCode]="_plateAccessionCode"
                       inputPlaceHolder="Select source plate"></nemo-select-plate>

    <div *ngIf="_plateAccessionCode">
      <nemo-plate-viewer-container [plateAccessionCode]="sourcePlateIdentifier"
                                   [isReadonly]="false"
                                   [showLegend]="true"></nemo-plate-viewer-container>
      <div class="text-selection">
        <nemo-well-selection-input [plateAccessionCode]="sourcePlateIdentifier"></nemo-well-selection-input>
      </div>
    </div>
  `
})
export class ReArrayRightZoneComponent implements OnInit {

  _plateAccessionCode: string;
  sourcePlateIdentifier = RE_ARRAY_SOURCE_PLATE_ID;

  @Input() plateAccessionCode$: Observable<string>;
  @Input() destinationPlateAccessionCode: string;
  plates$: Observable<SimplePlateWithRequests[]>;

  constructor(
    private store: Store<AppState>,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    this.store.dispatch(findAllPlatesFromReArray({availableTask: 'RE_ARRAY_FROM'}));
    this.plates$ = this.store.select(selectPlateList('RE_ARRAY_FROM'));

    this.plateAccessionCode$.subscribe((ac) => {
      if (!Boolean(ac)) {
        return;
      }
      this._plateAccessionCode = ac;
      this.store.dispatch(findPlateByAccessionCodeThenSelectFromReArray({
        accessionCode: ac,
        putPlateIdentifier: this.sourcePlateIdentifier
      }));
    });
  }

  updateSelectedPlate(accessionCode: string) {
    this.router.navigate(['/tasks/re-array/' + this.destinationPlateAccessionCode + '/' + accessionCode]);
  }
}
