import { Component, OnInit } from '@angular/core';
import {  Store } from '@ngrx/store';
import { AppState } from '../../../../../store/app.reducers';
import { Observable } from 'rxjs';
import { UserRole } from '../../../../models/user-role';
import { selectGlobalContextUserRole } from '../../../../store/global-context.selectors';
import { actionChangeUserRole } from '../../../../store/global-context.actions';
import { HasGlobalContextUserRole } from '../has-global-context-user-role';

@Component({
  selector: 'nemo-change-context',
  template: `
    <div class="container">
      <div class="line lab">
        <nemo-lab-selector></nemo-lab-selector>
      </div>
      <div *ngIf="hasLab$() | async" class="line user-role">
        <nemo-user-role-selector></nemo-user-role-selector>
      </div>
    </div>
  `,
  styles: [`
             div.container {
               margin-top: 32px;
               width: 80%;
               max-width: 50em;
             }

             div.line {
               display: flex;
               flex-direction: row;
               justify-content: space-around;
               width: 100%;
               margin-bottom: 1em;
             }
           `]
})
export class ChangeContextComponent extends HasGlobalContextUserRole implements OnInit {

  role$: Observable<UserRole>;

  constructor(
    private store: Store<AppState>,
  ) {
    super(store);
  }

  ngOnInit(): void {
    this.role$ = this.store.select(selectGlobalContextUserRole);
  }

  changeUserRoleToSubmitter() {
    this.store.dispatch(actionChangeUserRole({role: UserRole.SUBMITTER}));
  }

  changeUserRoleToLaber() {
    this.store.dispatch(actionChangeUserRole({role: UserRole.LABER}));
  }
}
