import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../store/app.reducers';
import { selectAllSequencerModels } from '../../../store';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { createSequencerModel } from '../../../store/actions/sequencer-model.actions';
import { CreateSequencerModelDto } from '../../../dto/create-sequencer-model.dto';
import { take, tap } from 'rxjs/operators';

export const regexpListOfInt = /^(\s*\d+\s*,)*\s*\d+\s*$/;
export const regexpListOfString = /^(\s*[a-zA-Z0-9\s()%._-]+\s*,)*\s*[a-zA-Z0-9\s()%._-]+\s*$/;

export function parseListOfInt(str: String): number[] {
  return str.split(',').map((d) => parseInt(d, 10));
}

export function parseListOfString(str: String): string[] {
  if (str) {
    return str.trim().split(',').map((d) => d.trim());
  } else {
    return [];
  }
}

@Component({
  selector: 'nemo-sequencer-model-settings-add',
  template: `
    <div class="container"
         [formGroup]="formGroup"
    >
      <mat-form-field>
        <mat-label>Sequencer model name</mat-label>
        <input
          matInput
          formControlName="name"
        >
      </mat-form-field>
      <mat-form-field>
        <mat-label>Manufacturer</mat-label>
        <input
          matInput
          formControlName="manufacturer"
        >
      </mat-form-field>
      <mat-form-field>
        <mat-label>Flow cell types (comma-separated values)</mat-label>
        <input
          matInput
          formControlName="flowCellTypes"
        >
      </mat-form-field>
      <mat-form-field>
        <mat-label>Loading types (comma-separated values)</mat-label>
        <input
          matInput
          formControlName="loadingTypes"
        >
      </mat-form-field>
      <div>
        <span>I5 direction
          <b>{{(formGroup.value['i5Reversed'] === null) ? ':' : (formGroup.value['i5Reversed'] ? 'backward' : 'forward')}} </b>
        </span>
        <mat-button-toggle-group
          formControlName="i5Reversed"
        >
          <mat-button-toggle
            [value]="false"
            aria-label="I5 forwards"
          >
            <mat-icon>arrow_forward</mat-icon>
          </mat-button-toggle>
          <mat-button-toggle [value]="true" aria-label="I5 backwards">
            <mat-icon>arrow_backward</mat-icon>
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
      <mat-form-field>
        <mat-label># lanes</mat-label>
        <input
          matInput
          formControlName="availableFlowCellLanes"
          placeholder="2,4,8"
        >
      </mat-form-field>
      <div>
        <button
          mat-raised-button
          color="primary"
          [disabled]="!formGroup.valid"
          (click)="submit()"
        >Add
        </button>
      </div>
    </div>
  `,
  styles: [`
    div.container {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    mat-form-field {
      width: 40em;
      margin-right: 1em;
    }
  `]
})
export class SequencerModelSettingsAddComponent implements OnInit {
  formGroup: UntypedFormGroup;

  constructor(
    private readonly store: Store<AppState>
  ) {
  }

  ngOnInit() {
    this.formGroup = new UntypedFormGroup({
      name: new UntypedFormControl('', [
        Validators.required
      ]),
      manufacturer: new UntypedFormControl('', [
        Validators.required
      ]),
      flowCellTypes: new UntypedFormControl('', [
        Validators.pattern(regexpListOfString)
      ]),
      loadingTypes: new UntypedFormControl('', [
        Validators.pattern(regexpListOfString)
      ]),
      i5Reversed: new UntypedFormControl(null, [
        Validators.required
      ]),
      availableFlowCellLanes: new UntypedFormControl('', [
        Validators.required, Validators.pattern(regexpListOfInt)
      ])
    });
    this.store.select(selectAllSequencerModels).pipe(take(1), tap(() => this.formGroup.reset()));
  }

  submit() {
    const sequencer = {
      name: this.formGroup.get('name').value,
      manufacturer: this.formGroup.get('manufacturer').value,
      flowCellTypes: parseListOfString(this.formGroup.get('flowCellTypes').value),
      loadingTypes: parseListOfString(this.formGroup.get('loadingTypes').value),
      availableFlowCellLanes: parseListOfInt(this.formGroup.get('availableFlowCellLanes').value),
      i5Reversed: this.formGroup.get('i5Reversed').value
    } as CreateSequencerModelDto;
    this.store.dispatch(createSequencerModel({sequencer}));
  }
}
