import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { LibraryMethod, SubmissionType } from '../models/library-method';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

export const LIBRARY_METHOD_BASE_URI = environment.apiUrl + '/library-methods';

@Injectable({
  providedIn: 'root'
})
export class LibraryMethodService {

  constructor(private readonly http: HttpClient) {
  }

  findAll(): Observable<LibraryMethod[]> {
    return this.http.get<LibraryMethod[]>(LIBRARY_METHOD_BASE_URI)
      .pipe(map(libraryMethods => libraryMethods.map(libraryMethod => ({
        ...libraryMethod,
        submissionType: this.mapKeyToSubmissionType(libraryMethod.submissionType)
      }))));
  }

  create(libraryMethod: LibraryMethod): Observable<LibraryMethod> {
    return this.http.post<LibraryMethod>(LIBRARY_METHOD_BASE_URI, libraryMethod)
      .pipe(map(newLibraryMethod => ({
        ...newLibraryMethod,
        submissionType: this.mapKeyToSubmissionType(newLibraryMethod.submissionType)
      })));
  }

  rename(libraryMethod: LibraryMethod): Observable<LibraryMethod> {
    return this.http.put<LibraryMethod>(`${LIBRARY_METHOD_BASE_URI}/${libraryMethod.id}`, libraryMethod)
      .pipe(map(updatedLibraryMethod => ({
        ...updatedLibraryMethod,
        submissionType: this.mapKeyToSubmissionType(updatedLibraryMethod.submissionType)
      })));
  }

  archive(libraryMethod: LibraryMethod): Observable<void> {
    const uri = `${LIBRARY_METHOD_BASE_URI}/${libraryMethod.id}`;
    return this.http.delete<void>(uri);
  }

  restore(libraryMethod: LibraryMethod): Observable<void> {
    const uri = `${LIBRARY_METHOD_BASE_URI}/${libraryMethod.id}/restore`;
    return this.http.post<void>(uri, undefined);
  }

  mapKeyToSubmissionType(key: string): SubmissionType | undefined {
    return SubmissionType[key as keyof typeof SubmissionType];
  }
}
