import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { AppState } from '../../store/app.reducers';
import { changeArchiveFilter, selectIsArchivedOrDefaultTrue } from '../store';

@Component({
  selector: 'nemo-archive-filter',
  template: `
    <mat-checkbox
      color="primary"
      [checked]="checked$ | async"
      (change)="changeFilter($event)">
      Hide archived
    </mat-checkbox>
  `
})
export class StatusFilterComponent implements OnInit {

  @Input() tableId: string;
  checked$: Observable<boolean>;

  constructor(private store: Store<AppState>) {
  }

  ngOnInit() {
    this.checked$ = this.store.select(selectIsArchivedOrDefaultTrue(this.tableId));
  }

  changeFilter(event) {
    this.store.dispatch(changeArchiveFilter({tableId: this.tableId, checked: event.checked}));
  }
}
