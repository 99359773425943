import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import {  Store } from '@ngrx/store';
import { AppState } from '../../../store/app.reducers';
import { selectGenomicsImportedMetadata } from '../../store';

@Component({
  selector: 'nemo-request-submission-metadata',
  template: `
    <div class="container" *ngIf="(metadataHeaders$ | async) !== null && (metadataHeaders$ | async).length > 0">
      <span class="metadata-description mat-cell">Metadata imported:</span>
      <span class="metadata-header mat-cell" *ngFor="let header of (metadataHeaders$ | async)">{{header}}</span>
    </div>
  `,
  styles: [`
    div.container {
      display: flex;
      flex-direction: row;
      min-width: 400px;
      max-width: 60em;
      margin: 1em 0;
    }

    span.metadata-description {
      border-bottom: none;
    }

    span.metadata-header {
      margin-left: 1em;
      border-bottom: none;
    }
  `]

})
export class RequestSubmissionMetadataComponent implements OnInit {

  metadataHeaders$: Observable<String[]>;

  constructor(private store: Store<AppState>) {
  }

  ngOnInit(): void {
    this.metadataHeaders$ = this.store.select(selectGenomicsImportedMetadata);
  }
}
