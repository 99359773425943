import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import {  Store } from '@ngrx/store';
import { AppState } from '../../../store/app.reducers';
import { findAllRequestsFromHome } from '../../../genomics-request/store';
import { Observable } from 'rxjs';
import { NRequestSummary } from '../../../genomics-request/models/genomics-request';
import { selectGlobalContextGenomicsRequestsFromCurrentRole } from '../../../global-context/store/global-context.selectors';
import { getUserFilterCriteria } from '../../../global-context/services/global-context.service';
import { UserRole } from '../../../global-context/models/user-role';
import { User } from '../../../user/models/user.model';
import { Lab } from '../../../company/models/Lab';
import { HasGlobalContextUserRole } from '../../../global-context/shared/global-context-shared/components/has-global-context-user-role';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'nemo-home-genomics-requests',
  template: `
    <div class="requests">
      <h2 *ngIf="isSubmitter$ | async">My requests</h2>
      <h2 *ngIf="isLaber$ | async">Requests assigned to me</h2>
      <mat-checkbox
        color="primary"
        checked="true"
        (change)="changeFilter($event)">
        Hide archived
      </mat-checkbox>
    </div>
    <nemo-genomics-request-list
      [requests]="genomicsRequests$ | async"
      [showAssignees]="false"
      [showDescription]="true"
    ></nemo-genomics-request-list>
  `,
  styles: [`
    .requests {
      width: 95%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  `]
})
export class HomeGenomicsRequestsComponent extends HasGlobalContextUserRole implements OnInit, OnChanges {

  @Input() context: { user: User, role: UserRole, lab: Lab };

  hideArchived = true;

  genomicsRequests$: Observable<NRequestSummary[]>;

  constructor(
    private store: Store<AppState>,
  ) {
    super(store);
  }

  ngOnInit(): void {
    this.genomicsRequests$ = this.store.select(selectGlobalContextGenomicsRequestsFromCurrentRole);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.refreshList();
  }

  changeFilter(event: MatCheckboxChange) {
    this.hideArchived = event.checked;
    this.refreshList();
  }

  refreshList(): void {
    if (this.context) {
      this.store.dispatch(findAllRequestsFromHome({
        ...getUserFilterCriteria(this.context.role, this.context.user.id), hideArchived: this.hideArchived
      }));
    }
  }
}

