import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { flatMap, map, startWith } from 'rxjs/operators';
import { UntypedFormControl } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { SimplePlateWithRequests } from '../../models/simple-plate';
import { formatAccessionCode, trimAccessionCode } from '../../../shared/pipes/accession-code.pipe';
import {  Store } from '@ngrx/store';
import { AppState } from '../../../store/app.reducers';
import { selectIsPlateStorePending } from '../../store/plates';
import { PlateSnippet } from '../../dto/labware.dto';

@Component({
  selector: 'nemo-select-plate-snippet',
  template: `
    <mat-form-field class="select-plate" appearance="outline">
      <input type="text"
             #autoCompleteInput
             matInput
             [matAutocomplete]="auto"
             [formControl]="plateCtrl"
             [placeholder]="displayInputPlaceHolder$ | async">
      <mat-icon matSuffix>search</mat-icon>
    </mat-form-field>

    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectAccessionCode($event)">
      <mat-option *ngFor="let plate of filteredPlates$ | async" [value]="plate.accessionCode">
        <nemo-select-plate-snippet-item [plateSnippet]="plate"></nemo-select-plate-snippet-item>
      </mat-option>
      <mat-option *ngIf="(filteredPlates$ |async)?.length === 0" disabled="true" class="no-matching">
        No matching plate.
      </mat-option>
    </mat-autocomplete>
  `,
  styles: [`
             .select-plate {
               width: 100%;
             }

             /* TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version. */
             mat-option {
               height: unset;
             }
           `]
})
export class SelectPlateSnippetComponent implements OnInit, OnChanges {

  @Input() platesSnippets$: Observable<PlateSnippet[]>;
  @Input() selectedPlateAccessionCode: string;
  @Input() inputPlaceHolder = 'Select an existing plate';
  @Output() changeSelectedPlate = new EventEmitter<string>();

  plateCtrl = new UntypedFormControl();
  filteredPlates$: Observable<PlateSnippet[]>;
  pendingLoadingPlate$: Observable<boolean>;

  displayInputPlaceHolder$: Observable<string> = of(this.inputPlaceHolder);

  constructor(
    private readonly store: Store<AppState>,
  ) {
  }

  ngOnInit() {
    this.init();
    this.displayInputPlaceHolder$ = this.store.select(selectIsPlateStorePending).pipe(
      
      map(isPending => isPending ? 'Wait for plate list to be loaded...' : this.inputPlaceHolder)
    );
  }

  init() {
    this.filteredPlates$ = this.plateCtrl.valueChanges.pipe(
      startWith(''),
      flatMap(plate => {
        return plate ? this._filterPlate(plate) : this.platesSnippets$;
      })
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectedPlateAccessionCode']) {
      const currentAC = changes['selectedPlateAccessionCode'].currentValue;
      this.plateCtrl.setValue(currentAC ? formatAccessionCode(currentAC) : null);
    }
  }

  selectAccessionCode(event) {
    const accessionCode = event.option.value;
    this.changeSelectedPlate.emit(accessionCode);
    this.init();
  }

  private _filterPlate(value: string): Observable<PlateSnippet[]> {
    const filterValue = trimAccessionCode(value).toUpperCase();
    return this.platesSnippets$.pipe(
      map((plates: PlateSnippet[]) => {
          if (plates) {
            return plates.filter(plate => plate.accessionCode.toUpperCase().indexOf(filterValue) === 0);
          }
        }
      )
    );
  }
}
