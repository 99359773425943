import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'nemo-request-submission-form-species',
  template: `
    <div [formGroup]="formGroup">
      <h4>Species*</h4>
      <span class="description">If there are multiple species/organism values in samples, please overwrite it in samples csv file by adding column "organism".</span>
      <mat-form-field
          class="full-width library-method-field"
          appearance="fill">
        <mat-label>Species</mat-label>
        <mat-select formControlName="organismCtl"
                    placeholder="Species">
          <mat-option *ngFor="let option of mainOrganismOptions" [value]="option">{{ option }}</mat-option>
          <mat-divider></mat-divider>
          <mat-option *ngFor="let option of organismOptions" [value]="option">{{ option }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  `,
  styleUrls: ['../request-submission-form/request-submission-form.component.scss'],
})
export class RequestSubmissionFormSpeciesComponent {
  @Input() formGroup: FormGroup;

  //  The full list is aligned with Calico Benchiling
  //  see https://calico.benchling.com/calico_labs/registries/src_pVSWjAKd/dropdowns/sfs_S8JOHi7C
  //  or the exported version from
  //  https://console.cloud.google.com/bigquery?ws=!1m5!1m4!4m3!1scalico-benchling-bq-01!2sbenchling_01_transformed!3sdropdowns
  mainOrganismOptions: string[] = [
    'Mus musculus',
    'Homo sapiens',
    'Saccharomyces cerevisiae',
    'Caenorhabditis elegans',
    'Drosophila',
    'Cricetinae'
  ];
  organismOptions: string[] = [
    'Acipenser baerii',
    'Aotus trivirgatus',
    'Bos taurus',
    'Caenorhabditis elegans',
    'Callithrix argentata',
    'Callosciurus notatus',
    'Camelus dromedarius',
    'Canis lupus familiaris',
    'Carassius auratus',
    'Cavia porcellus',
    'Chlorocebus aethiops',
    'Chlorocebus pygerythrus',
    'Chlorocebus sabaeus',
    'Clarias batrachus',
    'Clupea pallasii',
    'Coturnix coturnix japonica',
    'Cricetinae',
    'Cricetulus griseus (Chinese hamster)',
    'Cricetulus migratorius',
    'Crossarchus obscurus',
    'Cyno macaque',
    'Daboia russelii',
    'Danio rerio',
    'Dasypus novemcinctus',
    'Dermacentor parumapertus',
    'Dermacentor variabilis',
    'Didelphis virginiana',
    'Drosophila',
    'Epinephelus coioides',
    'Equus burchelli',
    'Felis catus',
    'Fukomys damarensis',
    'Gallus gallus',
    'Gekko gecko',
    'Heterocephalus glaber',
    'Homo sapiens',
    'Ictalurus punctatus',
    'Iguana iguana',
    'Ixodes scapularis',
    'Lepomis macrochirus',
    'Lithobates catesbeiana',
    'Lithobates pipiens',
    'Macaca mulatta',
    'Marmota monax',
    'Meleagris gallopavo',
    'Meriones unguiculatus',
    'Mesocricetus auratus',
    'Morone chrysops',
    'Muntiacus muntjak',
    'Mus musculus',
    'Mus terricolor',
    'Mustela putorius furo',
    'Neovison vison',
    'Odocoileus hemionus',
    'Oncorhynchus mykiss',
    'Opodiphthera eucalypti',
    'Oreochromis mossambicus',
    'Oryctolagus cuniculus',
    'Oryx dammah',
    'Ovis aries',
    'Papio hamadryas',
    'Pecari tajacu',
    'Pimephales promelas',
    'Poeciliopsis lucida',
    'Potorous tridactylus',
    'Rattus',
    'Rattus Rattus',
    'Rattus norvegicus',
    'Rhinella marina',
    'Saccharomyces cerevisiae',
    'Saimiri boliviensis boliviensis',
    'Saimiri boliviensis peruviensis',
    'Saimiri sciureus',
    'Salmo salar',
    'Spodoptera frugiperda',
    'Sus scrofa',
    'Sylvilagus floridanus',
    'Takifugu niphobles',
    'Takifugu rubripes',
    'Terrapene carolina',
    'Toxorhynchites amboinensis',
    'Trichoplusia ni',
    'Urocyon cinereoargenteus',
    'Xenopus laevis'
  ];
}
