import { createSelector } from '@ngrx/store';
import { AppState } from '../../../store/app.reducers';
import { FormRequestState } from './form-request.reducer';

export const getFormRequestState = (state: AppState) => state.formRequest;

export const selectCurrentFormRequest = createSelector(
  getFormRequestState,
  (state: FormRequestState) => state.current
);

export const selectFormRequests = createSelector(
  getFormRequestState,
  (state: FormRequestState) => state.all
);

export const selectIsFormRequestStorePending = createSelector(
  getFormRequestState,
  (state: FormRequestState) => state.pending
);
