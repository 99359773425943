import {
  archiveFlowCellError,
  assignAddPoolToSelectedError,
  assignAddPoolToSelectedSuccess,
  assignRemovePoolFromSelectedError,
  assignRemovePoolFromSelectedSuccess,
  changeFlowCellToDraftError,
  changeFlowCellToDraftSuccess,
  changeGlobalConcentration,
  changeLaneConcentrationAndOccupancy,
  findAllFlowCellsError,
  findAllFlowCellsForRequest,
  findAllFlowCellsForRequestSuccess,
  findAllFlowCellsSuccess,
  findNorAdmins,
  findNorAdminsSuccess,
  findRequestFastqDirsByFlowCellId,
  findRequestFastqDirsByFlowCellIdSuccess,
  findSampleNorsByFlowCellId,
  findSampleNorsByFlowCellIdSuccess,
  flowCellError,
  laneAssignmentProcessedError,
  laneAssignmentProcessedSuccess,
  selectFlowCell,
  setFlowCellType,
  setLoadingType,
  unarchiveFlowCellError,
} from './flow-cells.actions';
import { FlowCell } from '../../models/flow-cell';
import { SampleNor } from '../../models/sample-nor';
import { RequestFastqDir } from '../../models/request-fastqdir';
import { PageInfo } from '../../../shared/models/page.model';
import { IndexConflictWithLaneNumber } from '../../models/flow-cell-mapping';
import { SequencerConfiguration } from '../../../sequencing/models/sequencer-configuration';
import { createReducer, on } from '@ngrx/store';

/**
 * The FlowCellsState handle the list of flow cells
 */
export default interface FlowCellsState {
  flowCells: FlowCell[];
  selected: FlowCell;
  selectedIsSaved: boolean;
  selectedConflicts: IndexConflictWithLaneNumber[];
  message: string;
  pageInfo: PageInfo | null;
  sampleNors: SampleNor[];
  requestFastqDirs: RequestFastqDir[];
  norAdmins: string[];
}

export const initialState: FlowCellsState = {
  flowCells: [],
  selected: null,
  selectedIsSaved: null,
  selectedConflicts: [],
  message: '',
  pageInfo: null,
  sampleNors: [],
  requestFastqDirs: [],
  norAdmins: []
};

export const reducer = createReducer(
  initialState,
  on(findAllFlowCellsSuccess,
    (state, { pageInfo, flowCells }): FlowCellsState => ({ ...state,
      pageInfo,
      flowCells
    })
  ),
  on(flowCellError,
    (state, { message }): FlowCellsState => ({ ...state,
      message
    })
  ),
  on(selectFlowCell,
    (state, { flowCell }): FlowCellsState => ({ ...state,
      selected: flowCell,
      selectedIsSaved: true,
      selectedConflicts: null
    })
  ),
  on(assignAddPoolToSelectedSuccess,
    (state, { flowCell }): FlowCellsState => ({ ...state,
      selected: flowCell,
      selectedIsSaved: false,
      selectedConflicts: null,
    })
  ),
  on(assignAddPoolToSelectedError,
    (state, { flowCell, conflicts }): FlowCellsState => ({ ...state,
      selected: flowCell,
      selectedIsSaved: false,
      selectedConflicts: conflicts,
    })
  ),
  on(assignRemovePoolFromSelectedSuccess,
    (state, { flowCell }): FlowCellsState => ({ ...state,
      selected: flowCell,
      selectedIsSaved: false,
      selectedConflicts: null,
    })
  ),
  on(assignRemovePoolFromSelectedError,
    (state, { flowCell, conflicts }): FlowCellsState => ({ ...state,
      selected: flowCell,
      selectedIsSaved: false,
      selectedConflicts: conflicts,
    })
  ),
  on(changeLaneConcentrationAndOccupancy,
    (state, { laneNumber, layout, concentration }): FlowCellsState => {
      const newLayout = state.selected.layout
        .filter(mapping => mapping.laneNumber !== laneNumber)
        .concat(layout);
      const newConcentrations = state.selected.concentrations
        .map((existingConcentration, laneNumber) =>
          laneNumber === laneNumber ? concentration : existingConcentration
        );
      return { ...state,
        selectedIsSaved: false,
        selected: new FlowCell(
          state.selected.id,
          state.selected.flowCellId,
          state.selected.sequencerModel,
          state.selected.sequencerConfiguration,
          state.selected.numberOfLanes,
          state.selected.createdAt,
          newLayout,
          newConcentrations,
          state.selected.status,
          state.selected.archived
        )
      };
    }
  ),
  on(changeGlobalConcentration,
    (state, { concentration }): FlowCellsState => ({ ...state,
      selectedIsSaved: false,
      selected: new FlowCell(
        state.selected.id,
        state.selected.flowCellId,
        state.selected.sequencerModel,
        state.selected.sequencerConfiguration,
        state.selected.numberOfLanes,
        state.selected.createdAt,
        state.selected.layout,
        new Array(state.selected.numberOfLanes).fill(concentration),
        state.selected.status,
        state.selected.archived
      )
    })
  ),
  on(setFlowCellType,
    (state, { flowCellType }): FlowCellsState => {
      const sequencerConfiguration = {
        sequencerModel: state.selected.sequencerModel,
        flowCellType,
        loadingType: state.selected.sequencerConfiguration?.loadingType
      } as SequencerConfiguration;
      return { ...state,
        selectedIsSaved: false,
        selected: new FlowCell(
          state.selected.id,
          state.selected.flowCellId,
          state.selected.sequencerModel,
          sequencerConfiguration,
          state.selected.numberOfLanes,
          state.selected.createdAt,
          state.selected.layout,
          state.selected.concentrations,
          state.selected.status,
          state.selected.archived
        )
      };
    }
  ),
  on(setLoadingType,
    (state, { loadingType }): FlowCellsState => {
      const sequencerConfiguration = {
        sequencerModel: state.selected.sequencerModel,
        flowCellType: state.selected.sequencerConfiguration?.flowCellType,
        loadingType,
      } as SequencerConfiguration;
      return { ...state,
        selectedIsSaved: false,
        selected: new FlowCell(
          state.selected.id,
          state.selected.flowCellId,
          state.selected.sequencerModel,
          sequencerConfiguration,
          state.selected.numberOfLanes,
          state.selected.createdAt,
          state.selected.layout,
          state.selected.concentrations,
          state.selected.status,
          state.selected.archived
        )
      };
    }
  ),
  on(changeFlowCellToDraftSuccess,
    (state): FlowCellsState => {
      return { ...state,
        selected: new FlowCell(
          state.selected.id,
          state.selected.flowCellId,
          state.selected.sequencerModel,
          state.selected.sequencerConfiguration,
          state.selected.numberOfLanes,
          state.selected.createdAt,
          state.selected.layout,
          state.selected.concentrations,
          'DRAFT',
          state.selected.archived
        )
      };
    }
  ),
  on(laneAssignmentProcessedSuccess,
    (state): FlowCellsState => {
      return { ...state,
        selected: new FlowCell(
          state.selected.id,
          state.selected.flowCellId,
          state.selected.sequencerModel,
          state.selected.sequencerConfiguration,
          state.selected.numberOfLanes,
          state.selected.createdAt,
          state.selected.layout,
          state.selected.concentrations,
          'PROCESSED',
          state.selected.archived
        )
      };
    }
  ),
  on(findAllFlowCellsError,
    archiveFlowCellError,
    unarchiveFlowCellError,
    changeFlowCellToDraftError,
    laneAssignmentProcessedError,
    (state, { message }): FlowCellsState => ({ ...state,
      message
    })
  ),
  on(findAllFlowCellsForRequest,
    (state): FlowCellsState => ({ ...state,
      flowCells: [],
      pageInfo: null
    })
  ),
  on(findAllFlowCellsForRequestSuccess,
    (state, { flowCells }): FlowCellsState => ({ ...state,
      flowCells
    })
  ),
  on(findSampleNorsByFlowCellId,
    (state): FlowCellsState => ({ ...state,
      sampleNors: []
    })
  ),
  on(findSampleNorsByFlowCellIdSuccess,
    (state, { sampleNors }): FlowCellsState => ({ ...state,
      sampleNors: sampleNors
    })
  ),
  on(findRequestFastqDirsByFlowCellId,
    (state): FlowCellsState => ({ ...state,
      requestFastqDirs: []
    })
  ),
  on(findRequestFastqDirsByFlowCellIdSuccess,
    (state, { requestFastqDirs }): FlowCellsState => ({ ...state,
      requestFastqDirs: requestFastqDirs
    })
  ),
  on(findNorAdmins,
    (state): FlowCellsState => ({ ...state,
      norAdmins: []
    })
  ),
  on(findNorAdminsSuccess,
    (state, { norAdmins }): FlowCellsState => ({ ...state,
      norAdmins: norAdmins
    })
  ),
);
