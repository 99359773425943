import EntityPage from '../../global-context/models/entity-page';
import { Action, createReducer, on } from '@ngrx/store';
import { CommentEntity } from '../models/commentText';
import {
  actionCommentPostedError,
  actionCommentPostedSuccess,
  actionFileAddedSuccess,
  actionNewComment,
  findAllCommentsByEntity,
  findAllCommentsByEntityError,
  findAllCommentsByEntitySuccess
} from './comment.actions';

export default interface CommentState {
  postPending: boolean;
  activePage: EntityPage;
  comments: CommentEntity[];
  message: string;
}

export const initialState: CommentState = {
  postPending: false,
  activePage: null,
  comments: [],
  message: null
};

const globalCommentReducer = createReducer(
  initialState,
  on(findAllCommentsByEntity, (state): CommentState => ({...state, message: null, comments: []})),
  on(findAllCommentsByEntitySuccess, (state, {comments}): CommentState => ({...state, message: null, comments: comments})),
  on(findAllCommentsByEntityError, (state, {message}): CommentState => ({...state, message: message, comments: []})),
  on(actionNewComment, (state): CommentState => ({...state, postPending: true, message: null})),
  on(actionCommentPostedSuccess, (state, {comment}): CommentState => ({
    ...state,
    postPending: false,
    message: null,
    comments: [...state.comments, comment]
  })),
  on(actionCommentPostedError, (state, {message}): CommentState => ({...state, postPending: false, message: message})),
  on(actionFileAddedSuccess, (state, {comment}): CommentState => ({
    ...state,
    comments: [...state.comments, comment]
  }))
);

export function reducer(state: CommentState | undefined, action: Action) {
  return globalCommentReducer(state, action);
}
