import { Component, OnInit } from '@angular/core';
import {  Store } from '@ngrx/store';
import { AppState } from '../../../store/app.reducers';
import { findAllPlates } from '../../store/plates/actions/plate-list.action';
import { Title } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { selectPlateList, selectIsPlateStorePending } from '../../store/plates';
import { SimplePlateWithRequests } from '../../models/simple-plate';

@Component({
  selector: 'nemo-plate-list-page',
  template: `
    <nemo-pending-title title="Plates" [pending]="pending$ | async"></nemo-pending-title>
    <nemo-plate-search></nemo-plate-search>
    <nemo-plate-list
      [plates]="plates$ | async"
    ></nemo-plate-list>
  `,
  styles: [`
             :host {
               display: flex;
               flex-direction: column;
             }

             nemo-plate-list {
               margin-top: 4px;
             }
           `]

})
export class PlateListPageComponent implements OnInit {

  plates$: Observable<SimplePlateWithRequests[]>;
  pending$: Observable<boolean>;

  constructor(
    private store: Store<AppState>,
    private titleService: Title,
  ) {
  }

  ngOnInit() {
    this.titleService.setTitle('Nemo plate list');
    this.findAll();
    this.plates$ = this.store.select(selectPlateList());
    this.pending$ = this.store.select(selectIsPlateStorePending);
  }

  findAll() {
    this.store.dispatch(findAllPlates({}));
  }
}
