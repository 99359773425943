import { Subscription } from 'rxjs';
import { PlateCoordinatesSelection } from '../../../models/plate-coordinates-selection';
import {  Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';
import { AppState } from '../../../../store/app.reducers';
import { selectRangeSelectionsByIdentifier } from '../../../store/plates';

/**
 * Inherit this class when you needs to react to Wells selection events on a particular plate,
 * e.g. WellSelectionInputComponent, PlateViewer2DComponent
 *
 * You need to possess an `plateAccessionCode`, which is used to decide which plate/bioMaterialPlateMappings you listen to.
 * An plateAccessionCode can either be an accession code or a technical tag (index/samples'
 * To start listening, call `this.updateWellListener()`.
 */
export abstract class WellSelectionListener {

  abstract plateAccessionCode: string;

  wellSelectionSubscription: Subscription;
  wellSelection: PlateCoordinatesSelection;

  constructor(
    public onSelectionUpdate: (WellSelection) => void,
    public store: Store<AppState>,
  ) {
  }

  /**
   * Start listening to the plate referred to by `plateAccessionCode`
   */
  updateWellListener() {
    if (this.wellSelectionSubscription) {
      this.wellSelectionSubscription.unsubscribe();
    }
    this.wellSelectionSubscription = this.store.select(selectRangeSelectionsByIdentifier(this.plateAccessionCode))
      .pipe(filter((p) => Boolean(p)))
      .subscribe((selection) => {
        this.wellSelection = selection;
        this.onSelectionUpdate(selection);
      });
  }
}
