import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/app.reducers';
import { clearValidateRequestSample } from '../../store';

@Component({
  selector: 'nemo-request-submission-page',
  template: `
    <div class="container">
      <div class="title-line">
        <div class="title">Request submission</div>
        <div class="help">
          <nemo-help-link gDocPath="document/d/1ypQ_RZjTxYwjs9cbMQSAOvrHF3jyRWdAS0j0zQkSDK8"
                          text="help"></nemo-help-link>
        </div>
      </div>
      <nemo-request-submission-form></nemo-request-submission-form>
      <nemo-request-submission-samples></nemo-request-submission-samples>
    </div>
  `,
  styles: [`
   div.container {
     min-width: 400px;
     margin-right: 16px;
   }

   nemo-request-submission-form {
     display: block;
     max-width: 1280px;
   }

   div.title-line {
     display: flex;
     flex-direction: row;
     justify-content: space-between
   }

   div.title-line div.title {
     font-size: 1.6rem;
     font-weight: bolder;
     padding-bottom: 12px;
   }
 `]
})
export class RequestSubmissionPageComponent implements OnInit {
  constructor(
    private readonly store: Store<AppState>,
  ) {
  }

  ngOnInit(): void {
    this.store.dispatch(clearValidateRequestSample());
  }
}
