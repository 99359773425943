import { createSelector } from '@ngrx/store';
import ErrorState from './error.reducer';
import { AppState } from '../../../store/app.reducers';

export const getErrorState = (state: AppState) => state.error;

export const selectErrors = createSelector(
  getErrorState,
  (state: ErrorState) => state.errors
);

export const selectActiveError = createSelector(
  getErrorState,
  (state: ErrorState) => state.active
);
