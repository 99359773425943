import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Plate96 } from '../../../labware/models/plate-96';
import {  Store } from '@ngrx/store';
import { AppState } from '../../../store/app.reducers';
import { selectPlateByIdentifier } from '../../../labware/store/plates';
import { Pool } from '../../../bio/models/pool';
import { ECHO_POOLING_DESTINATION_PLATE_ID } from './echo-pooling-left-zone.component';
import { toExportForEcho } from '../services/echo-pooling.service';
import { map } from 'rxjs/operators';
import { getAllPoolsByRequestsAccessionCode } from '../../../labware/services/plate.service';

@Component({
  selector: 'nemo-echo-pooling-export-for-echo',
  template: `
    <nemo-export-button tableName="for-echo"
                        [accessionCode]="accessionCode$ | async"
                        [data]="formatedData$ | async"
                        [tableColumns]="exportTableColumns"
                        [disabled]="(formatedData$ | async) === undefined || (formatedData$ | async) === null"
                        title="Export for Echo"
                        [number]="processed ? null : 3"></nemo-export-button>
  `
})
export class EchoPoolingExportForEchoComponent implements OnInit {

  @Input() processed = false;
  accessionCode$: Observable<string>;
  formatedData$: Observable<object[]>;
  exportTableColumns: string[] = ['Source Well', 'Destination Well', 'Transfer volume'];

  constructor(private store: Store<AppState>) {
  }

  ngOnInit() {
    this.accessionCode$ = this.store.select(selectPlateByIdentifier(ECHO_POOLING_DESTINATION_PLATE_ID)).pipe(
      
      map(plate => {
        if (plate) {
          return plate.accessionCode;
        }
      })
    );
    this.formatedData$ = this.store.select(selectPlateByIdentifier(ECHO_POOLING_DESTINATION_PLATE_ID)).pipe(
      
      map(plate => getAllPoolsByRequestsAccessionCode(plate as Plate96<Pool>, null)),
      map(toExportForEcho)
    );
  }
}
