import {Component, EventEmitter, Input, Output} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';


export interface CollaboratorOptions {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'nemo-collaborator-select',
  template: `
    <mat-form-field class="example-full-width">
      <mat-label>Collaborator</mat-label>
      <input type="text"
             matInput
             [formControl]="control"
             (change)="changedValue()"
             [matAutocomplete]="auto">
      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="changedValue()">
        <mat-option *ngFor="let collaboratorOption of collaboratorOptions" [value]="collaboratorOption">
          {{collaboratorOption}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  `,
  styleUrls: ['../select.scss'],
  styles: [`
    /* TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version. */
    mat-option {
      font-size: 13px;
      font-family: Roboto, RobotoDraft, Helvetica, Arial, sans-serif;
      height: 40px;
    }
  `]
})
export class CollaboratorSelectComponent {
  @Input()
  control: UntypedFormControl;

  @Output()
  changeValue = new EventEmitter<string>();

  collaboratorOptions: string[] = [
    'Sponsor Research Agreement - SRA',
    'Contract Research Organization - CRO',
    'Internal Calico Research'
  ];

  changedValue() {
    this.changeValue.emit();
  }
}
