import { Component, Input, OnInit } from '@angular/core';
import {  Store } from '@ngrx/store';
import {
  findPlateByAccessionCodeThenSelectFromEchoPooling,
  selectPlateList,
  removePlateFromEchoPooling
} from '../../../labware/store/plates';
import { AppState } from '../../../store/app.reducers';
import { Observable } from 'rxjs';
import { SimplePlateWithRequests } from '../../../labware/models/simple-plate';
import { Router } from '@angular/router';
import { findAllPlatesFromEchoPooling } from '../../../labware/store/plates/actions/plate-list.action';
import { TaskAvailable } from '../../models/task.model';

export const ECHO_POOLING_SOURCE_PLATE_ID = 'echo-pooling/source';

@Component({
  selector: 'nemo-echo-pooling-right-zone',
  template: `
    <nemo-select-plate [plates$]="plates$"
                       (changeSelectedPlate)="updateSelectedPlate($event)"
                       [selectedPlateAccessionCode]="_plateAccessionCode"
                       inputPlaceHolder="Select source plate"></nemo-select-plate>

    <div *ngIf="_plateAccessionCode">
      <nemo-plate-viewer-container [plateAccessionCode]="sourcePlateIdentifier"
                                   [isReadonly]="true"
                                   [showLegend]="true"></nemo-plate-viewer-container>
    </div>

  `
})
export class EchoPoolingRightZoneComponent implements OnInit {

  @Input() plateAccessionCode$: Observable<string>;
  @Input() destinationPlateAccessionCode: string;
  plates$: Observable<SimplePlateWithRequests[]>;
  _plateAccessionCode: string;
  sourcePlateIdentifier = ECHO_POOLING_SOURCE_PLATE_ID;

  constructor(
    private store: Store<AppState>,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    this.store.dispatch(findAllPlatesFromEchoPooling({
      availableTask: TaskAvailable.ECHO_POOLING_FROM
    }));
    this.plates$ = this.store.select(selectPlateList(TaskAvailable.ECHO_POOLING_FROM));

    this.plateAccessionCode$.subscribe((ac) => {
      if (!ac) {
        this.store.dispatch(removePlateFromEchoPooling({plateAccessionCode: this.sourcePlateIdentifier}));
        return;
      }
      this._plateAccessionCode = ac;
      this.store.dispatch(findPlateByAccessionCodeThenSelectFromEchoPooling({
        accessionCode: ac,
        putPlateIdentifier: this.sourcePlateIdentifier
      }));
    });
  }

  updateSelectedPlate(accessionCode: string) {
    this.router.navigate(['/tasks/echo-pooling/' + this.destinationPlateAccessionCode + '/' + accessionCode]);
  }

}
