import { Form } from '../../models/form';
import { createReducer, on } from '@ngrx/store';
import {
  actionFormEditLoadById,
  actionFormLoadAllFormTypes,
  actionFormLoadAllFormTypesSuccess,
  actionFormLoadByIdSuccess,
  actionFormLoadByTypeNameSuccess,
  actionFormSubmitLoadByTypeName,
  actionLoadAllFormTypesWithSectionsForLab,
  actionLoadAllFormTypesWithSectionsForLabSuccess,
} from './form.actions';
import { FormType, FormTypeWithSections } from '../../models/form-type';

export interface FormState {
  currentForm: Form;
  allFormTypes: FormType[];
  formTypesWithSectionsForCurrentLab: FormTypeWithSections[];
}

export const initialState: FormState = {
  currentForm: null,
  allFormTypes: [],
  formTypesWithSectionsForCurrentLab: []
};

export const reducer = createReducer(
  initialState,
  on(actionFormLoadAllFormTypes,
    (state): FormState => ({...state,
      allFormTypes: []
    })
  ),
  on(actionFormLoadAllFormTypesSuccess,
    (state, { formTypes }): FormState => ({...state,
      allFormTypes: formTypes
    })
  ),
  on(actionFormSubmitLoadByTypeName,
    actionFormEditLoadById,
    (state): FormState => ({...state,
      currentForm: null
    })
  ),
  on(actionFormLoadByTypeNameSuccess,
    actionFormLoadByIdSuccess,
    (state, { form }): FormState => ({...state,
      currentForm: form
    })),
  on(actionLoadAllFormTypesWithSectionsForLab,
    (state): FormState => ({...state,
      formTypesWithSectionsForCurrentLab: []
    })
  ),
  on(actionLoadAllFormTypesWithSectionsForLabSuccess,
    (state, { formTypesWithSections }): FormState => ({...state,
      formTypesWithSectionsForCurrentLab: formTypesWithSections
    })
  )
);
