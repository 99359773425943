import { createSelector } from '@ngrx/store';
import { AppState } from '../../store/app.reducers';
import TaskState, { TaskPlatQCAssignmentState } from './task.reducer';

export const getTaskState = (state: AppState) => state.task;

export const selectTasks = createSelector(
  getTaskState,
  (state: TaskState) => state.tasks
);

export const selectTasksPlateQCAssignment = createSelector(
  getTaskState,
  (state: TaskState) => state.plateQCAssignment
);

export const selectTasksPlateQCAssignmentError = createSelector(
  selectTasksPlateQCAssignment,
  (state: TaskPlatQCAssignmentState) => state.error
);


export const selectTasksPlateQCAssignmentPlateAccessionCode = createSelector(
  selectTasksPlateQCAssignment,
  (state: TaskPlatQCAssignmentState) => state.plateAccessionCode
);
