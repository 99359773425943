import { createReducer, on } from '@ngrx/store';
import {
  countPlateQCSuccess,
  findAllPlateQCSuccess,
  findPlateQCByAccessionCodeError,
  findPlateQCByAccessionCodeFromInstrumentDirectoryPage,
  findPlateQCByAccessionCodeSuccess,
} from './actions/qc-api.actions';
import { PageInfo } from '../../shared/models/page.model';
import {
  decrementPlateQCTodoCount,
  incrementPlateQCTodoCount,
  updatePlateQCInListSuccess,
  updatePlateQCSuccess,
} from './actions/qc-tasks.action';
import { PlateQC, PlateQCSummary } from '../models/plate-qc-measures';

export default interface QcState {
  currentPlateQC?: PlateQC;
  qcTodoCounts: number;
  all: PlateQCSummary[];
  pageInfo: PageInfo | null;
  message?: string;
}

export const initialState: QcState = {
  currentPlateQC: null,
  qcTodoCounts: null,
  all: [],
  pageInfo: null,
  message: null
};

export const reducer = createReducer(
  initialState,
  on(findPlateQCByAccessionCodeFromInstrumentDirectoryPage,
    (state): QcState => ({ ...state,
      currentPlateQC: null,
      message: null
    })
  ),
  on(findPlateQCByAccessionCodeSuccess,
      (state, { plateQC }): QcState => ({ ...state,
      currentPlateQC: plateQC,
      message: null
    })
  ),
  on(findPlateQCByAccessionCodeError,
      (state, { message }): QcState => ({ ...state,
      currentPlateQC: null,
      message
    })
  ),
  on(findAllPlateQCSuccess,
      (state, { plateQCs, pageInfo }): QcState => ({ ...state,
      all: plateQCs,
      pageInfo
    })
  ),
  on(countPlateQCSuccess,
      (state, { qcCounts }): QcState => ({ ...state,
      qcTodoCounts: qcCounts
    })
  ),
  on(updatePlateQCInListSuccess,
      (state, { plateQC }): QcState => ({ ...state,
      all: updatePlateQCListWith(state.all, plateQC)
    })
  ),
  on(updatePlateQCSuccess,
      (state, { plateQC }): QcState => ({ ...state,
      currentPlateQC: plateQC,
      message: null
    })
  ),
  on(decrementPlateQCTodoCount,
      (state): QcState => ({ ...state,
      qcTodoCounts: state.qcTodoCounts - 1
    })
  ),
  on(incrementPlateQCTodoCount,
      (state): QcState => ({ ...state,
      qcTodoCounts: state.qcTodoCounts + 1
    })
  )
);

const updatePlateQCListWith = (allPlates: PlateQCSummary[], plateQC: PlateQCSummary): PlateQCSummary[] => {
  return allPlates.map(d => (d.accessionCode === plateQC.accessionCode) ? plateQC : d);
}
