import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, filter, flatMap, map, mergeMap, tap } from 'rxjs/operators';

import {
  archiveFlowCell,
  archiveFlowCellError,
  archiveFlowCellSuccess,
  assignAddPoolToSelected,
  assignAddPoolToSelectedError,
  assignAddPoolToSelectedSuccess,
  assignRemovePoolFromSelected,
  assignRemovePoolFromSelectedError,
  assignRemovePoolFromSelectedSuccess,
  changeFlowCellToDraft,
  changeFlowCellToDraftError,
  changeFlowCellToDraftSuccess,
  findAllFlowCells,
  findAllFlowCellsError,
  findAllFlowCellsForRequest,
  findAllFlowCellsForRequestError,
  findAllFlowCellsForRequestSuccess,
  findAllFlowCellsFromAPI,
  findAllFlowCellsFromTodoPage,
  findAllFlowCellsSuccess,
  findFlowCellByFlowCellIdThenSelect,
  findNorAdmins,
  findNorAdminsSuccess,
  findRequestFastqDirsByFlowCellId,
  findRequestFastqDirsByFlowCellIdSuccess,
  findSampleNorsByFlowCellId,
  findSampleNorsByFlowCellIdSuccess,
  laneAssignmentProcessed,
  laneAssignmentProcessedError,
  laneAssignmentProcessedSuccess,
  saveLaneMapping,
  selectFlowCell,
  unarchiveFlowCell,
  unarchiveFlowCellError,
  unarchiveFlowCellSuccess,
  updateFlowCellField,
  updateFlowCellFieldError,
} from './flow-cells.actions';
import { FlowCell } from '../../models/flow-cell';
import { changeArchiveFilter, selectIsArchivedOrDefaultTrue } from '../../../table/store';
import { Page } from '../../../shared/models/page.model';
import { toPageInfo } from '../../../shared/services/page.service';
import { AppState } from '../../../store/app.reducers';
import { selectSelectedFlowCell } from './flow-cells.selectors';
import { Router } from '@angular/router';
import { poolsFindAllFromLaneAssignment, poolsFindByFlowCellId } from '../../../bio/store/actions';
import { FlowCellApiService } from '../../services/flow-cell-api.service';
import { SimplePoolWithRequest } from '../../../bio/models/pool';

@Injectable()
export class FlowCellsEffects {

  constructor(
    private actions$: Actions,
    private flowCellApiService: FlowCellApiService,
    private store: Store<AppState>,
    private router: Router,
  ) {
  }


  findAll = createEffect(() => { return this.actions$.pipe(
    ofType(
      findAllFlowCells,
      findAllFlowCellsFromAPI,
      findAllFlowCellsFromTodoPage
    ),
    concatLatestFrom(() => [
      this.store.select(selectIsArchivedOrDefaultTrue('flow-cell'))
    ]),
    mergeMap(([action, hideArchived]) =>
      this.flowCellApiService.findAll(action['pageSize'], action['page'], hideArchived, null)
    ),
    map((page: Page<FlowCell>) =>
      findAllFlowCellsSuccess({flowCells: page.content, pageInfo: toPageInfo(page)})
    ),
    catchError(error => of(findAllFlowCellsError({message: error.message}))),
  ) });

  findAllForRequest$ = createEffect(() => { return this.actions$.pipe(
    ofType(findAllFlowCellsForRequest),
    mergeMap(({requestAccessionCode}) =>
      this.flowCellApiService.findAll(0, 0, false, requestAccessionCode)
    ),
    map((page) => findAllFlowCellsForRequestSuccess({flowCells: page.content})),
    catchError(error => of(findAllFlowCellsForRequestError({message: error.message})))
  ) });

  /**
   * Retrieve the info for a flowCell from backend,
   * then select it.
   * If flow cell is draft, then all pols are piped in the store
   * It it is not draft anymore, then we only pull the flow cell pool
   */


  findFlowCellByIdThenSelect$ = createEffect(() => { return this.actions$.pipe(
    ofType(findFlowCellByFlowCellIdThenSelect),
    mergeMap(({ flowCellId }) =>
      this.flowCellApiService.findByFlowCellId(flowCellId)
    ),
    flatMap((flowCell: FlowCell) => [
      selectFlowCell({flowCell}),
      (flowCell.status === 'DRAFT') ?
        poolsFindAllFromLaneAssignment() :
        poolsFindByFlowCellId({flowCellId: flowCell.flowCellId}),
        findSampleNorsByFlowCellId({flowCellId: flowCell.flowCellId}),
	findRequestFastqDirsByFlowCellId({flowCellId: flowCell.flowCellId}),
        findNorAdmins()
      ],
    )
  ) });


  findSampleNorsByFlowCellId = createEffect(() => { return this.actions$.pipe(
    ofType(findSampleNorsByFlowCellId),
    mergeMap((action) =>
      this.flowCellApiService.findSampleNorByFlowCellId(action.flowCellId)),
      map(sampleNors => findSampleNorsByFlowCellIdSuccess({ sampleNors }))
  ) });



  findRequestFastqDirsByFlowCellId = createEffect(() => { return this.actions$.pipe(
    ofType(findRequestFastqDirsByFlowCellId),
    mergeMap((action) =>
      this.flowCellApiService.findRequestFastqDirsByFlowCellId(action.flowCellId)),
      map(requestFastqDirs => findRequestFastqDirsByFlowCellIdSuccess({ requestFastqDirs }))
  ) });

  findNorAdmins = createEffect(() => { return this.actions$.pipe(
    ofType(findNorAdmins),
    mergeMap(() =>
      this.flowCellApiService.findNorAdmins()),
      map(norAdmins => findNorAdminsSuccess({ norAdmins }))
  ) });


  changeArchiveFilter = createEffect(() => { return this.actions$.pipe(
    ofType(changeArchiveFilter),
    filter((action) => action.tableId === 'flow-cell'),
    map(() => findAllFlowCellsFromAPI({pageSize: 25, page: 0}))
  ) });


  archive = createEffect(() => { return this.actions$.pipe(
    ofType(archiveFlowCell),
    mergeMap((action) =>
      this.flowCellApiService.archive(action.flowCellId).pipe(
        map(() => archiveFlowCellSuccess()),
        catchError(error => of(archiveFlowCellError({message: error.message})))
      )
    )
  ) });


  unarchive = createEffect(() => { return this.actions$.pipe(
    ofType(unarchiveFlowCell),
    mergeMap((action) =>
      this.flowCellApiService.unarchive(action.flowCellId).pipe(
        map(() => unarchiveFlowCellSuccess()),
        catchError(error => of(unarchiveFlowCellError({message: error.message})))
      )
    )
  ) });


  laneAssignmentProcessed = createEffect(() => { return this.actions$.pipe(
    ofType(laneAssignmentProcessed),
    mergeMap((action) =>
      this.flowCellApiService.laneAssignmentProcessed(action.flowCellId).pipe(
        map(() => laneAssignmentProcessedSuccess()),
        catchError(error => of(laneAssignmentProcessedError({message: error.message})))
      )
    )
  ) });


  changeFlowCellToDraft = createEffect(() => { return this.actions$.pipe(
    ofType(changeFlowCellToDraft),
    mergeMap((action) =>
      this.flowCellApiService.putFlowCellToDraft(action.flowCellId).pipe(
        map(() => changeFlowCellToDraftSuccess()),
        catchError(error => of(changeFlowCellToDraftError({message: error.message})))
      )
    )
  ) });


  saveFlowCellMapping$ = createEffect(() => { return this.actions$.pipe(
    ofType(saveLaneMapping),
    mergeMap((action) =>
      this.flowCellApiService.saveMapping(action.flowCell).pipe(
        map(() =>
          findFlowCellByFlowCellIdThenSelect({flowCellId: action.flowCell.flowCellId})
        )
      )
    )
  ) });


  assignPoolToSelected$ = createEffect(() => { return this.actions$.pipe(
    ofType(assignAddPoolToSelected),
    concatLatestFrom(() => [this.store.select(selectSelectedFlowCell)]),
    map(([action, flowCell]) => {
      try {
        return flowCell.addPool(action.pool as SimplePoolWithRequest, action.laneNumber); // TODO make sure the casting does not break
      } catch (e) {
        console.error(e);
        return flowCell;
      }
    }),
    mergeMap((newFlowCell: FlowCell) => {
        return this.flowCellApiService.validateMapping(newFlowCell).pipe(
          map((conflicts) => {
            return conflicts ?
                   assignAddPoolToSelectedError({flowCell: newFlowCell, conflicts: conflicts}) :
                   assignAddPoolToSelectedSuccess({flowCell: newFlowCell});
          }),
          catchError(() => of(assignAddPoolToSelectedError({flowCell: newFlowCell, conflicts: []})))
        );
      }
    ),
    catchError((err) => {
      throw err;
    })
  ) });


  removePoolFromSelected$ = createEffect(() => { return this.actions$.pipe(
    ofType(assignRemovePoolFromSelected),
    concatLatestFrom(() => [this.store.select(selectSelectedFlowCell)]),
    map(([action, flowCell]) => {
      try {
        return flowCell.removePool(action.pool as SimplePoolWithRequest, action.laneNumber); // TODO make sure the casting does not break
      } catch (e) {
        console.error(e);
        return flowCell;
      }
    }),
    mergeMap((newFlowCell: FlowCell) => {
        return this.flowCellApiService.validateMapping(newFlowCell).pipe(
          map((conflicts) => {
            return conflicts ?
                   assignRemovePoolFromSelectedError({
                     flowCell: newFlowCell,
                     conflicts: conflicts
                   }) :
                   assignRemovePoolFromSelectedSuccess({flowCell: newFlowCell});
          }),
          catchError(() => of(assignRemovePoolFromSelectedError({
            flowCell: newFlowCell,
            conflicts: []
          })))
        );
      }
    ),
    catchError((err) => {
      throw err;
    })
  ) });


  updateFlowCellField$ = createEffect(() => { return this.actions$.pipe(
    ofType(updateFlowCellField),
    mergeMap((action) =>
      this.flowCellApiService.patchOneField(action.flowCellId, action.field, action.value)
    ),
    tap((flowCell) => {
        // well at this stage, only flowCellId can be updated, so we must change the url
        return this.router.navigate(['/flow-cell', flowCell.flowCellId], {});
      }
    ),
    catchError(error => {
      const message = (error.message) ? error.message : 'Impossible update flow cell field';
      return of(updateFlowCellFieldError({message}));
    }),
  ) }, {dispatch: false});
}
